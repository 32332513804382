<template>
    <div class="MyApprovalList">
        <div data-title="我的审批">
            <div class="nav1">
                <div class="headTab">
                    <span class="navitm" :class="{ nav1Active: !nav1Active }" @click="nav1Change(0)">接口申请</span>
                    <span class="navitm" :class="{ nav1Active: nav1Active }" @click="nav1Change(1)">受限目录申请</span>
                    <p class="phone-num" v-if="servicePhoneNum">业务服务电话：{{ servicePhoneNum }}</p>
                </div>

                <div class="searchBar">
                    <div class="bt">
                        <a-input v-model.trim="nav1Search.title" :addon-before="!nav1Active ? '申请标题' : '目录名称'" placeholder="请输入关键词" />
                    </div>
                    <div class="lx">
                        <span class="ant-input-group-addon span" style="width: 80px;height: 32px;line-height: 30px;">申请状态</span>
                        <a-select allowClear v-model="nav1Search.serviceState" placeholder="请选择申请状态" style="width: 120px" v-if="!nav1Active">
                            <a-select-option :key="item.value" :value="item.value" v-for="(item, index) in serviceState_options">
                                {{ item.label }}
                            </a-select-option>
                        </a-select>
                        <a-select allowClear v-model="nav1Search.limitState" placeholder="请选择申请状态" style="width: 120px" v-else>
                            <a-select-option :key="item.value" :value="item.value" v-for="(item, index) in limitState_options">
                                {{ item.label }}
                            </a-select-option>
                        </a-select>
                    </div>
                    <div class="sj">
                        <a-range-picker :value="!nav1Active ? defaultData : defaultData1" :format="dateFormat" @change="onChange" :allowClear="false" />
                    </div>
                    <div class="cx">
                        <a-button type="primary" style="width: 100%;" @click="listInit">
                            查询
                        </a-button>
                    </div>
                    <div class="reset" @click="reset">
                        <a-icon type="redo" />
                    </div>
                </div>
                <div v-if="showData">
                    <a-spin :spinning="spinning" tip="数据加载中...">
                        <div v-if="!nav1Active" class="tb1_1">
                            <ul class="cardUl">
                                <li class="li" v-for="(item, index) in MyDataList" :key="index" :class="{ hasButton: item.serviceState === '5' }">
                                    <i class="listIcon"></i>
                                    <span class="listTitle" @click="goDeatail(item.id)">{{ item.applyformName }}</span>
                                    <ul class="keys">
                                        <li class="kvs">接口名称 ：{{ item.interfaceName }}</li>
                                        <li class="kvs">关联应用 ： {{ item.applicationName }}</li>
                                        <li class="kvs">申请时间：{{ resolveTimeStream(item.updateDate) }}</li>
                                        <li class="kvs">申请人 ：{{ item.applicantName }}</li>
                                        <li class="kvs">审批流程</li>
                                        <li class="kvs blue" :class="{ red: item.serviceState === '5' }">申请状态 ：{{ GetLable(item.serviceState, serviceState_options) }}</li>
                                    </ul>
                                    <section class="flow-step-box">
                                        <ul class="flow-step-list">
                                            <li v-for="step in interfaceFlowStepList" :key="step.key" class="flow-step-item" :class="{ active: item.flowHistory.length >= step.key }">
                                                <div class="flow-step-img">
                                                    <img :src="item.flowHistory.length >= step.key ? step.iconActive : step.icon" alt="" />
                                                </div>
                                                <p class="flow-step-title">{{ step.title }}</p>
                                            </li>
                                        </ul>
                                    </section>
                                    <div class="approval" v-if="item.serviceState == '5'">
                                        <!--<div class="approval">-->
                                        <a-button style="width: 100%;" @click.stop="goEdit(item.id)">重新申请</a-button>
                                    </div>
                                    <span class="line"></span>
                                    <span class="process">申请状态</span>
                                    <!-- <approval-process :data="item.flowHistory" class="processDetail"></approval-process> -->
                                    <div class="apply-status">
                                        <img :src="require('@/assets/index/refused.png')" alt="" v-if="item.serviceState == '5'" />
                                        <img :src="require('@/assets/index/agree.png')" alt="" v-else-if="item.serviceState == '4' || item.serviceState == '7' || item.serviceState == '6'" />
                                        <img :src="require('@/assets/index/approve.png')" alt="" v-else />
                                        <p v-if="item.serviceState == '5'">
                                            {{ getReason(item.flowHistory, 0) }}
                                        </p>
                                    </div>
                                </li>
                            </ul>
                            <div class="pagination">
                                <a-pagination v-model="current1" :total="total1" :page-size-options="pageSizeOptions" :page-size.sync="pageSize1" show-size-changer show-quick-jumper @change="change" @showSizeChange="onShowSizeChange" />
                            </div>
                        </div>
                        <div v-else class="tb1_1">
                            <ul class="cardUl">
                                <li class="li" v-for="(item, index) in MyLimitCatalogList" :key="index" :class="{ hasButton: item.status == '2' }">
                                    <i class="listIcon"></i>
                                    <span class="listTitle" @click="goLimitDetail(item.id)">{{ item.catalogName }}</span>
                                    <ul class="keys">
                                        <li class="kvs">申请单号：{{ item.id }}</li>
                                        <li class="kvs">申请用户名称：{{ item.name }}</li>
                                        <li class="kvs">申请用户电话：{{ item.phone }}</li>
                                        <li class="kvs blue" :class="{ red: item.status == '2' }">申请状态 ：{{ GetLable(item.status, limitApplyStatusOptions) }}</li>
                                        <li class="kvs">审批流程</li>
                                        <li class="kvs">提交日期：{{ moment(item.createDate).format('YYYY-MM-DD') }}</li>
                                    </ul>
                                    <section class="flow-step-box">
                                        <ul class="flow-step-list">
                                            <li v-for="step in catalogFlowStepList" :key="step.key" class="flow-step-item" :class="{ active: item.flowHistory.length >= step.key }">
                                                <div class="flow-step-img">
                                                    <img :src="item.flowHistory.length >= step.key ? step.iconActive : step.icon" alt="" />
                                                </div>
                                                <p class="flow-step-title">{{ step.title }}</p>
                                            </li>
                                        </ul>
                                    </section>
                                    <div class="approval" v-if="item.status == '2'">
                                        <!--<div class="approval">-->
                                        <a-button style="width: 100%;" @click.stop="goEditLimitCatalog(item.id)">重新申请</a-button>
                                    </div>
                                    <!--  <div class="delete">
                                        <a-button style="width: 100%;" @click.stop="goDelete(item.id)" type="danger" ghost>删除</a-button>
                                    </div>-->
                                    <span class="line"></span>
                                    <span class="process">申请状态</span>
                                    <!-- <approval-process :data="item.sysCatalogApproveInfoVo" class="processDetail"></approval-process> -->
                                    <div class="apply-status">
                                        <img :src="catalogApplyStatusList[item.status]" alt="" />
                                        <template v-if="item.status == '1'">
                                            <a-button type="primary" class="apply_interface_btn" @click="goInterfaceApply(item)">接口申请</a-button>
                                        </template>

                                        <p v-if="item.status == '2'">
                                            {{ getReason(item.flowHistory) }}
                                        </p>
                                    </div>
                                </li>
                            </ul>
                            <div class="pagination">
                                <a-pagination v-model="current2" :total="total2" :page-size-options="pageSizeOptions" :page-size.sync="pageSize2" show-size-changer show-quick-jumper @change="change" @showSizeChange="onShowSizeChange" />
                            </div>
                        </div>
                    </a-spin>
                </div>
                <no-data v-else></no-data>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
import { DataList, getLimitCatalogList, getRelateAppList } from './api/index'
import { defaultData, GetLable, resolveTimeStream } from '@/utils/tools'
import { GetDictionary } from '@/API/http'
import NoData from '../component/NoData'

export default {
    name: 'MyApprovalList',
    data() {
        return {
            servicePhoneNum: window.globalData.servicePhoneNum,
            showData: true,
            spinning: true, //页面loading 状态
            nav1Active: 0,
            pageSizeOptions: ['5', '10', '20', '50'],
            current1: 1,
            current2: 1,
            current3: 1,
            current4: 1,
            total1: 0,
            total2: 0,
            total3: 0,
            total4: 0,
            pageSize1: 5,
            pageSize2: 5,
            pageSize3: 5,
            pageSize4: 5,
            serviceState: null,
            serviceState_options: null,
            limitState_options: [
                { value: 0, label: '申请中' },
                { value: 1, label: '审批通过' },
                { value: 2, label: '审批不通过' }
            ],
            limitApplyStatusOptions: [
                { label: '申请中', value: '0' },
                { label: '审批通过', value: '1' },
                { label: '审批不通过', value: '2' }
            ],
            dateFormat: 'YYYY-MM-DD',
            monthFormat: 'YYYY-MM',
            defaultData: defaultData(),
            defaultData1: defaultData(),
            tab1: 1, //我的审批 tab 切换  1 待审批，2 已审批
            nav1Search: {
                title: '',
                serviceState: undefined,
                timeRange: '',
                limitState: undefined
            },
            MyDataList: null, //我的的服务申请列表
            MyLimitCatalogList: [], //受限目录申请列表
            selectPlaceholder: undefined,
            MyAbiliyList: null, //我的能力申请列表
            MyExchangeList: null, //我的交换申请列表
            MyVideoList: null, //我的視頻申请列表
            relateAppList: [], //我的关联应用列表
            interfaceFlowStepList: [
                {
                    key: 1,
                    title: '接口申请',
                    icon: require('@/assets/index/step0.png'),
                    iconActive: require('@/assets/index/step0_active.png')
                },

                {
                    key: 2,
                    title: '数管部门审查',
                    icon: require('@/assets/index/step2.png'),
                    iconActive: require('@/assets/index/step2_active.png')
                }
            ],
            catalogFlowStepList: [
                {
                    key: 1,
                    title: '受限目录申请',
                    icon: require('@/assets/index/step0.png'),
                    iconActive: require('@/assets/index/step0_active.png')
                },
                {
                    key: 2,
                    title: '规范性审查',
                    icon: require('@/assets/index/step1.png'),
                    iconActive: require('@/assets/index/step1_active.png')
                },
                {
                    key: 3,
                    title: '数源部门审查',
                    icon: require('@/assets/index/step2.png'),
                    iconActive: require('@/assets/index/step2_active.png')
                },
                {
                    key: 4,
                    title: '需求复核',
                    icon: require('@/assets/index/step3.png'),
                    iconActive: require('@/assets/index/step3_active.png')
                }
            ],
            catalogApplyStatusList: {
                '0': require('@/assets/index/approve.png'),
                '1': require('@/assets/index/agree.png'),
                '2': require('@/assets/index/refused.png')
            },
            interfaceApplyStatusList: {}
        }
    },
    computed: {},
    components: { NoData },
    methods: {
        moment,
        resolveTimeStream,
        GetLable,
        getRelateAppList() {
            let params = {
                param: {
                    applicationState: 3
                }
            }
            getRelateAppList(params)
                .then(res => {
                    // console.log('getRelateAppList=>', res)
                    this.relateAppList = res.records || []
                })
                .catch(err => {
                    console.log(err)
                })
        },
        getReason(list, type = 1) {
            let attr = type == 1 ? 'handleInfo' : 'handleRemark'
            return list[list.length - 1][attr]
        },
        showGoAddAppConfirm() {
            let _this = this
            this.$confirm({
                class: 'goAddAppConfirm',
                title: '您当前没有审核通过的应用，不可进行接口申请。',
                closable: true,
                cancelText: '确定',
                cancelType: 'primary',
                okText: '前往应用注册',
                okType: 'link',
                maskClosable: true,
                onOk() {
                    _this.$router.push({
                        name: 'MyAppList'
                    })
                },
                onCancel() {
                    console.log('Cancel')
                }
            })
        },
        //重置
        reset() {
            console.log('重置')
            this.nav1Search = {
                title: '',
                serviceState: undefined,
                timeRange: '',
                limitState: undefined
            }
            this.serviceState = ''
            this.defaultData = []
            this.defaultData = defaultData()
            this.defaultData1 = []
            this.defaultData1 = defaultData()
            this.selectPlaceholder = undefined

            this.listInit()
        },
        handleChange(item) {
            // this.nav1Search.limitState = item.key
            // debugger
            this.serviceState = item.value || ''
        },
        disabledDate: current => {
            return current < moment().startOf('day') || current > moment().add(90, 'day')
        },
        onShowSizeChange1() {
            this.listInit()
        },
        onShowSizeChange(current, size) {
            console.log(current, size)
            console.log(this.current1)
            console.log(this.total1)
            console.log(this.pageSize1)
            this.current1 = 1
            this.current2 = 1
            this.current3 = 1
            this.current4 = 1
            this.listInit()
        },

        onChange(dates, dateStrings) {
            if (!this.nav1Active) {
                this.defaultData = [dateStrings[0], dateStrings[1]]
            } else {
                this.defaultData1 = [dateStrings[0], dateStrings[1]]
            }
            console.log('From: ', dates[0], ', to: ', dates[1])
            console.log('From: ', dateStrings[0], ', to: ', dateStrings[1])
        },

        handleMenuClick(e) {
            console.log(e)
        },
        nav1Change(k) {
            this.current1 = 1
            this.current2 = 1
            this.current3 = 1
            this.current4 = 1

            console.log('重置')
            this.nav1Search = {
                title: '',
                serviceState: undefined,
                timeRange: '',
                limitState: undefined
            }
            this.serviceState = ''
            this.defaultData = []
            this.defaultData = defaultData()
            this.defaultData1 = []
            this.defaultData1 = defaultData()
            this.selectPlaceholder = undefined
            this.serviceState = ''
            this.nav1Active = k
            this.listInit()
        },
        change(page) {
            if (!this.nav1Active) {
                this.current1 = page
            } else {
                this.current2 = page
            }
            this.listInit()
        },
        goDeatail(id) {
            this.$router.push({ name: 'MyApplyDetail', query: { id: id, type: 1 } })
        },
        goLimitDetail(id) {
            this.$router.push({ name: 'MyLimitApplyDetail', query: { id: id } })
        },
        goExchangeDeatail(id) {
            this.$router.push({ name: 'MyApplyDetail', query: { id: id, type: 0 } })
        },
        goVideoDeatail(id) {
            this.$router.push({ name: 'MyApplyDetail', query: { id: id, type: 2 } })
        },
        goEdit(id) {
            this.$router.push({ name: 'InterfaceServiceEdit', params: { id } })
        },
        goEditLimitCatalog(id) {
            if (!this.relateAppList.length) {
                //已登录，未申请接口，但是该账号未关联应用，弹出提示去关联应用提示框随后跳到新增关联应用页面
                this.showGoAddAppConfirm()
                return
            }
            this.$router.push({ name: 'DataCatalogEdit', query: { id } })
        },
        goDelete(id) {
            this.$router.push({ name: 'InterfaceServiceApplication', params: { id } })
        },
        async dictionaryInit() {
            await GetDictionary('service_state')
                .then(res => {
                    this.serviceState_options = res
                })
                .catch(e => {
                    console.log(e)
                })
            this.listInit()
        },
        listInit() {
            this.spinning = true
            this.showData = true
            if (this.nav1Active == 0) {
                // 数据、服务申请
                DataList({
                    pageNo: this.current1,
                    pageSize: this.pageSize1,
                    param: {
                        applyformName: this.nav1Search.title,
                        startCreateTime: moment(this.defaultData[0]).format('YYYY-MM-DD') + ' 00:00:00',
                        endCreateTime: moment(this.defaultData[1]).format('YYYY-MM-DD') + ' 23:59:59',
                        serviceState:  this.nav1Search.serviceState||''
                    }
                }).then(res => {
                    this.MyDataList = res.records
                    this.current1 = res.current
                    this.total1 = res.total
                    if (res.records.length > 0) {
                        this.showData = true
                    } else {
                        this.showData = false
                    }
                    setTimeout(() => {
                        this.spinning = false
                    }, 300)
                })
            } else if (this.nav1Active == 1) {
                getLimitCatalogList({
                    pageNo: this.current2,
                    pageSize: this.pageSize2,
                    param: {
                        catalogName: this.nav1Search.title,
                        status: this.nav1Search.limitState,
                        createDate: moment(this.defaultData1[0]).format('YYYY-MM-DD') + ' 00:00:00',
                        updateDate: moment(this.defaultData1[1]).format('YYYY-MM-DD') + ' 23:59:59'
                    }
                }).then(res => {
                    console.log('res=>', res)

                    this.MyLimitCatalogList = res.records
                    this.total2 = res.total
                    if (this.MyLimitCatalogList.length) {
                        this.showData = true
                    } else {
                        this.showData = false
                    }
                    setTimeout(() => {
                        this.spinning = false
                    }, 300)
                })
            }
        },
        goInterfaceApply(item) {
            if (!item.interfaceId || !item.interfaceIsOnline) return this.$message.warn('该目录对应的数据接口没有上线，暂时无法申请')
            if (item.hasApplied) return this.$message.warn('您已申请该目录对应的数据接口，无需重复申请')
            if (!this.relateAppList.length) {
                //已登录，未申请接口，但是该账号未关联应用，弹出提示去关联应用提示框随后跳到新增关联应用页面
                this.showGoAddAppConfirm()
                return
            }
            this.$router.push({
                name: 'InterfaceServiceApplication',
                params: {
                    id: item.interfaceId
                }
            })
        }
    },
    mounted() {
        this.getRelateAppList()
        this.dictionaryInit()
    },
    activated() {
        this.getRelateAppList()
        this.dictionaryInit()
    }
}
</script>

<style scoped lang="scss">
.MyApprovalList {
    .nav1 {
        position: relative;

        .headTab {
            position: relative;
            width: 100%;
            height: 62px;
            /*background: lightblue;*/
            border-bottom: 1px solid #ebeced;
            overflow: hidden;
            .phone-num {
                position: absolute;
                right: 20px;
                top: 60%;
                font-size: 16px;
                transform: translateY(-50%);
                font-weight: 600;
            }
            .navitm {
                font-family: Source Han Sans CN;
                font-size: 16px;
                height: 16px;
                font-weight: normal;
                font-stretch: normal;
                line-height: 16px;
                letter-spacing: 0px;
                color: #999999;
                position: relative;
                margin-top: 32px;
                display: block;
                float: left;
                margin-right: 45px;
                cursor: pointer;
                text-align: center;
                &:first-child {
                    margin-left: 60px;
                }
            }
            .nav1Active {
                color: #000025;
            }
            /*.navitm:after {
                content: '';
                position: absolute;
                left: 0;
                bottom: -13px;
                width: 100%;
                height: 4px;
                background: #357df1;
                display: none;
            }

            .nav1Active:after {
                display: block;
            }*/
        }
        .searchBar {
            width: 882px;
            height: 30px;
            display: block;
            margin: 0 auto;
            margin-top: 24px;
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            align-items: center;
            .bt {
                width: 200px;
                height: 30px;
            }
            .lx {
                width: 200px;
                height: 32px;
                margin-left: 16px;
                .span {
                    width: 80px;
                    height: 30px;
                    line-height: 30px;
                    display: block;
                    float: left;
                }
                /deep/ .ant-btn {
                    width: 120px;
                    display: inline;
                    float: left;
                    height: 32px;
                    border-bottom-left-radius: 0;
                    border-top-left-radius: 0;
                    position: relative;
                    cursor: pointer;
                    margin-left: 0 !important;
                    background: white;
                }
                /deep/ .ant-select-selection--single {
                    height: 32px;
                    border-bottom-left-radius: 0;
                    border-top-left-radius: 0;
                }
                /deep/ .ant-select-selection__rendered {
                    line-height: 32px;
                }
            }
            .sj {
                margin-left: 16px;
                width: 300px;
                height: 30px;
            }
            .cx {
                margin-left: 16px;
                width: 84px;
                height: 30px;
                position: relative;
            }
            .reset {
                margin-left: 16px;
                height: 30px;
                width: 30px;
                background: white;
                border: 1px solid #cccccc;
                border-radius: 4px;
                overflow: hidden;
                cursor: pointer;
                font-size: 18px;
                text-align: center;
                line-height: 30px;
                font-weight: bold;
                transform-origin: center center;
                transform: rotate(-90deg);
            }
        }
        .tb1_1 {
            .cardUl {
                > li {
                    height: 220px;
                    &.hasButton {
                        height: 268px;
                    }
                    .line {
                        left: 620px;
                        height: 180px;
                    }
                    .process {
                        right: 200px;
                    }
                    .processDetail {
                        top: 59px;
                        right: 0;
                        width: 240px;
                        left: initial;
                    }
                    .apply-status {
                        position: absolute;
                        right: 10px;
                        top: 50%;
                        transform: translateY(-50%);
                        text-align: center;
                        width: 240px;
                        > p {
                            font-size: 12px;
                            line-height: 20px;
                            letter-spacing: 0px;
                            color: #8e92a1;
                            margin-top: 12px;

                            word-break: break-all;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                        }
                        .apply_interface_btn {
                            position: absolute;
                            bottom: -50px;
                            left: 50%;
                            transform: translateX(-50%);
                        }
                    }
                    .flow-step-box {
                        position: absolute;
                        top: 150px;
                        left: 20px;
                        .flow-step-list {
                            display: flex;
                            flex-flow: row nowrap;
                            align-items: center;
                            .flow-step-item {
                                text-align: center;

                                .flow-step-img {
                                    position: relative;

                                    &::after {
                                        content: '';
                                        position: absolute;
                                        top: 50%;
                                        transform: translateY(-50%);
                                        right: 0;
                                        left: 0%;
                                        height: 1px;
                                        background-color: #e9e9e9;
                                        z-index: 1;
                                    }

                                    img {
                                        position: relative;
                                        z-index: 2;
                                        width: 32px;
                                        height: 32px;
                                    }
                                }
                                &:first-of-type {
                                    .flow-step-img {
                                        &::after {
                                            left: 50%;
                                        }
                                    }
                                }
                                &:last-of-type {
                                    .flow-step-img {
                                        &::after {
                                            right: 50%;
                                        }
                                    }
                                }

                                .flow-step-title {
                                    position: relative;
                                    font-size: 12px;
                                    color: #c8c8cc;
                                    line-height: 20px;
                                    padding-top: 4px;
                                    width: 156px;
                                }
                                &:not(:last-of-type) {
                                    .flow-step-title {
                                        &::after {
                                            content: '';
                                            position: absolute;
                                            right: -6px;
                                            bottom: 4px;
                                            width: 12px;
                                            height: 12px;
                                            background: url('~@/assets/index/step_arrow.png') no-repeat;
                                        }
                                    }
                                }
                                &.active {
                                    .flow-step-img {
                                        &::after {
                                            background-color: #357df1;
                                        }
                                    }
                                    .flow-step-title {
                                        color: #1d242e;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .cardUl {
            width: 882px;
            min-height: 200px;
            margin: 0 auto;
            background: #ffffff;
            margin-top: 32px;
            .li {
                width: 882px;
                height: 160px;
                background-color: #ffffff;
                box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.1);
                margin-bottom: 24px;
                position: relative;
                cursor: pointer;
                .listIcon {
                    position: absolute;
                    top: 20px;
                    left: 16px;
                    width: 20px;
                    height: 20px;
                    background: url('~@/assets/index/listIcon.png') no-repeat;
                    background-size: contain;
                }
                .listTitle {
                    width: 500px;
                    height: 16px;
                    font-size: 16px;
                    line-height: 16px;
                    letter-spacing: 0px;
                    color: #333333;
                    position: absolute;
                    top: 22px;
                    left: 46px;
                }
                .keys {
                    position: absolute;
                    top: 62px;
                    left: 46px;
                    width: 510px;
                    height: 70px;
                    /*background: #42b983;*/
                    .kvs {
                        width: 250px;
                        height: $fs12;
                        display: block;
                        float: left;
                        font-size: $fs12;
                        line-height: $fs12;
                        color: #1d242e;
                        margin-bottom: 16px;
                        /*background: #1d242e;*/
                    }
                    .blue {
                        color: #0184ff;
                    }
                    .red {
                        color: red;
                    }
                    .kvs:nth-child(2n + 1) {
                        margin-right: 10px;
                    }
                }
                .line {
                    position: absolute;
                    top: 22px;
                    left: 582px;
                    width: 1px;
                    height: 120px;
                    border-right: 1px dashed #d2d3d3;
                }
                .approval {
                    position: absolute;
                    bottom: 16px;
                    left: 401px;
                    height: 30px;
                    line-height: 30px;
                    /* border-radius: 4px; */
                    /* border: solid 1px #0184ff;*/
                    /deep/ .ant-btn {
                        color: #0184ff;
                        border-color: #0184ff;
                    }
                }
                .delete {
                    position: absolute;
                    bottom: 16px;
                    left: 309px;
                    width: 80px;
                    height: 30px;
                    color: red;
                }
                .process {
                    width: 48px;
                    height: $fs12;
                    font-family: Source Han Sans CN;
                    font-size: $fs12;
                    font-weight: normal;
                    font-stretch: normal;
                    line-height: $fs12;
                    letter-spacing: 0px;
                    color: #1d242e;
                    position: absolute;
                    top: 26px;
                    right: 232px;
                }
                .processDetail {
                    width: 280px;
                    height: 102px;
                    /* background: red; */
                    position: absolute;
                    top: 59px;
                    left: 602px;
                    overflow: auto;
                    padding-right: 18px;
                    .prcessLi {
                        width: 260px;
                        height: 12px;
                        margin-bottom: 16px;
                        .keyaction {
                            float: left;
                        }
                        .value {
                            float: right;
                        }
                    }
                }
                /*滚动条样式*/
                .processDetail::-webkit-scrollbar {
                    width: 4px;
                    /*height: 4px;*/
                }
                .processDetail::-webkit-scrollbar-thumb {
                    border-radius: 10px;
                    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                    background: rgba(0, 0, 0, 0.2);
                }
                .processDetail::-webkit-scrollbar-track {
                    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                    border-radius: 0;
                    background: rgba(0, 0, 0, 0.1);
                }
            }
            .hasButton {
                height: 200px;
                .line {
                    height: 160px;
                }
                .processDetail {
                    height: 120px;
                }
            }
        }
        .pagination {
            margin-top: 40px;
            margin-bottom: 48px;
            ul {
                display: flex;
                justify-content: center;
            }
        }
    }

    /deep/ .ant-select-dropdown-menu-item {
        overflow: visible !important;
    }
}
</style>
<style lang="scss">
.goAddAppConfirm {
    position: relative;
    /deep/ .ant-modal-body {
        padding: 60px 80px 24px !important;
    }
    /deep/ .ant-modal-confirm-btns {
        .ant-btn {
            &:first-of-type {
                background: #357df1;
                color: #fff;
                border: 1px solid #357df1;
            }
            &:last-of-type {
                color: #357df1;
                background: transparent;
            }
        }
    }
}
</style>
