<template>
    <div class="UserCenter">
        <div class="center-content">
            <div class="left-content">
                <img :src="user.icon" class="icon" alt="" />
                <span class="welcome" v-if="timeArea">
                    {{ timeArea }}，{{ user.name }}
                    <span class="time">{{ nowTime }}</span>
                </span>
                <ul class="userUl">
                    <li v-for="(item, index) in userRouter" :key="index" :class="{ userLiActive: userIndex === index }" @click="userSelect(item, index)">
                        <span class="actionicon">
                            <a-icon :type="item.icon" />
                        </span>
                        <span class="action">{{ item.name }}</span>
                        <!-- <span class="nums" v-if="index === 0">{{ item.num }}</span> -->
                    </li>
                </ul>
            </div>
            <div class="right-content">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>
import { handleDateToString } from '@/utils/tools'
import { getCookie } from '@/utils/cookie.js'
export default {
    name: 'UserCenter',
    inject: ['reload'],
    data() {
        return {
            user: {
                icon: require('@/assets/index/icon.png'),
                name: '管理员'
            },
            timeArea: '',
            nowTime: handleDateToString(new Date().getTime(), true),
            nowTimeTimer: null,
            userIndex: 0,
            userRouter: [
                { path: 'MyApply', name: '我的申请', icon: 'form', num: 12 },
                { path: 'MyService', name: '我的服务', icon: 'cloud-server', num: 33 },
                { path: 'MyApp', name: '我的应用', icon: 'appstore', num: 12 },
                { path: 'MyCollect', name: '我的收藏', icon: 'star', num: 12 }
            ]
        }
    },
    computed: {
        key() {
            return this.$route.fullPath
        }
    },
    watch: {
        $route: function() {
            this.getActiveIndex()
        }
    },
    methods: {
        //获取时间段
        getTimeArea() {
            let hour = new Date().getHours()
            if (hour < 6) {
                this.timeArea = '凌晨好'
            } else if (hour < 9) {
                this.timeArea = '早上好'
            } else if (hour < 12) {
                this.timeArea = '上午好'
            } else if (hour < 14) {
                this.timeArea = '中午好'
            } else if (hour < 17) {
                this.timeArea = '下午好'
            } else if (hour < 19) {
                this.timeArea = '傍晚好'
            } else if (hour < 22) {
                this.timeArea = '晚上好'
            } else {
                this.timeArea = '夜里好'
            }
        },
        //个人中心跳转
        userSelect(item, key) {
            this.userIndex = key
            console.log(item.path)
            console.log(this.$route)
            this.$router.push({
                name: item.path
            })
            if (item.path === this.$route.path.split('/')[4]) {
                this.reload()
            }
        },
        //获取个人中心当前子页面
        getActiveIndex() {
            let path = this.$route.fullPath.split('/')[4]
            let index = 0
            switch (path) {
                case 'MyApply':
                    index = 0
                    break
                case 'MyService':
                    index = 1
                    break
                case 'MyApp':
                    index = 2
                    break
                case 'MyCollect':
                    index = 3
                    break
            }
            this.userIndex = index
        }
    },
    mounted() {
        this.getActiveIndex()
        //设置全局的个人中心的状态
        this.$store.commit('setUserCenter', true)
        this.getTimeArea()
        //时间更新
        let _this = this // 声明一个变量指向Vue实例this，保证作用域一致 
        _this.nowTime = handleDateToString(new Date().getTime(), true) // 修改数据date
        this.nowTimeTimer = setInterval(() => {
            _this.nowTime = handleDateToString(new Date().getTime(), true) // 修改数据date
        }, 1000)

        try {
            let KF_USER = JSON.parse(getCookie('KF_USER'))
            this.user.name = KF_USER.userName
        } catch (e) {
            console.log(e)
        }
    },
    destroyed() {
        //设置全局的个人中心的状态
        this.$store.commit('setUserCenter', false)
        this.nowTimeTimer && clearInterval(this.nowTimeTimer) //  清除时间定时器
    }
}
</script>

<style scoped lang="scss">
.UserCenter {
    .center-content {
        width: 1200px;
        margin: 0 auto;
        padding: 24px 0 48px 0;
        /*display: flex;*/
        display: table;
        flex-flow: row wrap;
        justify-content: space-around;
        align-items: flex-start;
        overflow: hidden;
        min-height: 1360px;
        .left-content {
            user-select: none;
            display: table-cell;
            width: 254px;
            min-height: 1360px;
            height: 100%;
            background-color: rgba(255, 255, 255, 1);
            background-image: url('~@/assets/index/userbg.png');
            background-repeat: no-repeat;
            background-position: top center;
            background-size: 100%;
            position: relative;
            .icon {
                width: 60px;
                height: 60px;
                background-image: url('~@/assets/index/icon.png');
                background-repeat: no-repeat;
                background-position: top center;
                background-size: 100%;
                position: absolute;
                top: 28px;
                left: 20px;
            }
            .welcome {
                position: absolute;
                top: 38px;
                left: 100px;
                width: 150px;
                height: 16px;
                line-height: 16px;
                font-size: 16px;
                color: #1d242e;
                .time {
                    display: block;
                    font-family: Source Han Sans CN;
                    font-size: 13px;
                    margin-top: 13px;
                    font-weight: normal;
                    font-stretch: normal;
                    letter-spacing: 0px;
                    color: #999999;
                }
            }
            .userUl {
                width: 100%;
                height: 420px;
                margin-top: 114px;
                li {
                    width: 100%;
                    height: 60px;
                    position: relative;
                    line-height: 60px;
                    /*background: rgba(29, 36, 46, 0.19);*/
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: flex-start;
                    align-items: center;
                    cursor: pointer;
                    .actionicon {
                        display: block;
                        width: 30px;
                        height: 30px;
                        box-sizing: border-box;
                        border-radius: 30px;
                        background-color: #ffffff;
                        border: solid 1px #dbdbdb;
                        font-size: 14px;
                        text-align: center;
                        line-height: 30px;
                        margin-left: 20px;
                        cursor: pointer;
                    }
                    .action {
                        width: 56px;
                        height: 14px;
                        font-family: Source Han Sans CN;
                        font-size: 14px;
                        font-weight: normal;
                        font-stretch: normal;
                        line-height: 14px;
                        letter-spacing: 0px;
                        color: #333333;
                        margin-left: 20px;
                    }
                    .nums {
                        margin-left: 105px;
                        font-size: $fs14;
                        font-weight: normal;
                        font-stretch: normal;
                        line-height: $fs14;
                        letter-spacing: 0px;
                    }
                    &:hover,
                    &.userLiActive {
                        .actionicon {
                            border: solid 1px $userCenerActive;
                        }
                        .action {
                            color: $userCenerActive;
                        }
                    }
                    &.userLiActive {
                        background: #f0f2f4;
                    }
                }
            }
        }
        .right-content {
            display: table-cell;
            width: 946px;
            min-height: 1360px;
            background: rgba(253, 253, 253, 1);
            border: 2px solid #ebeced;
            box-sizing: border-box;
            vertical-align: top;
        }
    }
}
</style>
