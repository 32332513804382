import { baseRequest } from '@/API'

export function Test(JSON) {
    return baseRequest({
        url: 'catalog/sysdict/select',
        method: 'POST',
        params: JSON
    })
}

//已审批
export function GetListApproval(JSON) {
    return baseRequest({
        url: '/workflow/irsp/getFinishedTaskList',
        method: 'POST',
        data: JSON
    })
}

//待审批
export function GetListNotApproval(JSON) {
    return baseRequest({
        url: '/workflow/irsp/getCandidateTaskList',
        method: 'POST',
        data: JSON
    })
}
//数据交换详情
export function GetDataExchangeDetail(JSON) {
    return baseRequest({
        url: '/catalog/sysExchange/exchangeDetail',
        method: 'POST',
        params: JSON
    })
}
//接口服务申请 详情
export function InterfaceServiceDetail(JSON) {
    return baseRequest({
        // url: '/interface/resourceservice/myapprovedetail',
        url: '/interface/openservice/myapplydetail',
        method: 'POST',
        data: JSON
    })
}
//我的服务 详情
export function OpenServiceDetail(JSON) {
    return baseRequest({
        // url: '/interface/resourceservice/myapprovedetail',
        url: '/interface/openservice/myservicedetail',
        method: 'POST',
        data: JSON
    })
}
//视频申请 详情
export function VideoDetail(JSON) {
    return baseRequest({
        url: '/catalog/sysvideoapply/selectOne',
        method: 'POST',
        params: JSON
    })
} //视频申请 分页列表
export function VideoChannelList(JSON) {
    return baseRequest({
        url: '/catalog/sysvideoapply/channelPage',
        method: 'POST',
        data: JSON
    })
}

//数据交换 审批
export function DataExchangeDetailApproval(JSON) {
    return baseRequest({
        url: '/catalog/resourceExchange/originDptAudit',
        method: 'POST',
        data: JSON
    })
}
//数据交换 审批
export function ServiceApproval(JSON) {
    return baseRequest({
        url: '/catalog/sysExchange/originDptAudit',
        method: 'POST',
        data: JSON
    })
}
//视频 审批
export function VideoApproval(JSON) {
    return baseRequest({
        url: '/catalog/sysvideoapply/companyAppr',
        method: 'POST',
        data: JSON
    })
}
//视频申请 分页列表
export function ServiceDetail(JSON) {
    return baseRequest({
        url: '/interface/serviceinfo/detail',
        method: 'POST',
        data: JSON
    })
}
