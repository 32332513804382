<template>
    <div class="MyServiceList">
        <div data-title="我的审批">
            <div class="nav1">
                <div class="head" style="text-align: left">
                    <span>我的接口服务</span>
                    <p class="phone-num" v-if="servicePhoneNum">业务服务电话：{{ servicePhoneNum }}</p>
                </div>
                <div class="searchBar">
                    <div class="bt">
                        <a-input v-model.trim="nav1Search.title" addon-before="服务名称" placeholder="输入标题搜索" />
                    </div>
                    <div class="sj">
                        <a-range-picker :value="defaultData" :format="dateFormat" @change="onChange" :allowClear="false" />
                    </div>
                    <div class="cx">
                        <a-button type="primary" style="width: 100%;" @click="search">
                            查询
                        </a-button>
                    </div>
                    <div class="reset" @click="reset">
                        <a-icon type="redo" />
                    </div>
                </div>
                <div class="tb1_1">
                    <div v-if="showData">
                        <a-spin :spinning="spinning" tip="数据加载中...">
                            <ul class="cardUl">
                                <li class="li" v-for="(item, index) in MyServiceList" :key="index">
                                    <i class="listIcon"></i>
                                    <span class="listTitle" @click="goDeatail(item.id)" :title="item.applyformName">{{ item.applyformName }}</span>
                                    <p class="btn_test" @click="showInterfaceTestModal(item)">接口调用测试</p>
                                    <ul class="keys">
                                        <li class="kvs" style="    white-space: nowrap;  overflow: hidden;  text-overflow: ellipsis;" :title="item.interfaceName">接口名称 ：{{ item.interfaceName }}</li>
                                        <li class="kvs" style="    white-space: nowrap;  overflow: hidden;  text-overflow: ellipsis;" :title="item.id">申请单号 ：{{ item.id }}</li>
                                        <li class="kvs">关联应用 ： {{ item.applicationName }}</li>
                                        <li class="kvs">申请时间： {{ formatTime(item.createDate) }}</li>
                                        <li class="kvs">申请人 ：{{ item.applicantName }}</li>
                                        <li class="kvs">授权时间：{{ formatTime(item.updateDate) }}</li>
                                    </ul>
                                    <span class="line"></span>

                                    <span class="process">使用信息</span>
                                    <ul class="processDetail">
                                        <li class="prcessLi">
                                            <span class="solid solid8"></span>
                                            <span class="showNum">
                                                当日调用次数:
                                                <span class="value">{{ item.callTimeByDay }}次</span>
                                            </span>
                                        </li>
                                        <li class="prcessLi">
                                            <span class="solid solid8"></span>
                                            <span class="showNum">
                                                累计调用次数:
                                                <span class="value">{{ item.callTimeTotal }}次</span>
                                            </span>
                                        </li>
                                        <li class="prcessLi">
                                            <span class="solid solid9"></span>
                                            <span class="showNum">
                                                累计服务时长:
                                                <span class="value red" style="color: red">{{ item.totalServiceTime }}</span>
                                            </span>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <div class="pagination">
                                <a-pagination v-model="current" :total="total" :page-size-options="pageSizeOptions" @change="change" :page-size.sync="pageSize" show-size-changer show-quick-jumper @showSizeChange="onShowSizeChange" />
                            </div>
                        </a-spin>
                    </div>
                    <no-data v-else></no-data>
                </div>
            </div>
        </div>
        <InterfaceTestModal :visible="interfaceTestModalVisible" :interfaceTestData="interfaceTestData" @cancel="cancelInterfaceTest"></InterfaceTestModal>
    </div>
</template>

<script>
import moment from 'moment'
import { DataList, getInterfaceTestInfo } from './api/index'
import { defaultData, formatTime } from '@/utils/tools'
import NoData from '@/views/Home/component/NoData'
import InterfaceTestModal from '@/views/Home/component/InterfaceTestModal'
export default {
    name: 'MyServiceList',
    data() {
        return {
            servicePhoneNum: window.globalData.servicePhoneNum,
            showData: true,
            spinning: true, //页面loading 状态
            pageSize: 5,
            pageSizeOptions: ['5', '10', '20', '50'],
            current: 1,
            total: 1,
            nav1Active: 1, //我的 审批默认tab 1
            dateFormat: 'YYYY-MM-DD',
            monthFormat: 'YYYY-MM',
            defaultData: defaultData(),
            tab1: 1, //我的审批 tab 切换  1 待审批，2 已审批
            nav1Search: {
                title: '',
                type: '',
                timeRange: ''
            },
            interfaceTestData: {},
            interfaceTestModalVisible: false,
            MyServiceList: null //我的的服务申请列表
        }
    },
    components: { NoData, InterfaceTestModal },
    methods: {
        moment,
        formatTime,
        //重置
        reset() {
            console.log('重置')
            this.nav1Search.title = ''
            this.defaultData = defaultData()
            this.current = 1
            this.listInit()
        },
        search() {
            this.current = 1
            this.listInit()
        },
        disabledDate: current => {
            return current < moment().startOf('day') || current > moment().add(90, 'day')
        },
        onShowSizeChange() {
            this.current = 1
            this.listInit()
        },
        onChange(dates, dateStrings) {
            this.defaultData = [dateStrings[0], dateStrings[1]]
            console.log('From: ', dates[0], ', to: ', dates[1])
            console.log('From: ', dateStrings[0], ', to: ', dateStrings[1])
        },
        change(page) {
            this.current = page
            this.listInit()
        },
        handleMenuClick(e) {
            console.log(e)
        },
        nav1Change(k) {
            this.nav1Active = k
        },
        goDeatail(id) {
            this.$router.push({ name: 'MyServiceDetail', query: { id: id, showAll: true } })
        },
        listInit() {
            this.spinning = true
            this.MyServiceList = null
            // 数据、服务申请
            console.log(' 数据、服务申请 初始化')
            DataList({
                pageNo: this.current,
                pageSize: this.pageSize,
                param: {
                    applyformName: this.nav1Search.title,
                    startCreateTime: moment(this.defaultData[0]).format('YYYY-MM-DD') + ' 00:00:00',
                    endCreateTime: moment(this.defaultData[1]).format('YYYY-MM-DD') + ' 23:59:59'
                }
            }).then(res => {
                this.MyServiceList = res.records
                this.current = res.current
                this.total = res.total
                if (res.records.length > 0) {
                    this.showData = true
                } else {
                    this.showData = false
                }
                setTimeout(() => {
                    this.spinning = false
                }, 300)
            })
        },
        showInterfaceTestModal(data) {
            // 显示接口测试弹窗
            console.log('data=>', data)

            getInterfaceTestInfo({ id: data.id }).then(res => {
                this.interfaceTestData = { id: data.id, interfaceName: data.interfaceName, catalogId: data.catalogId, data: res }
                this.$nextTick(() => {
                    this.interfaceTestModalVisible = true
                })

                console.log('res===>', this.interfaceTestData)
            })
        },
        cancelInterfaceTest() {
            this.interfaceTestModalVisible = false
        }
    },
    activated() {
        this.listInit()
    }
}
</script>

<style scoped lang="scss">
.MyServiceList {
    .nav1 {
        .head {
            position: relative;
            width: 100%;
            height: 62px;
            box-sizing: border-box;
            border-bottom: 1px solid #ebeced;
            padding-left: 34px;
            padding-top: 32px;
            font-size: $fs16;
            color: #000025;
            .phone-num {
                position: absolute;
                right: 20px;
                top: 60%;
                font-size: 16px;
                transform: translateY(-50%);
                font-weight: 600;
            }
        }
        .searchBar {
            width: 882px;
            height: 30px;
            display: block;
            margin: 0 auto;
            margin-top: 24px;
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            align-items: center;
            .bt {
                width: 200px;
                height: 30px;
            }
            .lx {
                width: 200px;
                height: 32px;
                margin-left: 16px;
                .span {
                    width: 80px;
                    height: 30px;
                    line-height: 30px;
                    display: block;
                    float: left;
                }
                /deep/.ant-btn {
                    width: 120px;
                    display: inline;
                    float: left;
                    height: 32px;
                    border-bottom-left-radius: 0;
                    border-top-left-radius: 0;
                    position: relative;
                    cursor: pointer;
                    margin-left: 0 !important;
                    background: white;
                }
                /deep/ .ant-select-selection--single {
                    height: 32px;
                    border-bottom-left-radius: 0;
                    border-top-left-radius: 0;
                }
                /deep/ .ant-select-selection__rendered {
                    line-height: 32px;
                }
            }
            .sj {
                margin-left: 16px;
                width: 300px;
                height: 30px;
            }
            .cx {
                margin-left: 16px;
                width: 84px;
                height: 30px;
                position: relative;
                margin-left: 232px;
            }
            .reset {
                margin-left: 16px;
                height: 30px;
                width: 30px;
                background: white;
                border: 1px solid #cccccc;
                border-radius: 4px;
                overflow: hidden;
                cursor: pointer;
                font-size: 18px;
                text-align: center;
                line-height: 30px;
                font-weight: bold;
                transform-origin: center center;
                transform: rotate(-90deg);
            }
        }
        .cardUl {
            width: 882px;
            min-height: 500px;
            margin: 0 auto;
            background: #ffffff;
            margin-top: 32px;
            .li {
                width: 882px;
                height: 160px;
                background-color: #ffffff;
                box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.1);
                margin-bottom: 24px;
                position: relative;
                cursor: pointer;
                .listIcon {
                    position: absolute;
                    top: 20px;
                    left: 16px;
                    width: 20px;
                    height: 20px;
                    background: url('~@/assets/index/listIcon.png') no-repeat;
                    background-size: contain;
                }
                .btn_test {
                    position: absolute;
                    left: 480px;
                    top: 20px;
                    color: $userCenerActive;
                }
                .listTitle {
                    width: 420px;
                    height: 16px;
                    font-size: 16px;
                    line-height: 16px;
                    letter-spacing: 0px;
                    color: #333333;
                    position: absolute;
                    top: 22px;
                    left: 46px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .keys {
                    position: absolute;
                    top: 62px;
                    left: 46px;
                    width: 510px;
                    height: 70px;
                    /*background: #42b983;*/
                    .kvs {
                        width: 250px;
                        height: $fs12;
                        display: block;
                        float: left;
                        font-size: $fs12;
                        line-height: $fs12;
                        color: #1d242e;
                        margin-bottom: 16px;
                        /*background: #1d242e;*/
                    }
                    .kvs:nth-child(2n + 1) {
                        margin-right: 10px;
                    }
                }
                .line {
                    position: absolute;
                    top: 22px;
                    left: 582px;
                    width: 1px;
                    height: 120px;
                    border-right: 1px dashed #d2d3d3;
                }
                .approval {
                    position: absolute;
                    top: 154px;
                    left: 401px;
                    width: 80px;
                    height: 30px;
                    /*border-radius: 4px;*/
                    /*border: solid 1px #0184ff;*/
                    /deep/ .ant-btn {
                        color: #0184ff;
                        border-color: #0184ff;
                    }
                }
                .process {
                    width: 48px;
                    height: $fs12;
                    font-family: Source Han Sans CN;
                    font-size: $fs12;
                    font-weight: normal;
                    font-stretch: normal;
                    line-height: $fs12;
                    letter-spacing: 0px;
                    color: #1d242e;
                    position: absolute;
                    top: 22px;
                    right: 232px;
                }
                .processDetail {
                    width: 280px;
                    height: 100px;
                    /* background: red; */
                    position: absolute;
                    top: 43px;
                    left: 602px;
                    overflow: hidden;
                    .prcessLi {
                        width: 260px;
                        height: 26px;
                        margin-bottom: 10px;
                        padding-left: 36px;
                        position: relative;
                        .solid {
                            width: 26px;
                            height: 26px;
                            position: absolute;
                            left: 0;
                            top: 0;
                        }
                        .showNum {
                            line-height: 26px;
                            font-size: $fs12;
                            color: rgba(0, 0, 0, 0.45);
                        }
                        .keyaction {
                            float: left;
                        }
                        .value {
                            color: rgba(0, 0, 0, 0.85);
                            margin-left: 10px;
                        }
                    }
                }
                /*滚动条样式*/
                .processDetail::-webkit-scrollbar {
                    width: 4px;
                    /*height: 4px;*/
                }
                .processDetail::-webkit-scrollbar-thumb {
                    border-radius: 10px;
                    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                    background: rgba(0, 0, 0, 0.2);
                }
                .processDetail::-webkit-scrollbar-track {
                    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
                    border-radius: 0;
                    background: rgba(0, 0, 0, 0.1);
                }
            }
        }
        .pagination {
            margin-top: 40px;
            margin-bottom: 48px;
            ul {
                display: flex;
                justify-content: center;
            }
        }
    }
}
</style>
