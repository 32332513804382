import { baseRequest } from '@/API'

//数据申请 列表
export function DataList(JSON) {
    return baseRequest({
        // url: '/interface/resourceservice/myintercaceapply',
        url: '/interface/openservice/myintercaceapply',
        method: 'POST',
        data: JSON
    })
}
//我的受限目录申请 列表
export function getLimitCatalogList(params) {
    return baseRequest({
        url: '/catalog/opencatalogapply/myrescatalogapply',
        method: 'POST',
        data: params
    })
}

//能力申请 列表
export function AbilityList(JSON) {
    return baseRequest({
        url: '/interface/resourceservice/myabilityapply',
        method: 'POST',
        data: JSON
    })
}
//交换申请 列表
export function ExchangeList(JSON) {
    return baseRequest({
        url: '/catalog/resourceExchange/myApplyPage',
        method: 'POST',
        data: JSON
    })
}
//視頻申请 列表
export function VideoList(JSON) {
    return baseRequest({
        url: '/catalog/sysvideoapply/selectVideoPage',
        method: 'POST',
        data: JSON
    })
}

//我的受限目录申请详情
export function myLimitCatalogDetail(JSON) {
    return baseRequest({
        url: '/catalog/opencatalogapply/rescatalogapplydetail',
        method: 'POST',
        data: JSON
    })
}
// 获取所有字典项
export function GetDicts(query) {
    return baseRequest({
        // baseURL: BaseUrl1,
        url: '/catalog/sysdict/selectAll',
        method: 'POST',
        params: query
    })
}
export function getRelateAppList(params) {
    return baseRequest({
        url: '/application/open/page',
        method: 'post',
        data: params
    })
}
