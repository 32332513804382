<template>
    <a-modal v-model="show" title="接口调用测试" :footer="null" @cancel="cancel" :width="720">
        <div class="wrap">
            <section class="row">
                <p class="title">
                    接口名称：
                    <span>{{ interfaceTestData.interfaceName }}</span>
                </p>
            </section>
            <section class="row">
                <p class="title">请求参数</p>
                <div class="params_table">
                    <div class="params_table_head">
                        <p class="">
                            参数名称
                        </p>
                        <p style="width: 240px;">
                            参数值
                        </p>
                    </div>
                    <div class="params_table_body" v-if="interfaceTestData.data">
                        <ul class="params_list">
                            <li class="params_item" v-for="(item, index) in paramsList" :key="item.key">
                                <div class="params_item_wrap">
                                    <p class="params_name">
                                        {{ item.key }}
                                        <a-popover>
                                            <template slot="content">
                                                {{ item.explain }}
                                            </template>
                                            <a-icon type="info-circle" class="icon_eyes" />
                                        </a-popover>
                                    </p>
                                    <template v-if="interfaceTestData.catalogId">
                                        <template v-if="paramsList.length - 1 === index">
                                            <section class="params_value">
                                                <a type="link" @click="getSign(item)" v-if="!item.value">获取签名</a>
                                                <a-popover v-else>
                                                    <template slot="content">
                                                        {{ item.value }}
                                                    </template>
                                                    <span>{{ item.value }}</span>
                                                </a-popover>
                                            </section>
                                        </template>
                                        <a-popover v-else>
                                            <template slot="content">
                                                {{ item.value }}
                                            </template>
                                            <section class="params_value">
                                                {{ item.value }}
                                            </section>
                                        </a-popover>
                                    </template>
                                    <a-input class="params_value" v-model.trim="item.value" v-else />
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <section class="row">
                <p class="title">
                    返回请求
                </p>
                <div class="response_data_area">
                    <a-textarea class="response_data" v-model="responseData" readOnly></a-textarea>
                </div>
            </section>
            <section class="row">
                <div class="btn_gotest">
                    <a-button type="primary" @click="submit">开始调用</a-button>
                </div>
            </section>
        </div>
    </a-modal>
</template>
<script>
import { getSign, getInterfaceTestResult } from '../api/index'
export default {
    name: 'InterfaceTestModal',
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        interfaceTestData: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            show: false,
            loading: false,
            responseData: '',
            paramsList: []
        }
    },
    computed: {
        // paramsList() {
        //     let params = this.interfaceTestData.data.params
        //     return !this.interfaceTestData.catalogId ? params : params.slice(0, params.length - 1)
        // }
    },
    components: {},
    watch: {
        visible() {
            this.show = this.visible
        },
        interfaceTestData: {
            deep: true,
            handler() {
                this.paramsList = this.interfaceTestData.data.params
            }
        }
    },
    mounted() {},

    methods: {
        getSign(data) {
            getSign({ ...this.interfaceTestData.data }).then(res => {
                this.interfaceTestData.data.params = this.paramsList.map(item => {
                    if (item.key === data.key) {
                        item.value = res
                    }
                    return item
                })
            })
        },
        cancel() {
            this.responseData = ''
            this.$emit('cancel')
        },
        isSignEmpty() {
            let value = this.paramsList[this.paramsList.length - 1].value
            return !value
        },
        submit() {
            if (this.interfaceTestData.catalogId && this.isSignEmpty()) {
                return this.$message.warn('请先获取签名')
            }

            if (this.loading) return
            this.loading = true
            getInterfaceTestResult({ ...this.interfaceTestData.data }).then(res => {
                this.responseData = res
                this.loading = false
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.wrap {
    .row {
        margin-bottom: 24px;
        .title {
            font-weight: 600;
            padding-left: 12px;
            border-left: 4px solid #0284ff;
        }
        .response_data_area {
            padding: 20px 0;
            .response_data {
                padding: 10px 20px;
                height: 180px;
                resize: none;
            }
        }

        .btn_gotest {
            text-align: center;
            margin: 0 auto;
        }
        .params_table {
            padding: 20px 0;
            .params_table_head {
                display: flex;
                justify-content: space-between;
                padding: 10px 20px 10px;
                border: 1px solid #d5d6e3;
                align-items: center;
                font-weight: 600;
                background-color: #eef1f8;
            }
            .params_table_body {
                .params_list {
                    .params_item {
                        padding: 10px 20px;
                        border: 1px solid #d5d6e3;
                        border-top: none;
                        .params_item_wrap {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            .params_name {
                                flex: 1;
                                padding-right: 12px;
                                .icon_eyes {
                                    padding-left: 4px;
                                    color: $userCenerActive;
                                    cursor: pointer;
                                }
                            }
                            .params_value {
                                width: 240px;
                            }
                            section.params_value {
                                word-break: break-all;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                display: -webkit-box;
                                -webkit-line-clamp: 2;
                                -webkit-box-orient: vertical;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
