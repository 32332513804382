<template>
    <div class="container">
        <a-breadcrumb>
            <a-breadcrumb-item v-for="item in levelList" :key="item.path">
                <router-link :to="item.redirect || item.path" class="no-redirect" style="cursor: pointer">{{ item.meta.name }}</router-link>
            </a-breadcrumb-item>
        </a-breadcrumb>
        <main class="main">
            <div class="content">
                <p style="text-align:center;font-size:30px;font-weight:bold;margin-top:20px;margin-bottom:30px;">网站声明</p>
                <div id="zoom" style="margin-top: 10px; text-align: left; color: #454545; padding-left: 50px; padding-right: 50px">
                    <p style="box-sizing: border-box; padding: 0px; outline: none; border: 0px; color: rgb(69, 69, 69); font-family: 微软雅黑; white-space: normal; background-color: rgb(255, 255, 255); text-indent: 2em">在您访问或使用舟山市·公共数据开放平台前，请认真阅读本协议。</p>
                    <p style="box-sizing: border-box; padding: 0px; outline: none; border: 0px; color: rgb(69, 69, 69); font-family: 微软雅黑; white-space: normal; background-color: rgb(255, 255, 255); text-indent: 2em">如使用本平台，您必须完全接受本协议中所包含的条款、条件和平台即时刊登的通告，并且遵守中华人民共和国关于互联网的相关法律、规定与规则。</p>
                    <p style="box-sizing: border-box; padding: 0px; outline: none; border: 0px; color: rgb(69, 69, 69); font-family: 微软雅黑; white-space: normal; background-color: rgb(255, 255, 255); text-indent: 2em">本平台有权不经提前通知随时修改本协议。本协议一经变动，将在相关页面提示修改内容；用户如果不同意本协议的修改，可以放弃访问或使用舟山市·公共数据开放平台提供的任何数据、产品和服务；如果用户在协议变动后继续访问或使用舟山市·公共数据开放平台，则视为已经接受协议的变动。</p>
                    <p style="box-sizing: border-box; padding: 0px; outline: none; border: 0px; color: rgb(69, 69, 69); font-family: 微软雅黑; white-space: normal; background-color: rgb(255, 255, 255); text-indent: 2em">
                        <span style="box-sizing: border-box; font-size: 18px">
                            <strong style="box-sizing: border-box">
                                一、用户权利和义务
                                <span style="box-sizing: border-box; font-size: 16px"></span>
                            </strong>
                        </span>
                    </p>
                    <p style="box-sizing: border-box; padding: 0px; outline: none; border: 0px; color: rgb(69, 69, 69); font-family: 微软雅黑; white-space: normal; background-color: rgb(255, 255, 255); text-indent: 2em">1．现阶段，用户有权免费获取本平台所提供的所有公共数据资源，享有数据资源的非排他使用权。用户可以自由地复制、发布、利用、传播和分享信息，不受歧视。&nbsp;</p>
                    <p style="box-sizing: border-box; padding: 0px; outline: none; border: 0px; color: rgb(69, 69, 69); font-family: 微软雅黑; white-space: normal; background-color: rgb(255, 255, 255); text-indent: 2em">2．用户在使用本平台数据资源所产生的成果中应注明公共数据资源来源为“舟山市·公共数据开放平台”，及从平台下载的日期。</p>
                    <p style="box-sizing: border-box; padding: 0px; outline: none; border: 0px; color: rgb(69, 69, 69); font-family: 微软雅黑; white-space: normal; background-color: rgb(255, 255, 255); text-indent: 2em">3.用户基于本平台公共数据资源开发的各类应用和服务，应遵守有关法律、法规和规章的规定，不得用于任何可能危害国家安全和社会公共利益、侵犯商业秘密和个人隐私或其他不正当的用途。因与第三方产生纠纷或者违反法律法规等造成的后果，由用户自行承担。</p>
                    <p style="box-sizing: border-box; padding: 0px; outline: none; border: 0px; color: rgb(69, 69, 69); font-family: 微软雅黑; white-space: normal; background-color: rgb(255, 255, 255); text-indent: 2em">4.用户及时将应用情况通过网站进行备案，并应积极配合有关的用户需求调查和数据资源调查。</p>
                    <p style="box-sizing: border-box; padding: 0px; outline: none; border: 0px; color: rgb(69, 69, 69); font-family: 微软雅黑; white-space: normal; background-color: rgb(255, 255, 255); text-indent: 2em">5.用户基于本平台公共数据资源进行开发利用时，应清楚地表明所做的任何数据分析或应用是个人或公司的行为，不得歪曲篡改从本平台获取的公共数据资源。</p>
                    <p style="box-sizing: border-box; padding: 0px; outline: none; border: 0px; color: rgb(69, 69, 69); font-family: 微软雅黑; white-space: normal; background-color: rgb(255, 255, 255); text-indent: 2em">6.&nbsp;用户在基于本平台数据资源的应用程序或互联网服务中应清楚地声明:源于平台的相关数据受舟山市·公共数据开放平台相关协议、条款约束。以上义务同样需要得到您的再授权用户同意并认可。</p>
                    <p style="box-sizing: border-box; padding: 0px; outline: none; border: 0px; color: rgb(69, 69, 69); font-family: 微软雅黑; white-space: normal; background-color: rgb(255, 255, 255); text-indent: 2em">7.如果本平台数据服务内容由于法律法规或政策调整等原因进行调整，用户应立即在相关应用程序或互联网服务中作相应调整。</p>
                    <p style="box-sizing: border-box; padding: 0px; outline: none; border: 0px; color: rgb(69, 69, 69); font-family: 微软雅黑; white-space: normal; background-color: rgb(255, 255, 255); text-indent: 2em">8.用户应遵守中华人民共和国有关互联网管理的相关法律法规和规章，以及所有与本平台网络服务有关的网络协议、规定和程序。禁止对本平台进行技术性破坏，不得干扰或妨害舟山市·公共数据开放平台提供正常的互联网服务。用户发现任何非法使用用户帐号或安全漏洞的情况，应当立即告知本平台运营管理方。</p>
                    <p style="box-sizing: border-box; padding: 0px; outline: none; border: 0px; color: rgb(69, 69, 69); font-family: 微软雅黑; white-space: normal; background-color: rgb(255, 255, 255); text-indent: 2em">9.用户义务是本许可的重要条件，如未遵守以上要求，则根据本许可授予的权利将自动终止，用户应立即删除相关数据并停止服务。</p>
                    <p style="box-sizing: border-box; padding: 0px; outline: none; border: 0px; color: rgb(69, 69, 69); font-family: 微软雅黑; white-space: normal; background-color: rgb(255, 255, 255); text-indent: 2em">
                        <span style="box-sizing: border-box; font-size: 18px"><strong style="box-sizing: border-box">二、平台权利及免责声明</strong></span>
                    </p>
                    <p style="box-sizing: border-box; padding: 0px; outline: none; border: 0px; color: rgb(69, 69, 69); font-family: 微软雅黑; white-space: normal; background-color: rgb(255, 255, 255); text-indent: 2em">1.因国家法律法规和政策调整，或因多条数据关联后可能造成隐私泄露及其它原因不宜继续公开的数据，本平台有权随时将其下线，用户不得以任何理由继续保存或使用该数据，否则造成的后果由您自行承担。</p>
                    <p style="box-sizing: border-box; padding: 0px; outline: none; border: 0px; color: rgb(69, 69, 69); font-family: 微软雅黑; white-space: normal; background-color: rgb(255, 255, 255); text-indent: 2em">2.本平台所有数据资源均为公共管理和服务部门在履职过程中采集和获取，由于未采集新数据或其他原因可能造成数据质量波动，及更新推迟或中止。本平台对所提供数据的完整性、准确性、及时性和服务连续性不作任何承诺或担保，并且不对因使用本平台所提供的数据或服务可能造成的任何损失承担责任。用户应自行判断是否继续使用本平台提供的数据或服务。</p>
                    <p style="box-sizing: border-box; padding: 0px; outline: none; border: 0px; color: rgb(69, 69, 69); font-family: 微软雅黑; white-space: normal; background-color: rgb(255, 255, 255); text-indent: 2em">3.本平台不对由于操作或传播的延迟、通讯失败,互联网接入设备的困难，硬件或软件故障而造成的直接或间接损失承担责任。</p>
                    <p style="box-sizing: border-box; padding: 0px; outline: none; border: 0px; color: rgb(69, 69, 69); font-family: 微软雅黑; white-space: normal; background-color: rgb(255, 255, 255); text-indent: 2em">4.任何由于黑客攻击、计算机病毒侵入或发作、政府管制而造成的暂时性关闭等影响网络正常经营及其他不可抗力原因而造成的资料泄露、丢失、被盗用或被篡改等，本平台将采取必要措施尽力减少用户的损失，但本平台对此不承担任何责任。</p>
                    <p style="box-sizing: border-box; padding: 0px; outline: none; border: 0px; color: rgb(69, 69, 69); font-family: 微软雅黑; white-space: normal; background-color: rgb(255, 255, 255); text-indent: 2em">5.&nbsp;舟山市·公共数据开放平台包含到其他网站的链接，这些网站内容和隐私策略可能和我们不同。本平台不对由于这些网站内容和隐私策略造成的后果承担责任。</p>
                    <p style="box-sizing: border-box; padding: 0px; outline: none; border: 0px; color: rgb(69, 69, 69); font-family: 微软雅黑; white-space: normal; background-color: rgb(255, 255, 255); text-indent: 2em">
                        <strong style="box-sizing: border-box"><span style="box-sizing: border-box; font-size: 18px">三、隐私保护声明</span></strong>
                    </p>
                    <p style="box-sizing: border-box; padding: 0px; outline: none; border: 0px; color: rgb(69, 69, 69); font-family: 微软雅黑; white-space: normal; background-color: rgb(255, 255, 255); text-indent: 2em">本平台尊重并保护所有用户的个人隐私权，未经用户许可或根据相关法律、法规或规章规定，以及维护舟山市·公共数据开放平台合法权益之外，本平台不会将用户个人信息提供给任何第三方，但是会根据工作需要与政府有关部门共同使用。</p>
                    <p><br /></p>
                    <p><br /></p>
                    <p><br /></p>
                </div>
            </div>
        </main>
    </div>
</template>

<script>
import { handleDateToString } from '@/utils/tools'
export default {
    data() {
        return {
            lastBread: '',
            id: '',
            levelList: null,
        }
    },
    created() {
        let id = this.$route.params.id
        this.lastBread = this.$route.params.breadTitl
        this.getBreadcrumb()
    },
    methods: {
        getBreadcrumb() {
            let matched = this.$route.matched.filter(item => item.name)
            const first = matched[0]
            matched[matched.length - 1].meta.name = this.lastBread
            if (first && (first.name !== '首页' || first.path !== '')) {
                matched = [
                    {
                        name: '首页',
                        path: '/Home',
                        meta: {
                            requiresAuth: false,
                            name: '首页'
                        }
                    }
                ].concat(matched)
            }
            console.log('matched=>', matched)
            this.levelList = matched.slice(0, 2)
        }
    },
    watch: {
        $route() {
            this.getBreadcrumb()
        }
    }
}
</script>
<style lang="scss">
.bsBox {
    box-sizing: content-box;
}
</style>
<style lang="scss" scoped>
.container {
    width: 1200px;
    height: 100%;
    min-height: 927px;
    position: relative;
    margin: 0 auto;
    padding: 33px 0 48px;
    font-family: Source Han Sans CN;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0;
    // display: flex;
    // justify-content: flex-start;
    // align-items: flex-start;
    .content-breadcrumb {
        margin-bottom: 80px;
    }
    .main {
        .headbox {
            .headbox-head {
                height: 48px;
                text-align: center;
                font-size: 28px;
                line-height: 48px;
                letter-spacing: 2px;
                color: rgba(0, 0, 0, 0.85);
                margin-bottom: 32px;
            }
            .headbox-headinfo {
                position: relative;
                height: 16px;
                text-align: center;
                line-height: 16px;
                font-size: 14px;
                color: rgba(0, 0, 0, 0.45);
                margin-bottom: 32px;
                & > section {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-right: 100px;
                    span {
                        margin-right: 20px;
                    }
                }

                .share-box {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    display: flex;
                    align-items: center;
                    & > span {
                        font-size: 12px;
                    }
                    .share-main {
                        display: flex;
                        align-items: center;
                        & > section {
                            flex-shrink: 0;
                            width: 24px;
                            padding: 0 4px;
                            cursor: pointer;
                            img {
                                max-width: 100%;
                            }
                        }
                    }
                }
            }
        }
        .content {
            div,p{
                line-height: 32px;
            }
            strong{
                line-height: 38px;
            }
            .content-pic {
                display: flex;
                justify-content: center;
                margin-bottom: 20px;
            }
            .content-txt {
                font-size: 18px;
                line-height: 28px;
                color: rgba(0, 0, 0, 0.65);
            }
            .content-file {
                span {
                    color: #409eff;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>
