<template>
    <div class="MyAppEdit">
        <div class="head" @click="reloadTest" style="text-align: left">{{ type === 'add' ? '新增' : '编辑' }}注册应用</div>
        <div class="body">
            <a-form :form="form" @submit="handleSubmit">
                <table class="table" border="1">
                    <tbody>
                        <tr>
                            <td>
                                <div>
                                    <div class="key">
                                        <span class="red">*</span>
                                        应用名称
                                    </div>
                                    <div class="value">
                                        <a-form-item>
                                            <a-input size="small" id="appName" v-decorator="['appName', { initialValue: table.appName, rules: [{ required: true, message: '请输入应用名称' }] }]" placeholder="请输入应用名称" setFieldsValue="table.appName" autocomplete="off"></a-input>
                                        </a-form-item>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <div class="key">
                                        <span class="red">*</span>
                                        应用编号
                                    </div>
                                    <div class="value">
                                        <input type="text" v-model="table.appCode" placeholder="应用编码自动生成无需输入" disabled style="background: rgba(230,230,230,0.17);cursor: not-allowed" autocomplete="off" />
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div>
                                    <div class="key">
                                        <span class="red">*</span>
                                        数据领域
                                    </div>
                                    <div class="value special">
                                        <a-form-item>
                                            <a-select placeholder="请选择数据领域" v-decorator="['fieldId', { initialValue: table.fieldId, rules: [{ required: true, message: '请选择所属领域' }] }]" style="width: 100%;height: 100%" size="large">
                                                <a-select-option :value="item.value" v-for="(item, index) in DataDomainSelect_options" :key="index">
                                                    {{ item.label }}
                                                </a-select-option>
                                            </a-select>
                                        </a-form-item>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <div class="key">
                                        <span class="red">*</span>
                                        负责人
                                    </div>
                                    <div class="value">
                                        <a-form-item>
                                            <a-input size="small" v-decorator="['name', { initialValue: table.name, rules: [{ required: true, message: '请输入负责人姓名' }] }]" placeholder="请输入负责人姓名" setFieldsValue="table.name" autocomplete="off"></a-input>
                                        </a-form-item>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div>
                                    <div class="key">
                                        <span class="red">*</span>
                                        负责人电话
                                    </div>
                                    <div class="value">
                                        <a-form-item>
                                            <a-input
                                                size="small"
                                                v-decorator="[
                                                    'mobile',
                                                    {
                                                        initialValue: table.mobile,
                                                        rules: [
                                                            {
                                                                pattern: /^1[0-9]{10}$/,
                                                                message: '请输入正确的手机号'
                                                            },
                                                            { required: true, message: '请输入负责人电话' }
                                                        ]
                                                    }
                                                ]"
                                                placeholder="请输入负责人电话"
                                                setFieldsValue="table.mobile"
                                                autocomplete="off"
                                            ></a-input>
                                        </a-form-item>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <div class="key">
                                        <span class="red">*</span>
                                        负责人邮箱
                                    </div>
                                    <div class="value " label="email">
                                        <a-form-item>
                                            <a-input
                                                size="small"
                                                v-decorator="[
                                                    'email',
                                                    {
                                                        initialValue: table.email,
                                                        rules: [
                                                            {
                                                                type: 'email',
                                                                message: '邮箱格式不正确'
                                                            },
                                                            { required: true, message: '请输入负责人邮箱' }
                                                        ]
                                                    }
                                                ]"
                                                placeholder="请输入负责人邮箱"
                                                setFieldsValue="table.email"
                                                autocomplete="off"
                                            ></a-input>
                                        </a-form-item>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div>
                                    <div class="key">
                                        <span class="red">*</span>
                                        应用开发者
                                    </div>
                                    <div class="value " label="email">
                                        <a-form-item>
                                            <a-input
                                                size="small"
                                                v-decorator="[
                                                    'developer',
                                                    {
                                                        initialValue: table.developer,
                                                        rules: [{ required: true, message: '请输入应用开发者' }]
                                                    }
                                                ]"
                                                placeholder="请输入应用开发者"
                                                setFieldsValue="table.developer"
                                                autocomplete="off"
                                            ></a-input>
                                        </a-form-item>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <div class="key">
                                        <span class="red">*</span>
                                        应用描述
                                    </div>
                                    <div class="value " label="email">
                                        <a-form-item>
                                            <a-input size="small" style="width: 580px" v-decorator="['applicationDesc', { initialValue: table.applicationDesc,rules: [{ required: true, message: '请输入应用描述' }] }]" placeholder="请输入应用描述" setFieldsValue="table.applicationDesc" autocomplete="off"></a-input>
                                        </a-form-item>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="appImg">
                    <a-upload name="avatar" list-type="picture-card" class="avatar-uploader" :show-upload-list="false" :before-upload="beforeUpload" @change="handleChange">
                        <img class="avatar" v-if="imageUrl" :src="imageUrl" alt="avatar" />
                        <div v-else>
                            <a-icon :type="loading ? 'loading' : 'cloud-upload'" style="font-size: 60px" />
                            <div class="ant-upload-text">
                                点击上传
                            </div>
                        </div>
                    </a-upload>
                    <span class="loadTip">请上传图标大小为：200*200px</span>
                    <!--                    <img :src="imageUrl" v-if="imageUrl" class="imgDetail" alt="" />-->
                </div>
                <div class="relateApp">
                    <span class="commonTitle">白名单管理</span>
                    <span class="addList" @click="addList">添加行</span>
                    <a-table class="list" rowKey="id" :columns="columns" :data-source="dataSource" :pagination="false">
                        <template slot="name" slot-scope="text, record, index">
                            <input class="ipipt" type="text" placeholder="请输入IP备注" v-model="record.name" />
                        </template>
                        <template slot="ipaddress" slot-scope="text, record, index">
                            <input class="ipipt" type="text" placeholder="请输入IP地址" v-model.trim="record.ipaddress" />
                        </template>
                        <template slot="actions" slot-scope="text, record, index">
                            <span class="seeDeatilBtn" @click="deleteItem(text, record, index)">删除</span>
                        </template>
                    </a-table>
                </div>
                <div class="relateApp">
                    <span class="commonTitle">应用上架信息</span>
                    <div class="app-type-info-box">
                        <section class="app-type-info-tab">
                            <p>
                                应用上架类型
                            </p>
                            <a-radio-group :default-value="appType" v-model="appType" @change="changeAppType">
                                <a-radio-button value="1">
                                    Web应用
                                </a-radio-button>
                                <a-radio-button value="2">
                                    App应用
                                </a-radio-button>
                            </a-radio-group>
                        </section>
                        <section class="app-type-info-tab" v-if="appType == '1'">
                            <p>
                                <span class="red">*</span>
                                Web应用访问地址
                            </p>
                            <a-input v-model="webUrl" placeholder="请输入Web应用访问地址"></a-input>
                        </section>
                        <section class="app-type-info-tab" v-if="appType != '1'">
                            <div style="margin-right:24px">
                                <p><span class="red">*</span>安卓版二维码</p>
                                <a-upload list-type="picture-card" class="avatar-uploader" :show-upload-list="false" :action="''" :before-upload="beforeUploadQRCode" :customRequest="customRequest0" v-if="qrCodeFile.android == ''">
                                    <div>
                                        <a-icon type="plus" />
                                    </div>
                                </a-upload>
                                <div class="img-box" v-else>
                                    <img :src="qrCodeFile.android" alt="" />
                                    <section class="img-mask">
                                        <a-icon type="delete" @click="qrCodeFile.android = ''" />
                                    </section>
                                </div>
                            </div>
                            <div>
                                <p><span class="red">*</span>iOS版二维码</p>
                                <a-upload list-type="picture-card" class="avatar-uploader" :show-upload-list="false" :action="''" :before-upload="beforeUploadQRCode" :customRequest="customRequest1" v-if="qrCodeFile.ios == ''">
                                    <div>
                                        <a-icon type="plus" />
                                    </div>
                                </a-upload>
                                <div class="img-box" v-else>
                                    <img :src="qrCodeFile.ios" alt="" />
                                    <section class="img-mask">
                                        <a-icon type="delete" @click="qrCodeFile.ios = ''" />
                                    </section>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                <div class="actions">
                    <a-button class="infoButton" type="primary" html-type="submit">提交</a-button>
                    <a-button class="infoButton" @click="goback">取消</a-button>
                </div>
            </a-form>
        </div>
    </div>
</template>

<script>
import { GetDictionary } from '@/API/http'
import { getCookie } from '@/utils/cookie'
import { MyAppDetail, MyAppEdit, UploadImg, DataDomainSelect } from './api/index'
import { GetLable } from '@/utils/tools'
import { uploadFile } from '@/API/http'
function getBase64(img, callback) {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
}

const columns = [
    {
        title: 'IP地址',
        dataIndex: 'ipaddress',
        scopedSlots: { customRender: 'ipaddress' }
    },
    {
        title: 'IP备注',
        dataIndex: 'name',
        scopedSlots: { customRender: 'name' }
    },
    {
        title: '操作',
        dataIndex: 'actions',
        scopedSlots: { customRender: 'actions' }
    }
]
const data = []
export default {
    name: 'MyAppDetail',
    beforeCreate() {
        this.form = this.$form.createForm(this, { name: 'register' })
    },
    data() {
        return {
            webUrl: '',
            qrCodeFile: {
                android: '',
                ios: ''
            },
            appType: '1',
            changed: false,
            loading: false,
            imageUrl: '',
            dataSource: [{ ipaddress: '' }],
            columns,
            type: '',
            DataDomainSelect_options: null,
            table: {
                appName: undefined, //应用名称
                appCode: undefined, // 应用编号
                name: JSON.parse(getCookie('KF_USER')).userName, //负责人 负责人
                mobile: undefined, //负责人 负责人
                email: undefined, //负责人  负责人邮箱
                iconUrl: undefined, // 图标
                developer: undefined, // 开发者
                fieldId: undefined, // 所属领域id
                fieldName: undefined, // 所属领域名称
                applicationDesc: undefined // 应用描述
            },
            KF_USER: JSON.parse(getCookie('KF_USER')),
            formData: ''
        }
    },
    methods: {
        reloadTest() {},
        beforeUploadQRCode(file) {
            let fileName = file.name.toLowerCase()
            let accecptType = ['png', 'jpeg', 'jpg']
            const isCorrectType = accecptType.some(item => {
                return fileName.includes(item)
            })
            if (!isCorrectType) {
                this.$message.error('图片格式不支持，请重新上传！')
                return false
            }
            const isLt10M = file.size / 1024 / 1024 < 10
            if (!isLt10M) {
                this.$message.error('图片大小不得超过10MB!')
                return false
            }
        },
        customRequest0(data) {
            this.uploadQRCode('android', data)
        },
        customRequest1(data) {
            this.uploadQRCode('ios', data)
        },
        uploadQRCode(type, data) {
            let formData = new FormData()
            formData.append('file', data.file)
            formData.append('defaultFileName', true)
            uploadFile(formData)
                .then(res => {
                    console.log('res=>', res)
                    let fileUrl = res.serverIpAndPort + res.serverPath
                    this.qrCodeFile[type] = fileUrl
                })
                .catch(err => {})
        },
        deleteItem(text, record, index) {
            console.log(text, record, index)
            this.dataSource.splice(index, 1)
            if (this.dataSource.length == 0) {
                this.dataSource.push({ name: '', ipaddress: '' })
            }
        },
        addList() {
            this.dataSource.push({ key: this.dataSource.length + 1, name: '', ipaddress: '' })
        },
        changeAppType(e) {
            console.log('e=>', e)
        },
        handleChange(file) {
            this.changed = true
            this.formData = new FormData()
            this.formData.append('file', file.file)
            getBase64(file.file, imageUrl => {
                this.imageUrl = imageUrl
                this.table.iconUrl = imageUrl
            })
        },
        beforeUpload(file) {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
            const isLt2M = file.size / 1024 / 1024 < 2
            if (!isJpgOrPng) {
                this.$message.error('文件格式不正确，请选择JPG或者PNG格式图片!')
            } else if (!isLt2M) {
                this.$message.error('图片大小不要超过2M！')
            }
            if (isJpgOrPng && isLt2M) {
                console.log(file)
            }
            // return isJpgOrPng && isLt2M
            return false
        },
        goback() {
            this.$router.go(-1)
        },
        handleSubmit(e) {
            e.preventDefault()
            console.log('handleSubmit')
            console.log('this.dataSource=>', this.dataSource)
            let checkEmptyIpAddress = this.dataSource.some(item => {
                return !item.ipaddress
            })
            if (checkEmptyIpAddress) {
                this.$message.warn('IP地址不能为空！')
                return
            }
            this.form.validateFieldsAndScroll((err, values) => {
                console.log('validateFieldsAndScroll=>', err, values)

                if (!err) {
                    console.log('Received values of form: ', values)
                    if(this.appType==1 && !this.webUrl){
                        this.$message.warn('Web应用访问地址不能为空！')
                        return false
                    }
                    if(this.appType!=1 && ( !this.qrCodeFile.android || !this.qrCodeFile.ios )){
                        this.$message.warn('二维码不能为空！')
                        return false
                    }
                    this.submit(values)
                }
            })
        },
        submit(form) {
            if (this.changed) {
                UploadImg(this.formData)
                    .then(res => {
                        this.table.iconUrl = res.serverIpAndPort + res.serverPath
                        console.log(this.table.iconUrl)
                        this.loadAction(form)
                    })
                    .catch(() => {
                        this.$message.error('图片上传失败，请稍候重试')
                    })
            } else {
                this.loadAction(form)
            }
        },
        loadAction(form) {
            let json = {
                applicationName: form.appName.trim(),
                chargePersionEmail: form.email,
                chargePersionName: form.name,
                chargePersionPhone: form.mobile,
                iconUrl: this.table.iconUrl,
                ipAddress: JSON.stringify(this.dataSource),
                developer: form.developer, // 开发者
                fieldId: form.fieldId, // 数据领域id
                applicationDesc: form.applicationDesc, // 应用描述
                fieldName: GetLable(form.fieldId, this.DataDomainSelect_options),
                appResultType: this.appType,
                webUrl: this.webUrl,
                androidQrcode: this.qrCodeFile.android,
                androidUrl: this.qrCodeFile.android,
                iosQrcode: this.qrCodeFile.ios,
                iodUrl: this.qrCodeFile.ios
            }
            if (this.type !== 'add') {
                json.id = this.$route.query.id
            }
            console.log(json)
            MyAppEdit(json)
                .then(res => {
                    this.$message.success('提交成功')
                    this.$router.push({
                        name: 'MyAppList',
                        query: { reload: 'true' }
                    })
                })
                .catch(err => {
                    console.log('err==>', err)
                    this.$message.error(err.data.msg)
                })
        },

        async dictionaryInit() {
            try {
                await DataDomainSelect()
                    .then(res => {
                        this.DataDomainSelect_options = []
                        res.map(item => {
                            this.DataDomainSelect_options.push({
                                value: item.id,
                                label: item.name
                            })
                        })
                        // this.table.fieldId=res[0].id
                    })
                    .catch(e => {
                        console.log(e)
                    })
            } catch (e) {
                console.log(e)
            }
        },
        getDetail() {
            MyAppDetail({ id: this.$route.query.id }).then(res => {
                console.log(res)
                this.webUrl = res.appResultInfo.webUrl || ''
                this.qrCodeFile.android = res.appResultInfo.androidQrcode || ''
                this.qrCodeFile.ios = res.appResultInfo.iosQrcode || ''
                this.appType = res.appResultInfo.appResultType || '1'
                this.table.appName = res.applicationInfoEntity.applicationName
                this.table.appCode = res.applicationInfoEntity.appkey
                this.table.name = res.applicationInfoEntity.chargePersionName
                this.table.mobile = res.applicationInfoEntity.chargePersionPhone
                this.table.email = res.applicationInfoEntity.chargePersionEmail
                this.table.iconUrl = res.applicationInfoEntity.iconUrl
                this.table.developer = res.applicationInfoEntity.developer
                this.table.fieldId = res.applicationInfoEntity.fieldId
                this.table.applicationDesc = res.applicationInfoEntity.applicationDesc
                let data = JSON.parse(res.applicationInfoEntity.ipAddress)
                let arr = []
                this.imageUrl = res.applicationInfoEntity.iconUrl
                data.map(item => {
                    let json = {
                        key: arr.length + 1,
                        name: item.name,
                        ipaddress: item.ipaddress
                    }
                    arr.push(json)
                })
                console.log(arr)
                this.dataSource = arr
            })
        }
    },
    mounted() {
        this.dictionaryInit()
        this.type = this.$route.query.type
        if (this.type !== 'add') {
            this.dataSource = data
            console.log('这里是编辑')
            this.getDetail()
        }
    },
    destroyed() {
        this.table = null
    }
}
</script>

<style scoped lang="scss">
.avatar-uploader > .ant-upload {
    width: 128px;
    height: 128px;
}
.ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
}
.ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
}
/deep/ .ant-upload.ant-upload-select-picture-card {
    display: table;
    float: left;
    width: 160px;
    height: 160px;
    /* margin-right: 8px; */
    /* margin-bottom: 8px; */
    text-align: center;
    vertical-align: top;
    background-color: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 4px;
    cursor: pointer;
    transition: border-color 0.3s ease;
}
.MyAppEdit {
    .commonTitle {
        width: 100%;
        height: 20px;
        line-height: 20px;
        padding-left: 12px;
        color: #000025;
        position: relative;
        display: block;
        &::after {
            content: '';
            width: 4px;
            height: 20px;
            background-color: #0284ff;
            position: absolute;
            left: 0;
            top: 0;
        }
    }
    .head {
        width: 100%;
        height: 62px;
        box-sizing: border-box;
        border-bottom: 1px solid #ebeced;
        padding-left: 34px;
        padding-top: 32px;
        font-size: $fs16;
        color: #000025;
    }
    .body {
        width: 882px;
        display: block;
        margin: 0 auto;
        margin-top: 32px;
        position: relative;
        table,
        table td,
        table th {
            border: 1px solid #dbdbdb;
            border-collapse: collapse;
        }
        .table {
            width: 680px;
            height: 28px;
            td {
                $theight: 50px;
                width: 50%;
                height: $theight;
                padding: 0;
                position: relative;
                div {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: flex-start;
                    align-items: center;
                    .key {
                        display: inline-block;
                        width: 100px;
                        height: 50px;
                        line-height: 50px;
                        text-align: left;
                        box-sizing: border-box;
                        padding-right: 8px;
                        color: #000025;
                        box-sizing: border-box;
                        padding-left: 12px;
                        /*background-color: #f9f9f9;*/
                        /*border-right: solid 1px #dbdbdb;*/
                        .red {
                            color: red;
                        }
                    }
                    .value {
                        display: inline-block;
                        height: $theight;
                        line-height: $theight;
                        box-sizing: border-box;
                        padding-left: 0px;
                        color: #000025;
                        position: relative;
                        width: 238px;
                        /deep/ input {
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            left: 0;
                            top: 0;
                            background: none;
                            outline: none;
                            border: none;
                            padding: 12px;
                            width: 229px;
                            height: 50px;
                            /* background: red; */
                            position: absolute;
                            left: 0;
                            top: 0px;
                        }
                        /deep/ .ant-input:focus {
                            border-color: #40a9ff;
                            border-right-width: 1px !important;
                            outline: 0;
                            border: none;
                            box-shadow: none;
                        }
                    }
                }
            }
        }
        .appImg {
            width: 160px;
            height: 160px;
            position: absolute;
            right: 0;
            top: 0;
            /*border: solid 1px rgba(193, 200, 210, 0.5);*/
            /*background: url('~@/assets/index/appdefault.png') no-repeat;*/
            /*background-size: contain;*/
            .imgDetail {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }
        }
        .loadTip {
            width: 167px;
            height: 14px;
            font-family: Source Han Sans CN;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            line-height: 22px;
            letter-spacing: 0px;
            color: rgba(0, 0, 0, 0.45);
            position: absolute;
            right: -8px;
            top: 167px;
        }
        .relateApp {
            margin-top: 36px;
            position: relative;
            .addList {
                position: absolute;
                right: 0;
                top: 0;
                font-family: Source Han Sans CN;
                text-decoration: underline;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                line-height: 12px;
                letter-spacing: 0px;
                color: #0184ff;
                cursor: pointer;
            }
            .list {
                margin-top: 16px;
                border-top: 1px solid #d5d6e3;
                table,
                table td,
                table th {
                    border: 1px solid #dbdbdb;
                    border-collapse: collapse;
                }
                /deep/ .ant-table-thead {
                    border: solid 1px #d5d6e3;
                }
                /deep/ .ant-table-thead > tr > th {
                    padding: 0 16px;
                    overflow-wrap: break-word;
                    height: 30px;
                    line-height: 30px;
                    background-color: #eef1f8;
                    color: #000025;
                    font-size: $fs12;
                }
                /deep/ .ant-table-tbody > tr > td {
                    padding: 0 16px;
                    overflow-wrap: break-word;
                    height: 30px;
                    line-height: 30px;
                    background: #f4f5f9;
                    color: #000025;
                }
                .seeDeatilBtn {
                    cursor: pointer;
                    color: #ff0000;
                }
            }
        }
        .ipipt {
            width: 100%;
            height: 100%;
            /* position: absolute;
left: 0;
top: 0;*/
            background: none;
            outline: none;
            border: none;
            padding: 0;
        }
        .actions {
            margin-top: 40px;
            display: flex;
            width: 100%;
            height: 40px;
            justify-content: center;
            align-items: center;
            .infoButton {
                width: 120px;
                height: 40px;
                margin-right: 32px;
            }
        }
    }
    /deep/ .ant-form-explain {
        position: absolute;
        left: 12px;
        bottom: -53px;
        width: 224px;
    }
    /deep/ .ant-select-lg .ant-select-selection--single {
        height: 50px;
        width: 238px;
        border: none;
        box-shadow: none;
    }
    .special /deep/ .ant-form-explain {
        bottom: -3px;
    }
    /deep/ .ant-select-lg .ant-select-selection__rendered {
        line-height: 50px;
    }
    .avatar {
        display: block;
        width: 150px;
        height: 150px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    .app-type-info-tab {
        display: flex;
        align-items: center;
        margin-top: 24px;
        p {
            margin-right: 12px;
            padding: 12px 0;
            width: 140px;
        }
        input {
            width: 320px;
        }
        ::v-deep .ant-upload-select-picture-card {
            margin: 0;
            width: 168px;
            height: 168px;
        }
        .img-box {
            position: relative;
            width: 168px;
            height: 168px;
            background-color: #fafafa;
            border: 1px dashed #d9d9d9;
            border-radius: 4px;
            overflow: hidden;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: content-box;
            .img-mask {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                z-index: 2;
                background: rgba(0, 0, 0, 0.6);
                opacity: 0;
                transition: all 0.4s ease;
                i {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 3;
                    font-size: 20px;
                    color: red;
                    cursor: pointer;
                }
            }
            &:hover {
                .img-mask {
                    opacity: 1;
                }
            }
            img {
                max-height: 100%;
            }
        }
    }
}
</style>
