<template>
    <div class="MyLimitApplyDetail">
        <div class="head">我的受限目录申请详细信息</div>
        <div class="body">
            <main class="main">
                <div class="main-info">
                    <section class="main-block">
                        <div class="main-title">
                            <SameStyleBox title="申请信息"></SameStyleBox>
                        </div>
                        <div class="apply-info-table">
                            <section class="apply-info-item">
                                <p class="">
                                    目录名称
                                </p>
                                <div class="apply-info-data">
                                    <span>{{ dataInfo.catalogName }}</span>
                                </div>
                            </section>
                            <section class="apply-info-item">
                                <p class="">
                                    申请人姓名
                                </p>
                                <div class="apply-info-data">
                                    <span>{{ dataInfo.name }}</span>
                                </div>
                            </section>
                            <section class="apply-info-item">
                                <p class="">
                                    申请人电话
                                </p>
                                <div class="apply-info-data">
                                    <span>{{ dataInfo.phone }}</span>
                                </div>
                            </section>
                            <section class="apply-info-item">
                                <p class="">
                                    申请人邮箱
                                </p>
                                <div class="apply-info-data">
                                    <span>{{ dataInfo.email }}</span>
                                </div>
                            </section>
                            <section class="apply-info-item">
                                <p class="">
                                    数源部门
                                </p>
                                <div class="apply-info-data">
                                    <span>{{ dataInfo.dataSourceDept }}</span>
                                </div>
                            </section>
                            <section class="apply-info-item">
                                <p class="">
                                    应用场景
                                </p>
                                <div class="apply-info-data">
                                    <span>{{ dataInfo.applyScene }}</span>
                                </div>
                            </section>
                        </div>
                    </section>
                    <section class="main-block" v-if="parameterData.dataSource.length">
                        <div class="main-title">
                            <SameStyleBox title="服务使用信息"></SameStyleBox>
                        </div>
                        <div class="modal-table">
                            <a-table rowKey="nameCn" :columns="parameterData.columns" :pagination="false" :data-source="parameterData.dataSource">
                                <span slot="dataType" slot-scope="value">{{ getLable(value, labelOptions.dataTypeOptions) }}</span>
                                <span slot="key" slot-scope="value">{{ getLable(value, labelOptions.iskeyOptions) }}</span>
                                <span slot="blank" slot-scope="value">{{ getLable(value, labelOptions.isBlankOptions) }}</span>
                                <span slot="dic" slot-scope="value">{{ getLable(value, labelOptions.isDicOptions) }}</span>
                                <span slot="open" slot-scope="value">{{ getLable(value, labelOptions.isOpenOptions) }}</span>
                            </a-table>
                            <div class="pagination" style="text-align: right;padding-top: 12px;">
                                <a-pagination :current="pageOptions.pageNo" :defaultPageSize="pageOptions.pageSize" :total="dataList.length" :show-total="total => `共${dataList.length} 条数据`" @showSizeChange="handleSizeChange" @change="handlePageChange" />
                            </div>
                        </div>
                    </section>
                    <section class="main-block">
                        <div class="main-title">
                            <SameStyleBox title="附件材料"></SameStyleBox>
                        </div>
                        <div class="main-content">
                            <a-table rowKey="fileName" :columns="fileTable.columns" :pagination="false" :data-source="fileTable.fileList" class="file-table">
                                <template slot="actions" slot-scope="text, record">
                                    <!-- {{ record }} -->
                                    <span class="mbtn btn_upload" @click="downLoad(record)">{{ record.originalName | getFileName }}</span>
                                </template>
                            </a-table>
                        </div>
                    </section>
                </div>
            </main>
            <!-- <ApplicationChooseModal :show="showAppChooseModal" @confirm="confirmChooseApp" @cancel="cancelChooseApp"></ApplicationChooseModal> -->

            <approval-process :processData="dataInfo.flowHistory"></approval-process>
            <a-button class="goback" @click="goback">返回</a-button>
        </div>
    </div>
</template>

<script>
import { handleDateToString } from '@/utils/tools'
import { myLimitCatalogDetail, GetDicts } from './api'
import { showStateLabel, getLable } from '@/utils/tools'
import { DataSetDetailList } from '@/views/OpenData/dict'
import ApprovalProcess from '@/views/Home/component/ApprovalProcess'
export default {
    name: 'MyLimitApplyDetail',
    data() {
        return {
            labelOptions: {
                dataTypeOptions: [],
                iskeyOptions: [
                    {
                        label: '是',
                        value: '1'
                    },
                    {
                        label: '否',
                        value: '2'
                    }
                ],
                isBlankOptions: [
                    {
                        label: '是',
                        value: '1'
                    },
                    {
                        label: '否',
                        value: '2'
                    }
                ],
                isDicOptions: [
                    {
                        label: '否',
                        value: ''
                    },
                    {
                        label: '是',
                        value: '1'
                    },
                    {
                        label: '否',
                        value: '2'
                    }
                ],
                isOpenOptions: []
            },
            parameterData: {
                columns: [],
                dataSource: []
            },
            fileTable: {
                columns: [
                    {
                        title: '附件材料',
                        dataIndex: 'fileName'
                    },
                    {
                        title: '',
                        dataIndex: 'actions',
                        scopedSlots: { customRender: 'actions' },
                        width: '35%'
                    }
                ],
                fileList: []
            },
            dataInfo: {},
            pageOptions: {
                pageNo: 1,
                pageSize: 5
            },

            dataList: [],
            processData: null
        }
    },
    filters: {
        getFileName: function(value) {
            if (!value) return ''
            value = value.toString()
            if (value.indexOf('/') !== -1) {
                value = value.substring(value.lastIndexOf('/') + 1)
                return value
            } else {
                return value
            }
        }
    },
    components: {
        ApprovalProcess
    },
    methods: {
        getLable,
        // 页码改变事件
        handlePageChange(index) {
            this.pageOptions.pageNo = index
            this.parameterData.dataSource = this.dataList.slice((this.pageOptions.pageNo - 1) * this.pageOptions.pageSize, this.pageOptions.pageNo * this.pageOptions.pageSize)
        },
        // 每页展示条数改变事件
        handleSizeChange(index, size) {
            console.log(index, size)
            this.pageOptions.pageNo = 1
            this.pageOptions.pageSize = size
            this.parameterData.dataSource = this.dataList.slice((this.pageOptions.pageNo - 1) * this.pageOptions.pageSize, this.pageOptions.pageNo * this.pageOptions.pageSize)
        },
        GetDicts() {
            // business_code0:业务类型,interface_protocol:协议(http等),call_mode:接口调用方式(get,post),coding:编码格式(utf-8等)
            let params = {
                types: 'public_property, interface_state, interface_protocol, call_mode,isOpen,dataType'
            }
            GetDicts(params)
                .then(res => {
                    // console.log('GetDicts=>', res)
                    this.labelOptions.isOpenOptions = res.isOpen
                    this.labelOptions.dataTypeOptions = res.dataType
                })
                .catch(err => {
                    // console.log(err)
                })
        },
        downLoad(item) {
            window.open(item.fileUrl)
        },
        goback() {
            console.log('-1')
            this.$router.go(-1)
        },
        resolveTime(time) {
            let str = handleDateToString(time, true)
            return str.split(' ')[0] + ' ' + str.split(' ')[1]
        },
        handleProcess(e) {
            this.processData = e
        },
        getLimitCatalogDetail() {
            let _this = this
            myLimitCatalogDetail({ id: this.$route.query.id })
                .then(res => {
                    console.log(res)
                    this.dataInfo = { ...res.catalogApply, collectionStructure: res.collectionStructure }
                    let data = eval(res.collectionStructure)
                    Object.keys(DataSetDetailList).forEach(item => {
                        _this.parameterData.columns.push({
                            title: DataSetDetailList[item],
                            dataIndex: item,
                            key: item,
                            width: '140px',
                            fixed: item == 'name' ? 'left' : '',
                            scopedSlots: { customRender: item }
                        })
                    })
                    // _this.parameterData.dataSource = data
                    _this.dataList = data
                    _this.parameterData.dataSource = _this.dataList.slice((this.pageOptions.pageNo - 1) * this.pageOptions.pageSize, this.pageOptions.pageNo * this.pageOptions.pageSize)
                    try {
                        let fileList = JSON.parse(res.catalogApply.fileList) || []
                        _this.fileTable.fileList = fileList.length ? fileList : []
                    } catch (error) {
                        _this.fileTable.fileList = []
                    }

                    console.log(_this.fileTable)
                })
                .catch(() => {})
        }
    },
    mounted() {
        this.GetDicts()
        this.getLimitCatalogDetail()
    }
}
</script>

<style scoped lang="scss">
.MyLimitApplyDetail {
    .head {
        width: 100%;
        height: 62px;
        box-sizing: border-box;
        border-bottom: 1px solid #ebeced;
        padding-left: 34px;
        padding-top: 32px;
        font-size: $fs16;
        color: #000025;
    }
    .body {
        padding: 38px 30px;
        .main {
            background: #fff;
            .header {
                display: flex;
                flex-flow: row nowrap;
                padding-bottom: 27px;
                border-bottom: 2px solid #ebeced;
                .title {
                    color: #000025;
                    font-size: 30px;
                    font-weight: bold;
                    padding-right: 14px;
                }
            }
            .modal-table {
                ::v-deep table {
                    border: 1px solid #e8e8e8;
                    max-height: 400px;
                    overflow-y: scroll;
                    .ant-table-tbody {
                        tr {
                            td {
                                /* width: 140px !important;
                                &:nth-of-type(1) {
                                    width: 300px !important;
                                }
                                &:nth-of-type(2) {
                                    width: 280px !important;
                                } */
                            }
                        }
                    }
                }
            }
            .main-info {
                .main-block {
                    margin-bottom: 18px;
                    .interface-box {
                        .interface-card {
                            background-color: #ffffff;
                            border: solid 1px #cccccc;
                            padding: 20px 30px;
                            .interface-header {
                                display: flex;
                                align-items: center;
                                aside {
                                    width: 40px;
                                    height: 40px;
                                    border: solid 1px rgba(193, 200, 210, 0.5);
                                    overflow: hidden;
                                    img {
                                        max-width: 100%;
                                        height: auto;
                                        vertical-align: top;
                                    }
                                }
                                .interface-title {
                                    font-size: 16px;
                                    letter-spacing: 1px;
                                    color: #0184ff;
                                    margin-left: 16px;
                                    font-weight: bold;
                                }
                                .interface-tags {
                                    display: flex;
                                    align-items: center;
                                    margin-left: 34px;
                                    flex-shrink: 0;
                                    .tag {
                                        padding: 6px 14px;
                                        border-radius: 4px;
                                        font-size: 12px;
                                    }
                                    .tag-normal {
                                        background-color: rgba(24, 144, 255, 0.1);
                                        color: #1890ff;
                                    }
                                    .tag-success {
                                        background-color: rgba(19, 194, 194, 0.1);
                                        color: #13c2c2;
                                    }
                                    .tag-warning {
                                        background-color: rgba(255, 1, 1, 0.1);
                                        color: #ff0101;
                                    }
                                    .mgr16 {
                                        margin-right: 16px;
                                    }
                                }
                            }
                            .interface-selections {
                                display: flex;
                                justify-content: space-between;
                                margin-top: 30px;
                            }
                        }
                    }
                    .apply-info-table {
                        display: flex;
                        flex-flow: row wrap;
                        border-top: 1px solid #d5d6e3;
                        border-bottom: none;
                        .apply-info-item {
                            display: flex;
                            width: 50%;
                            flex-shrink: 0;
                            align-content: center;
                            /* border: 1px solid #d5d6e3; */
                            height: 50px;
                            line-height: 50px;
                            padding: 0 20px;
                            border-bottom: 1px solid #d5d6e3;
                            &:first-of-type {
                                width: 100%;
                                .apply-info-data {
                                    /* margin-left: 46px; */
                                    width: 100%;
                                }
                            }
                            &:last-of-type {
                                width: 100%;
                                border-right: 1px solid #d5d6e3;
                            }
                            &:nth-of-type(odd) {
                                border-right: 1px solid #d5d6e3;
                                border-left: 1px solid #d5d6e3;
                            }
                            &:nth-of-type(even) {
                                border-left: 1px solid #d5d6e3;
                            }
                            > p {
                                position: relative;
                                flex-shrink: 0;
                                color: #000025;
                                font-size: $fs12;
                                width: 90px;
                                .require {
                                    position: absolute;
                                    left: -8px;
                                    top: 0;
                                    color: #ff0000;
                                }
                            }
                            .apply-info-data {
                                margin-left: auto;
                                /* width: 420px; */
                                flex: 1;
                                .apply-info-input {
                                    width: 100%;
                                    background: transparent;
                                    border: none;
                                    outline: none;
                                    box-shadow: none;
                                    color: rgba(0, 0, 0, 0.65);
                                }
                                /deep/ .ant-select-selection__rendered {
                                    margin-left: 0px;
                                }
                            }
                        }
                    }
                    .use-limit {
                        display: flex;
                        justify-content: space-between;
                        .limit-item {
                            display: flex;
                            align-items: center;
                            margin-left: 12px;
                            > p {
                                margin-right: 10px;
                                font-size: $fs12;
                                color: #000025;
                            }
                            .limit-nums {
                                width: 140px;
                                height: 30px;
                                background-color: #ffffff;
                                border: solid 1px #cccccc;
                                padding: 0 10px;
                                display: flex;
                                align-items: center;
                                /deep/ .ant-input-number {
                                    border: none;
                                    background: transparent;
                                    outline: none;
                                    box-shadow: none;
                                    height: auto;
                                }
                                /deep/ .ant-form-explain {
                                    left: -12px;
                                    bottom: -10px;
                                    white-space: nowrap;
                                }
                                > span {
                                    margin-left: auto;
                                    font-size: $fs12;
                                    color: #000025;
                                }
                            }
                        }
                    }
                    .whitelist {
                        table {
                            width: 100%;
                            border-left: solid 1px #d5d6e3;
                            border-right: solid 1px #d5d6e3;
                            tr {
                                border-bottom: 1px solid #d5d6e3;
                                font-size: $fs12;
                                &:first-of-type {
                                    border-top: 1px solid #d5d6e3;
                                }
                                th {
                                    width: 50%;
                                    height: 30px;
                                    padding: 0 30px;
                                    line-height: 30px;
                                    background-color: #eef1f8;
                                }
                                td {
                                    padding: 0 30px;
                                    height: 30px;
                                    line-height: 30px;
                                }
                            }
                        }
                    }
                    .file-table {
                        ::v-deep td {
                            padding: 10px 16px;
                            font-size: $fs14;
                        }
                        ::v-deep .ant-upload-list {
                            display: none;
                        }
                        .mbtn {
                            padding: 12px 24px;
                            cursor: pointer;
                            &:first-of-type {
                                padding-left: 0;
                            }
                        }
                        .btn_upload {
                            color: $blue;
                        }
                        .btn_remove {
                            color: red;
                        }
                    }
                }
            }
            /deep/ .ant-form-item {
                margin-bottom: 0;
                height: 50px;
                line-height: 50px;
            }
            /deep/ .ant-form-item-control {
                line-height: inherit;
            }
            /deep/ .ant-form-explain {
                position: absolute;
                bottom: 4px;
                font-size: 12px;
            }
            /deep/ .ant-form-item-children {
                vertical-align: super;
            }
            /deep/ .apply-info-input {
                padding-left: 0;
            }
            /deep/ .ant-select-selection {
                border: 1px solid transparent;
                box-shadow: none;
                background-color: transparent;
                outline: none;
            }
            .footer {
                display: flex;
                justify-content: center;
                margin-top: 80px;
                button {
                    margin: 0 14px;
                    height: 40px;
                    line-height: 40px;
                    border-radius: 4px;
                }
                .btn-cancel {
                    background-color: #f4f4f4;
                    border: solid 1px #dddddd;
                    color: #999999;
                }
            }
        }
    }
    .commonblock {
        width: 100%;
        margin-bottom: 32px;
        /*min-height: 500px;*/
        /*background: rgba(142, 146, 161, 0.1);*/
    }
    .commonTitle {
        width: 100%;
        height: 20px;
        line-height: 20px;
        padding-left: 12px;
        color: #000025;
        position: relative;
        display: block;
        &::after {
            content: '';
            width: 4px;
            height: 20px;
            background-color: #0284ff;
            position: absolute;
            left: 0;
            top: 0;
        }
    }
    .process {
        width: 500px;
        min-height: 101px;
        position: relative;
        .processLi {
            width: 100%;
            height: 45px;
            line-height: 45px;
            padding-left: 50px;
            position: relative;
            .seeMore {
                display: block;
                width: 34px;
                height: 34px;
                position: absolute;
                background: #fdfdfd;
                left: -15px;
                top: 6px;
                text-align: center;
                line-height: 34px;
                z-index: 30;
                color: #0284ff;
                .see--icons {
                    width: 24px;
                    height: 24px;
                    line-height: 24px;
                    border-radius: 24px;
                    font-size: 15px;
                    overflow: hidden;
                    border: 1px solid #0284ff;
                    position: absolute;
                    left: 4px;
                    top: 5px;
                    cursor: pointer;
                }
            }
            .pname {
                width: 80px;
                display: block;
                float: left;
            }
            .paction {
                width: 36px;
                display: block;
                float: left;
            }
            .pactionDesc {
                width: 234px;
                display: block;
                float: left;
            }
            .pactionTime {
                display: block;
                float: left;
                text-align: right;
            }
        }
        $left: 0px;
        .processLi:after {
            content: '';
            width: 2px;
            height: 100%;
            position: absolute;
            left: $left;
            top: 0;
            border-left: 2px solid #d5d6e3;
            z-index: 1;
        }
        .processLi::before {
            content: '';
            width: 9px;
            height: 9px;
            position: absolute;
            left: -4px;
            top: 40%;
            border-radius: 9px;
            background: #d5d6e3;
            z-index: 10;
        }
        .processLi:first-child::after {
            content: '';
            width: 2px;
            height: 50%;
            position: absolute;
            left: $left;
            top: 50%;
            border-left: 2px solid #d5d6e3;
        }
        .processLi:last-child::after {
            content: '';
            width: 2px;
            height: 50%;
            position: absolute;
            left: $left;
            top: 0%;
            border-left: 2px solid #d5d6e3;
        }
    }
    .goback {
        display: block;
        margin: 0 auto;
    }
}
</style>
