import { baseRequest } from '@/API'

//fuwu列表
export function DataList(JSON) {
    return baseRequest({
        // url: '/interface/resourceservice/myusedservice',
        // url: '/interface/resourceservice/mydatausedservice',
        url: '/interface/resourceservice/mydatausedservice',
        method: 'POST',
        data: JSON
    })
}
export function getInterfaceTestInfo(params) {
    return baseRequest({
        url: '/interface/resourceservice/simulation/param',
        method: 'post',
        data: params
    })
}
