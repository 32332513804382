<template>
    <div class="index" v-if="loaded">
        <div class="blks">
            <div class="headTitle">
                <span :class="{ blue: bk1 === 1 }" class="pointer" @click="getDataDomainsData">数据分类</span>
                <span class="centerDot">•</span>
                <span :class="{ blue: bk1 === 0 }" class="pointer" @click="getDepartmentData">部门分类</span>
            </div>
            <span class="subtitle">按领域获取开放数据、您可以选择以下领域</span>
            <span class="more" @click="goOpenData('')">MORE</span>
            <span class="baseLine"></span>
            <a-spin :spinning="tipsListspinn" tip="数据加载中...">
                <div class="tipsList" v-if="showScreeing == 'org'">
                    <div class="item" v-for="(item, index) in catalogDeptsList" :key="index" @click="goOpenData(item)">
                        <span>{{ item.deptName || getDeepLabel(item.deptId) || item.deptId }}</span>
                        <i class="u-mask"></i>
                    </div>
                </div>
                <div class="tipsList" v-if="showScreeing == 'datadomain'">
                    <div class="item" v-for="(item, index) in catalogDomainList" :key="index" @click="goOpenData(item)">
                        <span>{{ item.dataDomainName || getDeepLabel(item.dataDomain, dataDomainOptions) || item.dataDomain }}</span>
                        <i class="u-mask"></i>
                    </div>
                </div>
            </a-spin>
        </div>
        <div class="blks">
            <div class="headTitle">
                <span :class="{ blue: bk2 === 0 }" class="pointer" @click="getNewOrHot(0)">最新数据</span>
                <span class="centerDot">•</span>
                <span :class="{ blue: bk2 === 1 }" class="pointer" @click="getNewOrHot(1)">热门数据</span>
            </div>
            <span class="subtitle">在这里，您可以获取更多有价值的数据</span>
            <span class="more" @click="goBk2">MORE</span>
            <span class="baseLine"></span>
            <a-spin :spinning="cardListspinn" tip="数据加载中...">
                <ul class="cardList">
                    <template v-for="(item, index) in newdata">
                        <li class="item" v-if="index < newdata.length" :key="index" @click="Bk2Detail(item)">
                            <span class="title dots1">{{ item.resName }}</span>
                            <span class="starBox">
                                <a-icon type="star" v-for="i in 5" :key="i" theme="filled" />
                            </span>
                            <ul class="itemUl">
                                <li>
                                    <span class="left">数据提供方</span>
                                    <span class="right">{{ item.idPocName }}</span>
                                </li>
                                <li>
                                    <span class="left">数据领域</span>
                                    <span class="right">{{ getDomainName(item.datadomain, dataDomainOptions) }}</span>
                                    <!-- <span class="right">{{ datadomainArr[index] }}</span> -->
                                </li>
                                <li>
                                    <span class="left">更新时间</span>
                                    <span class="right">{{ resolveTimeStream(item.updateDate) }}</span>
                                </li>
                            </ul>
                        </li>
                    </template>
                </ul>
            </a-spin>
        </div>
        <div class="blks">
            <div class="headTitle">
                <span :class="{ blue: bk3 === 0 }" class="pointer" @click="getRuleData">法规政策</span>
                <span class="centerDot">•</span>
                <span :class="{ blue: bk3 === 1 }" class="pointer" @click="getNewsData">新闻动态</span>
            </div>
            <span class="subtitle">在这里，您可以获取更多有价值的数据</span>
            <span class="more" @click="goNews">MORE</span>
            <span class="baseLine"></span>
            <div class="bk1">
                <div class="imgwrapper">
                    <img src="../../assets/index/img_news.png" alt="" />
                </div>
                <div class="headNews">
                    <a-spin :spinning="newsListspinn" tip="数据加载中...">
                        <template v-if="news.length">
                            <span class="title dots1">{{ news[0].title }}</span>
                            <span class="fromandtime">
                                <span class="area">{{ news[0].source }}</span>
                                <span class="time" v-if="news[0].publishtime">{{ resolveTimeStream(news[0].publishtime) }}</span>
                            </span>
                            <div class="desc dots4">
                                {{ news[0].abstractContent }}
                            </div>
                            <span class="seeDetail" @click="seeNewsDetail(news[0])">
                                详情&lt;&lt;&lt;
                            </span>
                        </template>
                        <no-data v-else></no-data>
                    </a-spin>
                </div>
            </div>
            <div class="moreList">
                <div class="lft">
                    <template v-for="(item, index) in news">
                        <div class="lidiv" :key="index" v-if="index > 0 && index < 4" @click="seeNewsDetail(news[index])">
                            <span class="NO">{{ index }}、</span>
                            <span>{{ item.title }}</span>
                            <span class="time">{{ resolveTimeStream(item.publishtime) }}</span>
                        </div>
                    </template>
                </div>
                <div class="rt">
                    <template v-for="(item, index) in news">
                        <div class="lidiv" :key="index" v-if="index > 3 && index < 7" @click="seeNewsDetail(news[index])">
                            <span class="NO">{{ index }}、</span>
                            <span>{{ item.title }}</span>
                            <span class="time">{{ resolveTimeStream(item.publishtime) }}</span>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { resolveTimeStream, getDeepLabel } from '@/utils/tools'
import { getDataDomains, getDepartment, getNewData, getNews, getRules, dataDomainselectByIdName } from './api/index'
import NoData from '@/components/NoData'
// import { getDeepLabel } from '@/utils/tools'
import { mapState } from 'vuex'
export default {
    name: 'index',
    data() {
        return {
            timer1: null,
            timer2: null,
            time: 300,
            loaded: false,
            bk1: 1,
            bk2: 0,
            bk3: 0,
            datadomainArr: [], //最新，最热：数据领域
            news: [{ title: '', source: '' }], //新闻
            rules: [], //法规
            dataSource: null,
            newdata: null,
            tipsListspinn: true,
            cardListspinn: true,
            newsListspinn: true
        }
    },
    components: {
        NoData
    },
    computed: {
        ...mapState(['dataDomainOptions', 'catalogDeptsList', 'catalogDomainList'])
    },
    methods: {
        getDomainName(el, list) {
            let elList = el.split(',')
            let arr = elList.map(item => {
                return getDeepLabel(item, list)
            })
            return arr.join(',')
        },
        resolveTimeStream,
        getDeepLabel,
        goOpenData(item) {
            this.$router.push({ name: 'DataCatalog', query: { type: this.bk1, typeId: this.bk1 ? item.dataDomain : item.deptId,areaCode:item.areaCode||null } })
        },
        goBk2() {
            this.$router.push({ name: 'DataSet' })
        },
        // 跳转详情事件
        Bk2Detail(item) {
            this.$router.push({
                name: 'DataSetDetail',
                query: {
                    id: item.id
                }
            })
        },
        goNews() {
            this.$router.push({ path: '/PolicyTrends/Index', query: { navIndex: this.bk3 } })
        },
        seeNewsDetail(news) {
            //查看新闻详情
            let breadTitl = this.bk3 == 1 ? '新闻动态' : '法规政策'
            this.$router.push({ name: 'PolicyTrendsDetail', params: { id: news.id, breadTitl: breadTitl, title: news.title, publishtime: news.publishtime, source: news.source } })
        },
        init() {
            this.getDataDomainsData()
            this.getNewOrHot(0)
            this.getRuleData()
            // 当store中没有数据时获取组织机构，数据领域数据
            if (this.dataDomainOptions.length == 0) {
                this.$store.dispatch('getData')
            }
        },
        search(id) {
            let data = dataDomainselectByIdName({ id: id }).then(res => {
                return res
            })
            console.log(data)
            return data
        },
        getDataDomainsData() {
            //数据分类
            this.tipsListspinn = true
            this.bk1 = 1
            this.showScreeing = 'datadomain'
            this.$store.dispatch('getDataCatalogTypeList')
            this.dataSourceHandle()
        },
        getDepartmentData() {
            //部门分类
            this.tipsListspinn = true
            this.bk1 = 0
            this.showScreeing = 'org'
            this.$store.dispatch('getDataCatalogTypeList')
            this.dataSourceHandle()
        },
        dataSourceHandle() {
            this.timer1 = setTimeout(() => {
                this.tipsListspinn = false
            }, this.time)
        },
        getNewOrHot(index) {
            this.bk2 = index
            this.cardListspinn = true
            let param = index == 1 ? { visitCount: 1 } : {}
            let params = {
                pageNo: 1,
                pageSize: 6,
                param: param
            }
            getNewData(params)
                .then(res => {
                    this.timer2 = setTimeout(() => {
                        this.newdata = res.records
                        // console.log(this.newdata)
                        // const len = this.newdata.length > 6 ? 6 : this.newdata.length
                        // debugger
                        // for (let i = 0; i < len; i++) {
                        //     // this.datadomainArr.push(this.search(this.newdata[i].datadomain))
                        //     let a = this.search(this.newdata[i].datadomain)
                        //     console.log(a)
                        // }
                        // console.log(this.datadomainArr)
                        this.cardListspinn = false
                    }, this.time)
                })
                .catch(() => {
                    this.cardListspinn = false
                    this.$message.error('接口异常')
                })
        },
        getRuleData() {
            //政策法规
            this.bk3 = 0
            this.newsListspinn = true
            getRules({
                pageNo: 1,
                pageSize: 10,
                param: {
                    newsType: 1
                }
            })
                .then(res => {
                    this.news = res.records
                    this.newsListspinn = false
                })
                .catch(() => {
                    this.$message.error('接口异常')
                    this.newsListspinn = false
                })
        },
        getNewsData() {
            //新闻动态
            this.bk3 = 1
            this.newsListspinn = true
            getNews({
                pageNo: 1,
                pageSize: 10,
                param: {
                    newsType: 1
                }
            })
                .then(res => {
                    this.news = res.records
                    this.newsListspinn = false
                })
                .catch(() => {
                    this.newsListspinn = false
                    this.$message.error('接口异常')
                })
        }
    },
    mounted() {
        this.loaded = true
        this.$store.dispatch('getDataCatalogTypeList')
        this.$store.dispatch('getDataDomain')
        this.init()
    },

    beforeDestroy() {
        this.timer1 && clearTimeout(this.timer1)
        this.timer2 && clearTimeout(this.timer2)
    }
}
</script>

<style scoped lang="scss">
.index {
    position: relative;
    animation: load 0.2s forwards ease;
    overflow: hidden;
    .dots {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        text-align: justify;
    }
    .dots1 {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        text-align: justify;
    }
    .dots3 {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        text-align: justify;
    }
    .dots4 {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        text-align: justify;
    }
    .blks {
        width: 1200px;
        min-height: 284px;
        display: block;
        margin: 0 auto;
        margin-top: 52px;
        position: relative;
        .headTitle {
            font-size: 24px;
            text-align: center;
            color: #333333;
            .centerDot {
                width: 60px;
                text-align: center;
                display: inline-block;
                line-height: 20px;
            }
        }
        .subtitle {
            display: block;
            width: 100%;
            text-align: center;
            font-size: 14px;
            line-height: 14px;
            letter-spacing: 0px;
            color: #999999;
            margin-top: 8px;
        }
        .more {
            display: block;
            width: 100%;
            height: 14px;
            font-size: 14px;
            line-height: 14px;
            color: #357df1;
            text-align: center;
            margin-top: 9px;
            cursor: pointer;
        }
        .baseLine {
            display: block;
            width: 1200px;
            height: 4px;
            background: #7d97c1;
            margin-top: 18px;
        }
        .tipsList {
            display: block;
            margin: 0 auto;
            margin-top: 20px;
            width: 1200px;
            min-height: 160px;
            display: grid;
            grid-template-columns: repeat(7, 150px);
            justify-content: space-between;
            grid-auto-rows: 40px;
            grid-row-gap: 20px;
            .item {
                display: block;
                width: 150px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                background: #e9ebec;
                font-size: 14px;
                color: #333333;
                margin-right: 25px;
                cursor: pointer;
                position: relative;
                /*margin-bottom: 20px;*/
                overflow: hidden;
                &:nth-child(7n) {
                    margin-right: 0;
                }
                span {
                    position: relative;
                    z-index: 100;
                }
                .u-mask {
                    position: absolute;
                    left: -101%;
                    top: -101%;
                    z-index: 1;
                    display: block;
                    width: 100%;
                    height: 100%;
                    transition: left 233ms ease-out, top 233ms ease-out, background-color 332ms ease-out, border-radius 332ms ease-out;
                    border-radius: 0 0 50% 0;
                    background-color: rgba(53, 125, 241, 0.24);
                }
            }
            .item:hover {
                span {
                    color: white;
                }
                .u-mask {
                    left: 0;
                    top: 0;
                    border-radius: 0;
                    background-color: #357df1;
                }
            }
        }
        .cardList {
            margin-top: 20px;
            width: 1200px;
            min-height: 430px;
            /* display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            align-items: flex-start;*/

            display: grid;
            grid-template-columns: repeat(3, 380px);
            justify-content: space-between;
            /*grid-template-rows: 40px ;*/
            grid-auto-rows: 200px;
            grid-row-gap: 20px;
            .item {
                width: 380px;
                height: 200px;
                background: url('~@/assets/index/img_sjbac_1.png') no-repeat;
                background-size: 100% 100%;
                transition: all 233ms ease-out;
                box-sizing: border-box;
                padding: 20px;
                cursor: pointer;
                &:hover {
                    /*border: 1px solid #357df1;*/
                    background: url('~@/assets/index/img_sjbac.png') no-repeat;
                    background-size: 100% 100%;
                    .title {
                        color: white;
                    }
                }
                .title {
                    width: 100%;
                    height: 16px;
                    font-size: 16px;
                    font-weight: normal;
                    font-stretch: normal;
                    line-height: 16px;
                    letter-spacing: 0px;
                    overflow: hidden;
                    color: #333333;
                }
                .starBox {
                    width: 100%;
                    text-align: left;
                    margin-top: 10px;
                    color: #3a80ef;
                }
                .itemUl {
                    width: 100%;
                    li {
                        width: 100%;
                        height: 44px;
                        border-bottom: 1px dashed #d5d5d5;
                        &:last-child {
                            border: none;
                        }
                        line-height: 44px;
                        .left {
                            height: 12px;
                            font-size: 12px;
                            letter-spacing: 0px;
                            color: #999999;
                            float: left;
                        }
                        .right {
                            height: 12px;
                            font-size: 12px;
                            color: #333333;
                            float: right;
                        }
                    }
                }
            }
        }
        .bk1 {
            width: 1200px;
            height: 200px;
            margin-top: 20px;
            .imgwrapper {
                width: 360px;
                height: 200px;
                display: block;
                float: left;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .headNews {
                width: 798px;
                float: right;
                height: 200px;
                .title {
                    width: 100%;
                    height: 16px;
                    font-size: 16px;
                    line-height: 16px;
                    letter-spacing: 0px;
                    color: #333333;
                }
                .fromandtime {
                    width: 100%;
                    margin-top: 20px;
                    height: 14px;
                    line-height: 14px;
                    display: block;
                    color: #333333;
                    .area {
                        width: 162px;
                        height: 14px;
                        font-size: 14px;
                        letter-spacing: 0px;
                        display: inline-block;
                    }
                }
                .desc {
                    display: block;
                    margin-top: 14px;
                    width: 100%;
                    font-size: 13px;
                    line-height: 20px;
                    color: #999999;
                    max-height: 76px;
                    overflow: hidden;
                }
                .seeDetail {
                    display: block;
                    margin-top: 14px;
                    color: #3a80ef;
                }
            }
        }
        .moreList {
            width: 1200px;
            height: 96px;
            display: block;
            margin-top: 22px;
            margin-bottom: 50px;
            .lft {
                width: 560px;
                float: left;
                .time {
                    float: right;
                    margin-right: 32px;
                }
                .lidiv:nth-child(1) .NO {
                    color: #357df1;
                }
                .lidiv:nth-child(2) .NO {
                    color: #ffea1c;
                }
                position: relative;
                &::after {
                    content: '';
                    position: absolute;
                    right: -32px;
                    top: 0px;
                    width: 1px;
                    height: 118px;
                    border-right: 1px solid #dbdbdb;
                }
            }
            .rt {
                width: 560px;
                float: right;
                .time {
                    float: right;
                    margin-right: 0px;
                }
            }
            .lidiv {
                margin-bottom: 28px;
                cursor: pointer;
                &:hover {
                    color: #3a80ef;
                }
            }
            .newsli {
                width: 100%;
                height: 14px;
                font-family: SourceHanSansCN-Regular;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                line-height: 14px;
                letter-spacing: 0px;
                color: #333333;
            }
        }
    }
    .blue {
        color: #357df1;
    }
    .pointer {
        cursor: pointer;
    }
}
@keyframes load {
    from {
        opacity: 0.8;
        filter: blur(5px);
    }
    to {
        opacity: 1;
        filter: blur(0px);
    }
}
</style>
