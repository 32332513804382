<template>
    <div class="MyAppList">
        <div class="head">
            <span>我的应用列表</span>
            <p class="phone-num" v-if="servicePhoneNum">业务服务电话：{{ servicePhoneNum }}</p>
        </div>
        <div class="searchBar">
            <div class="bt">
                <a-input v-model.trim="navSearch.title" addon-before="应用名称" placeholder="输入标题搜索" />
            </div>
            <div class="lx">
                <span class="ant-input-group-addon span" style="width: 80px;height: 32px;line-height: 30px;">应用状态</span>
                <a-select allowClear v-model="navSearch.applicationtype" placeholder="请选择申请类型" style="width: 120px">
                    <a-select-option :key="index" :value="item.value" v-for="(item, index) in application_state_options">
                        {{ item.label }}
                    </a-select-option>
                </a-select>
            </div>
            <div class="sj">
                <a-range-picker :value="defaultData" :format="dateFormat" @change="onChange" :allowClear="false" />
            </div>
            <div class="cx">
                <a-button type="primary" @click="searchList" style="width: 100%;">
                    查询
                </a-button>
            </div>
            <div class="reset" @click="reset">
                <a-icon type="redo" />
            </div>
        </div>

        <a-spin :spinning="spinning" tip="数据加载中...">
            <div class="applist">
                <div class="blk" @click="addApp">
                    <i class="sanweifenxi"></i>
                    <span class="addOne">新增应用</span>
                </div>

                <div class="blk" v-for="item in applist" :key="item.id" @click="goAppDetail(item.id)">
                    <span class="appName">{{ item.applicationName }}</span>
                    <div class="appImg">
                        <img v-if="item.iconUrl" :src="item.iconUrl" class="img" alt="" />
                    </div>
                    <span v-if="item.applicationState === '4'" class="appStatus reject ">已驳回</span>
                    <span class="appStatus" v-else>{{ GetLable(item.applicationState, application_state_options) }}</span>
                    <ul class="infoList">
                        <li class="infoLi">
                            <span class="key">业务模块：</span>
                            <span class="value">{{ GetLable(item.businessModule, business_type_options) }}</span>
                        </li>

                        <li class="infoLi">
                            <span class="key">负责人：</span>
                            <span class="value">{{ item.chargePersionName }}</span>
                        </li>
                        <li class="infoLi">
                            <span class="key">负责人电话：</span>
                            <span class="value">{{ item.chargePersionPhone }}</span>
                        </li>
                        <li class="infoLi">
                            <span class="key">注册时间：</span>
                            <span class="value">{{ resolveTimeStream(item.createDate) }}</span>
                        </li>
                    </ul>
                    <div class="btns" v-if="item.applicationState != '2'">
                        <!--                        <div class="btns" >-->
                        <div class="btnlist" @click.stop="editAgain(item.id)" style="flex:1">
                            <a-icon type="form" />
                        </div>
                        <div class="btnlist" @click.stop="deleteApp(item.id)" v-if="item.applicationState == '4'">
                            <a-icon type="delete" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="pagination" v-if="showApp">
                <a-pagination v-model="current1" :total="total1" @change="change" :page-size-options="pageSizeOptions" :page-size.sync="pageSize" show-size-changer show-quick-jumper @showSizeChange="onShowSizeChange" />
            </div>
        </a-spin>
    </div>
</template>

<script>
import moment from 'moment'
import { GetDictionary } from '@/API/http'
import { defaultData, GetLable, resolveTimeStream } from '@/utils/tools'
import { MyAppDelete, MyAppList } from './api/index'

export default {
    name: 'MyAppList',
    inject: ['reload'],
    data() {
        return {
            servicePhoneNum: window.globalData.servicePhoneNum,
            showData: true,
            showApp: true,
            spinning: true, //页面loading 状态
            navSearch: {
                title: '',
                applicationtype: '',
                timeRange: ''
            },
            pageSize: 5,
            pageSizeOptions: ['5', '10', '20', '50'],
            current1: 1,
            total1: 1,
            dateFormat: 'YYYY-MM-DD',
            defaultData: defaultData(),
            monthFormat: 'YYYY/MM',
            selectPlaceholder: undefined,
            application_state_options: [],
            business_type_options: [],
            applist: null,
            appTemp: null //删除缓存
        }
    },
    methods: {
        moment,
        resolveTimeStream,
        GetLable,
        //重置
        reset() {
            console.log('重置')
            this.current1 = 1
            this.navSearch.title = ''
            this.defaultData = []
            this.defaultData = defaultData()
            this.navSearch.applicationtype = ''
            this.selectPlaceholder = undefined
            this.listInit()
        },
        disabledDate: current => {
            return current < moment().startOf('day') || current > moment().add(90, 'day')
        },
        onShowSizeChange() {
            this.current1 = 1
            this.listInit()
        },
        onChange(dates, dateStrings) {
            this.defaultData = [dateStrings[0], dateStrings[1]]
        },
        change(page, pageSize) {
            console.log(page, pageSize)
            this.current1 = page
            this.listInit()
        },
        handleMenuClick(e) {
            console.log(e)
        },
        searchList() {
            this.current1 = 1
            this.listInit()
        },
        deleteApp(id) {
            this.$confirm({
                title: '提示',
                centered: true,
                content: '确认删除该应用吗？',
                okText: '确认',
                cancelText: '取消',
                onOk: function() {
                    MyAppDelete({ id: id })
                        .then(() => {
                            this.$message.success('应用删除成功')
                            this.listInit()
                        })
                        .catch(() => {
                            this.$message.error('操作失败')
                        })
                }
            })
        },
        goAppDetail(id) {
            console.log('goAppDetail')
            console.log(id)
            this.$router.push({
                name: 'MyAppDetail',
                query: { id }
            })
        },
        addApp() {
            this.$router.push({
                name: 'MyAppEdit',
                query: { type: 'add' }
            })
        },
        editAgain(id) {
            this.$router.push({
                name: 'MyAppEdit',
                query: { id }
            })
        },
        deleteAction() {
            console.log('确认删除')
        },

        handleChange(item) {
            this.navSearch.applicationtype = item.key
        },
        async dictionaryInit() {
            await GetDictionary('application_state')
                .then(res => {
                    this.application_state_options = res
                })
                .catch(e => {
                    console.log(e)
                })
            await GetDictionary('business_type')
                .then(res => {
                    this.business_type_options = res
                })
                .catch(e => {
                    console.log(e)
                })
            this.listInit()
        },
        listInit() {
            this.spinning = true
            this.showData = true
            this.applist = []
            MyAppList({
                pageNo: this.current1 || 1,
                pageSize: this.pageSize,
                param: {
                    applicationName: this.navSearch.title,
                    applicationState: this.navSearch.applicationtype,
                    startCreateTime: moment(this.defaultData[0]).format('YYYY-MM-DD') + ' 00:00:00',
                    endCreateTime: moment(this.defaultData[1]).format('YYYY-MM-DD') + ' 23:59:59'
                }
            }).then(res => {
                this.applist = res.records
                this.current1 = res.current
                this.total1 = res.total
                setTimeout(() => {
                    if (res.records.length > 0) {
                        this.showApp = true
                    } else {
                        this.showApp = false
                    }
                    this.spinning = false
                }, 300)
            })
        }
    },
    mounted() {
        this.dictionaryInit()
    },
    activated() {
        //已经缓存的页面 进行刷新操作
        if (this.$route.query.reload) {
            let path = this.$route.path
            this.$router.push({
                path: path,
                query: {}
            })
            this.reload()
        }
    }
}
</script>

<style scoped lang="scss">
@keyframes heartbeat {
    0% {
        transform: scale(0.9);
        filter: blur(1px);
        opacity: 0.8;
    }
    100% {
        transform: scale(1, 1);
        filter: blur(0px);
        opacity: 1;
    }
}
.MyAppList {
    .head {
        position: relative;
        width: 100%;
        height: 62px;
        box-sizing: border-box;
        border-bottom: 1px solid #ebeced;
        padding-left: 34px;
        padding-top: 32px;
        font-size: $fs16;
        color: #000025;
        .phone-num {
            position: absolute;
            right: 20px;
            top: 60%;
            font-size: 16px;
            transform: translateY(-50%);
            font-weight: 600;
        }
    }
    .searchBar {
        width: 882px;
        height: 30px;
        display: block;
        margin: 0 auto;
        margin-top: 24px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        .bt {
            width: 200px;
            height: 30px;
        }
        .lx {
            width: 200px;
            height: 32px;
            margin-left: 16px;
            .span {
                width: 80px;
                height: 30px;
                line-height: 30px;
                display: block;
                float: left;
            }
            /deep/ .ant-btn {
                width: 120px;
                display: inline;
                float: left;
                height: 32px;
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
                position: relative;
                cursor: pointer;
                margin-left: 0 !important;
                background: white;
            }
            /deep/ .ant-select-selection--single {
                height: 32px;
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
            }
            /deep/ .ant-select-selection__rendered {
                line-height: 32px;
            }
        }
        .sj {
            margin-left: 16px;
            width: 300px;
            height: 30px;
        }
        .cx {
            margin-left: 16px;
            width: 84px;
            height: 30px;
            position: relative;
        }
        .reset {
            margin-left: 16px;
            height: 30px;
            width: 30px;
            background: white;
            border: 1px solid #cccccc;
            border-radius: 4px;
            overflow: hidden;
            cursor: pointer;
            font-size: 18px;
            text-align: center;
            line-height: 30px;
            font-weight: bold;
            transform-origin: center center;
            transform: rotate(-90deg);
        }
    }
    .applist {
        width: 882px;
        display: block;
        margin: 0 auto;
        margin-top: 32px;
        min-height: 600px;
        user-select: none;
        /* display: flex;
flex-flow: row wrap;
justify-content: space-between;
align-items: flex-start;*/
        margin-bottom: 48px;
        display: grid;
        grid-template-columns: repeat(3, 260px);
        justify-content: space-between;
        align-items: flex-start;
        .blk {
            width: 260px;
            height: 260px;
            box-sizing: border-box;
            border: solid 1px #dbdbdb;
            margin-bottom: 32px;
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: center;
            position: relative;
            .sanweifenxi {
                display: block;
                width: 120px;
                height: 136px;
                background: url('~@/assets/index/icon-sanweifenxi.png') no-repeat;
                background-size: cover;
                animation: heartbeat 1.5s infinite alternate ease;
            }
            .addOne {
                display: block;
                width: 120px;
                height: 40px;
                background-color: rgba(53, 125, 241, 0.1);
                border-radius: 4px;
                text-align: center;
                line-height: 40px;
                font-family: Source Han Sans CN;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                letter-spacing: 0px;
                color: #0184ff;
                margin-top: 14px;
                cursor: pointer;
            }
            .appName {
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                letter-spacing: 0px;
                color: #303343;
                width: 150px;
                position: absolute;
                left: 20px;
                top: 20px;
            }
            .appStatus {
                /* width: 52px; */
                height: 24px;
                position: absolute;
                left: 20px;
                line-height: 24px;
                color: #0184ff;
                top: 44px;
                background-color: rgba(1, 132, 255, 0.1);
                border-radius: 4px;
                text-align: center;
                padding: 0px 8px;
                &.reject {
                    background-color: rgba(255, 1, 1, 0.1);
                    color: #ff0101;
                }
            }
            .appImg {
                width: 48px;
                height: 48px;
                background-color: #dce6f8;
                background-image: url('~@/assets/index/appImg.png');
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 39px auto;
                position: absolute;
                top: 20px;
                right: 20px;
                .img {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                }
            }
            .infoList {
                width: 220px;
                height: 132px;
                display: block;
                position: absolute;
                left: 20px;
                top: 80px;
                .infoLi {
                    width: 100%;
                    height: 20px;
                    overflow: hidden;
                    margin-bottom: 8px;
                    overflow: hidden;
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    text-align: justify;
                    .key {
                        color: #999999;
                    }
                    .value {
                        color: #333333;
                    }
                }
            }
            .btns {
                width: 100%;
                height: 32px;
                position: absolute;
                left: 0;
                bottom: 0;
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-start;
                .btnlist {
                    width: 50%;
                    height: 32px;
                    text-align: center;
                    font-size: $fs14;
                    line-height: 32px;
                    background: #f4f5f6;
                    color: #999999;
                    cursor: pointer;
                    &:hover {
                        background: #0184ff;
                        color: white;
                    }
                }
            }
        }
        .blk:first-child {
            cursor: pointer;
        }
        .blk:hover {
            border: solid 1px #0184ff;
            cursor: pointer;
        }
    }
    .pagination {
        margin-top: 40px;
        margin-bottom: 48px;
        ul {
            display: flex;
            justify-content: center;
        }
    }
}
</style>
