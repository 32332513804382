<template>
    <div class="MyAppDetail">
        <div class="head">我的应用详情信息</div>
        <div class="body">
            <table class="table" border="1" v-if="applicationInfoEntity">
                <tbody>
                    <tr>
                        <td>
                            <div>
                                <span class="key">应用名称</span>
                                <span class="value">{{ applicationInfoEntity.applicationName }}</span>
                            </div>
                        </td>
                        <td>
                            <div>
                                <span class="key">负责人电话</span>
                                <span class="value">{{ applicationInfoEntity.chargePersionPhone }}</span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div>
                                <span class="key">应用编号</span>
                                <span class="value">{{ applicationInfoEntity.applicationId }}</span>
                            </div>
                        </td>
                        <td>
                            <div>
                                <span class="key">负责人邮箱</span>
                                <span class="value">{{ applicationInfoEntity.chargePersionEmail }}</span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div>
                                <span class="key">appKey</span>
                                <span class="value">{{ applicationInfoEntity.appkey }}</span>
                            </div>
                        </td>
                        <td>
                            <div>
                                <span class="key">IP地址</span>
                                <span class="value">{{ getIPList(applicationInfoEntity.ipAddress) }}</span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div>
                                <span class="key">应用状态</span>
                                <span class="value">{{ GetLable(applicationInfoEntity.applicationState, application_state_options) }}</span>
                            </div>
                        </td>
                        <td>
                            <div>
                                <span class="key">数据领域</span>
                                <span class="value">{{ applicationInfoEntity.fieldName }}</span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div>
                                <span class="key">负责人</span>
                                <span class="value">{{ applicationInfoEntity.chargePersionName }}</span>
                            </div>
                        </td>
                        <td>
                            <div>
                                <span class="key">提交时间</span>
                                <span class="value">{{ resolveTimeStream(applicationInfoEntity.createDate) }}</span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div>
                                <span class="key">开发者</span>
                                <span class="value">{{ applicationInfoEntity.developer }}</span>
                            </div>
                        </td>
                        <td>
                            <div>
                                <span class="key">更新时间</span>
                                <span class="value">{{ resolveTimeStream(applicationInfoEntity.updateDate) }}</span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="4">
                            <div>
                                <span class="key">应用描述</span>
                                <span class="value dotsFlow" :title="applicationInfoEntity.applicationDesc">{{ applicationInfoEntity.applicationDesc }}</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="appImg">
                <img v-if="applicationInfoEntity.iconUrl" :src="applicationInfoEntity.iconUrl" class="imgDetail" alt="" />
            </div>
            <div class="relateApp">
                <span class="commonTitle">关联接口服务</span>
                <a-table class="list" :columns="columns" :data-source="data" :pagination="false">
                    <template slot="actions" slot-scope="text, record, index">
                        <!--                    <template v-slot:actions="record">-->
                        <span class="seeDeatilBtn" @click="seeDetail(text, record, index)">查看</span>
                    </template>
                </a-table>
            </div>

            <div class="relateApp">
                <span class="commonTitle">应用上架信息</span>
                <div class="app-type-info-box">
                    <section class="app-type-info-tab">
                        <p>
                            应用上架类型
                        </p>
                        <a-radio-group :default-value="appType" v-model="appType">
                            <a-radio-button value="1" v-if="appType == 1">
                                Web应用
                            </a-radio-button>
                            <a-radio-button value="2" v-else>
                                App应用
                            </a-radio-button>
                        </a-radio-group>
                    </section>
                    <section class="app-type-info-tab" v-if="appType == '1'">
                        <p>
                            Web应用访问地址
                        </p>
                        <a-input v-model.trim="webUrl" placeholder="请输入Web应用访问地址" disabled></a-input>
                    </section>
                    <section class="app-type-info-tab" v-if="appType != '1'">
                        <div style="margin-right:24px" v-if="qrCodeFile.android !== ''">
                            <p>安卓版二维码</p>

                            <div class="img-box">
                                <img :src="qrCodeFile.android" alt="" />
                            </div>
                        </div>
                        <div v-if="qrCodeFile.ios !== ''">
                            <p>iOS版二维码</p>

                            <div class="img-box">
                                <img :src="qrCodeFile.ios" alt="" />
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <a-button class="goback" @click="goback">返回</a-button>
        </div>
    </div>
</template>

<script>
import { GetDictionary } from '@/API/http'
import { MyAppDetail } from './api/index'
import { GetLable, resolveTimeStream } from '@/utils/tools'

const columns = [
    {
        title: '接口名称',
        dataIndex: 'name'
    },
    {
        title: '申请人',
        dataIndex: 'applyPerson'
    },
    {
        title: '申请时间',
        dataIndex: 'applyTime'
    },
    {
        title: '申请状态',
        dataIndex: 'applyState'
    },
    {
        title: '审批人',
        dataIndex: 'approvalPerson'
    },
    {
        title: '审批时间',
        dataIndex: 'approvalTime'
    },
    {
        title: '操作',
        dataIndex: 'actions',
        scopedSlots: { customRender: 'actions' }
    }
]
const data = []

export default {
    name: 'MyAppDetail',
    data() {
        return {
            data,
            columns,
            webUrl: '',
            qrCodeFile: {
                android: '',
                ios: ''
            },
            appType: '1',
            applicationInfoEntity: {
                iconUrl: null
            },
            application_state_options: null,
            service_state_options: null
        }
    },
    methods: {
        resolveTimeStream,
        GetLable,
        seeDetail(text, record, index) {
            console.log(text, record, index)
            this.$router.push({
                name: 'MyServiceDetail',
                query: {
                    id: record.key,
                    type: 1,
                    showAll: true
                }
            })
        },
        goback() {
            console.log('-1')
            this.$router.go(-1)
        },
        detailInit() {
            let id = this.$route.query.id
            MyAppDetail({ id }).then(res => {
                console.log(res)
                this.webUrl = res.appResultInfo.webUrl || ''
                this.qrCodeFile.android = res.appResultInfo.androidQrcode || ''
                this.qrCodeFile.ios = res.appResultInfo.iosQrcode || ''
                this.appType = res.appResultInfo.appResultType || '1'
                this.applicationInfoEntity = res.applicationInfoEntity
                res.serviceInfoList
                this.data = []

                res.serviceInfoList.map(item => {
                    let json = {
                        key: item.id,
                        name: item.interfaceName,
                        applyPerson: item.applicantName,
                        applyTime: item.createDate,
                        applyState: GetLable(item.serviceState, this.service_state_options),
                        approvalPerson: item.approvePersion,
                        approvalTime: item.approveTime
                    }
                    this.data.push(json)
                })
            })
        },
        getIPList(str) {
            if (str) {
                let arr = JSON.parse(str)
                console.log(arr)
                let res = []
                arr.map(item => {
                    res.push(item.ipaddress)
                })
                return res.join('、')
            } else {
                return ''
            }
        },
        async dictionaryInit() {
            await GetDictionary('application_state')
                .then(res => {
                    this.application_state_options = res
                })
                .catch(e => {
                    console.log(e)
                })
            await GetDictionary('service_state')
                .then(res => {
                    this.service_state_options = res
                })
                .catch(e => {
                    console.log(e)
                })
            this.detailInit()
        }
    },
    mounted() {
        this.dictionaryInit()
    }
}
</script>

<style scoped lang="scss">
.MyAppDetail {
    .commonTitle {
        width: 100%;
        height: 20px;
        line-height: 20px;
        padding-left: 12px;
        color: #000025;
        position: relative;
        display: block;
        &::after {
            content: '';
            width: 4px;
            height: 20px;
            background-color: #0284ff;
            position: absolute;
            left: 0;
            top: 0;
        }
    }
    .head {
        width: 100%;
        height: 62px;
        box-sizing: border-box;
        border-bottom: 1px solid #ebeced;
        padding-left: 34px;
        padding-top: 32px;
        font-size: $fs16;
        color: #000025;
    }
    .body {
        width: 882px;
        display: block;
        margin: 0 auto;
        margin-top: 32px;
        position: relative;
        table,
        table td,
        table th {
            border: 1px solid #dbdbdb;
            border-collapse: collapse;
        }
        .table {
            width: 680px;
            height: 28px;
            td {
                width: 50%;
                height: 40px;
                padding: 0;
                position: relative;
                div {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: flex-start;
                    align-items: center;
                    .key {
                        display: inline-block;
                        width: 100px;
                        height: 40px;
                        line-height: 40px;
                        text-align: right;
                        box-sizing: border-box;
                        padding-right: 8px;
                        color: #8e92a1;
                        background-color: #f9f9f9;
                        border-right: solid 1px #dbdbdb;
                    }
                    .value {
                        display: inline-block;
                        height: 40px;
                        line-height: 40px;
                        box-sizing: border-box;
                        padding-left: 12px;
                        color: #000025;
                    }
                }
            }
        }
        .appImg {
            width: 160px;
            height: 160px;
            position: absolute;
            right: 0;
            top: 0;
            border: solid 1px rgba(193, 200, 210, 0.5);
            background: url('~@/assets/index/appdefault.png') no-repeat;
            background-size: contain;
            .imgDetail {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }
        }
        .relateApp {
            margin-top: 36px;
            .list {
                margin-top: 16px;
                border-top: 1px solid #d5d6e3;
                table,
                table td,
                table th {
                    border: 1px solid #dbdbdb;
                    border-collapse: collapse;
                }
                /deep/ .ant-table-thead {
                    border: solid 1px #d5d6e3;
                }
                /deep/ .ant-table-thead > tr > th {
                    padding: 0 16px;
                    overflow-wrap: break-word;
                    height: 30px;
                    line-height: 30px;
                    background-color: #eef1f8;
                    color: #000025;
                    font-size: $fs12;
                }
                /deep/ .ant-table-tbody > tr > td {
                    padding: 0 16px;
                    overflow-wrap: break-word;
                    height: 30px;
                    line-height: 30px;
                    background: #f4f5f9;
                    color: #000025;
                }
                .seeDeatilBtn {
                    cursor: pointer;
                    color: #0184ff;
                }
            }
        }
        .goback {
            display: block;
            margin: 100px auto;
        }
    }
    .dotsFlow {
        /*background: red;*/
        width: 544px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        display: -webkit-box !important;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        text-align: justify;
        overflow: hidden;
    }
    .app-type-info-tab {
        display: flex;
        align-items: center;
        margin-top: 24px;
        p {
            margin-right: 12px;
            padding: 12px 0;
            width: 120px;
        }
        input {
            width: 320px;
        }
        ::v-deep .ant-upload-select-picture-card {
            margin: 0;
            width: 168px;
            height: 168px;
        }
        .img-box {
            position: relative;
            width: 168px;
            height: 168px;
            background-color: #fafafa;
            border: 1px dashed #d9d9d9;
            border-radius: 4px;
            overflow: hidden;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: content-box;
            .img-mask {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                z-index: 2;
                background: rgba(0, 0, 0, 0.6);
                opacity: 0;
                transition: all 0.4s ease;
                i {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 3;
                    font-size: 20px;
                    color: red;
                    cursor: pointer;
                }
            }
            &:hover {
                .img-mask {
                    opacity: 1;
                }
            }
            img {
                max-height: 100%;
            }
        }
    }
}
</style>
