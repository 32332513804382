<template>
    <div class="detailModal">
        <a-modal :title="title" :visible="openModal" @cancel="handleCancel" :footer="null" width="800px" :centered="true">
            <DataCatalogDetail v-if="openType == 0" :data="detailData"></DataCatalogDetail>
            <OpendataDetail v-if="openType == 1" :detailId="detailId"></OpendataDetail>
            <InterfaceServiceDetail v-if="openType == 2 || openType == 3" :detailId="detailId"></InterfaceServiceDetail>
            <slot></slot>
        </a-modal>
    </div>
</template>
<script>
import OpendataDetail from '../../OpenData/DataSet/Detail'
import InterfaceServiceDetail from '../../InterfaceService/Detail'
import DataCatalogDetail from '@/components/DataCatalogDetail1'
import { GetDetail } from '../../OpenData/api'
import { formatTime, getLable } from '@/utils/tools'
import { DataSetDetailList } from '../../OpenData/dict'
export default {
    name: 'DetailModal',
    props: {
        openModal: Boolean,
        openType: [Number, String],
        detailId: String,
        data: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            title: '',
            /***1：数据集***/
            // 详情数据
            detailData: {},
            // 详情交换数据
            dataSource: [],
            // 交换数据列
            columns: [],
            // 字典集合
            labelOptions: {
                frequeryOptions: [],
                resFormatOptions: [],
                dataTypeOptions: [],
                exchangeAttrOptions: [],
                shareAttrOptions: [],
                isOptions: [
                    { label: '否', value: '0' },
                    { label: '是', value: '1' }
                ]
            }
        }
    },
    components: { OpendataDetail, InterfaceServiceDetail, DataCatalogDetail },
    mounted() {},
    watch: {
        openType(newValue) {
            console.log('openType=>',newValue);
            if (newValue == 0) {
                this.title = '目录信息'
                this.getData()
                Object.keys(DataSetDetailList).forEach(item => {
                    this.columns.push({
                        title: DataSetDetailList[item],
                        dataIndex: item,
                        key: item,
                        width: 140,
                        fixed: item == 'name' ? 'left' : '',
                        scopedSlots: { customRender: item }
                    })
                })
            } else {
                this.title = ''
            }
        }
    },
    methods: {
        formatTime,
        getLable,
        handleCancel() {
            this.$emit('changeModal', false)
        },
        getData() {
            if(!this.detailId)return
            GetDetail({ id: this.detailId }).then(res => {
                this.detailData = res
                // let data = eval(res.collectionStructure)
                // //查看共享属性是否为数组
                // if (Array.isArray(data)) {
                //     this.dataSource = data
                //     // this.visible = true
                // }
            })
        }
    }
}
</script>
<style lang="scss" scoped></style>
