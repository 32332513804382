<template>
    <div class="MyApprovalDetail">
        <div class="head">我的服务详情信息</div>
        <div class="body">
            <div class="serviceHead">
                <div class="serviceImg">
                    <img :src="headInfo.interfaceImg" v-if="headInfo.iconUrl" class="interIconImg" alt="" />
                </div>
                <div class="infos">
                    <span class="subhead">{{ headInfo.applyformName }}</span>

                    <div class="subBody">
                        <div>
                            <div class="spanlist">
                                <span class="key">申请单号：</span>
                                <span class="value">{{ headInfo.id }}</span>
                            </div>

                            <div class="spanlist">
                                <span class="key">申请用户名称：</span>
                                <span class="value">{{ headInfo.applicantName }}</span>
                            </div>

                            <div class="spanlist">
                                <span class="key">申请用户电话：</span>
                                <span class="value" v-if="headInfo.applicantPhone">{{ telDispaly(headInfo.applicantPhone) }}</span>
                            </div>
                        </div>

                        <div class="spanlist">
                            <span class="key">申请邮箱：</span>
                            <span class="value">{{ headInfo.applicantEmail }}</span>
                        </div>

                        <div class="spanlist">
                            <span class="key">使用目的：</span>
                            <span class="value" style="display: inline-block;max-width: 200px;white-space: nowrap;overflow:hidden;text-overflow:ellipsis;vertical-align: bottom;">{{ headInfo.purpose }}</span>
                        </div>

                        <div class="spanlist">
                            <span class="key">关联应用：</span>
                            <span class="value">{{ headInfo.applicationName }}</span>
                        </div>

                        <div class="spanlist">
                            <span class="key">申请状态：</span>
                            <span class="value">{{ GetLable(headInfo.serviceState, service_state_options) }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <detail-approval-body :hastable="false" @serviceData="serviceData" @process="process" @record="record" @applicationInfoEntity="applicationInfoEntity"></detail-approval-body>

            <!-- 白名单管理 -->
            <div class="commonblock whitelist">
                <span class="whitelistTitle">白名单管理</span>
                <div class="whitelistWrapper">
                    <div class="title">
                        <span class="key">IP名称</span>
                        <span class="value">IP地址</span>
                    </div>
                    <div class="content" v-for="(item, index) in ipAddressArr" :key="index">
                        <span class="key">{{ item.name || '' }}</span>
                        <span class="value">{{ item.ipaddress }}</span>
                    </div>
                </div>
            </div>
            <!-- 服务使用信息 -->
            <div class="commonblock records">
                <span class="commonTitle">服务使用信息</span>
                <div class="recordsWrapper">
                    <!-- <div class="item">
                        <span class="key">日调用上限</span>
                        <span class="value">
                            <span>{{ dataRecords.callnumberPerday }}</span>
                            <span class="unit">次</span>
                        </span>
                    </div> -->
                    <!-- <div class="item">
                        <span class="key">总调用次数</span>
                        <span class="value">
                            <span>{{ dataRecords.callnumberTotal }}</span>
                            <span class="unit">次</span>
                        </span>
                    </div> -->
                    <!-- <div class="item">
                        <span class="key">单次调用最大条数</span>
                        <span class="value">
                            <span>{{ dataRecords.callnumberPertime }}</span>
                            <span class="unit">条</span>
                        </span>
                    </div> -->
                    <div class="item">
                        <span class="key">当日调用次数</span>
                        <span class="value">
                            <span>{{ dataRecords.toady || '0' }}</span>
                            <span class="unit">次</span>
                        </span>
                    </div>
                    <div class="item">
                        <span class="key">累计调用次数</span>
                        <span class="value">
                            <span>{{ dataRecords.total }}</span>
                            <span class="unit">次</span>
                        </span>
                    </div>
                    <div class="item">
                        <span class="key">累计服务时长</span>
                        <span class="value">
                            <span>{{ dataRecords.totalServiceTime }}</span>
                        </span>
                    </div>
                </div>
            </div>

            <!-- 审批流程 -->
            <approval-process :processData="processData"></approval-process>

            <a-button class="goback" @click="goback">返回</a-button>
        </div>
    </div>
</template>

<script>
import DetailApprovalBody from '@/views/Home/component/DetailApprovalBody'
import ApprovalProcess from '@/views/Home/component/ApprovalProcess'
import { GetDictionary } from '@/API/http'
import { resolveTimeStream, GetLable } from '@/utils/tools.js'
export default {
    name: 'MyApprovalDetail',
    data() {
        return {
            headInfo: [],
            business_type_options: null,
            service_state_options: null,
            processData: null,
            ipAddressArr: [],
            dataRecords: {
                toady: '',
                total: '',
                callnumberPerday: '',
                callnumberTotal: '',
                callnumberPertime: '',
                totalServiceTime: ''
            }
        }
    },
    components: {
        DetailApprovalBody,
        ApprovalProcess
    },
    methods: {
        resolveTimeStream,
        GetLable,
        serviceData(data) {
            this.headInfo = data
            console.log('this.headInfo=>', this.headInfo)
        },
        goback() {
            console.log('-1')
            this.$router.go(-1)
        },
        record(data) {
            //接口调用信息汇总
            this.dataRecords = data
        },
        async dictionaryInit() {
            await GetDictionary('business_type')
                .then(res => {
                    this.business_type_options = res
                })
                .catch(e => {
                    console.log(e)
                })
            await GetDictionary('service_state')
                .then(res => {
                    this.service_state_options = res
                })
                .catch(e => {
                    console.log(e)
                })
        },
        process(data) {
            console.log(data)
            this.processData = data
        },
        applicationInfoEntity(data) {
            this.applicationInfoEntity = data
            if (data.ipAddress) {
                this.ipAddressArr = JSON.parse(data.ipAddress)
            }
        },
        // 电话号码的隐蔽
        telDispaly(tel) {
            // debugger
            let ary = tel.split('')
            ary.splice(3, 4, '****')
            let tel1 = ary.join('')
            return tel1
        }
    },
    mounted() {
        this.dictionaryInit()
        console.log('图片没有')
    }
}
</script>

<style scoped lang="scss">
.MyApprovalDetail {
    .head {
        width: 100%;
        height: 62px;
        box-sizing: border-box;
        border-bottom: 1px solid #ebeced;
        padding-left: 34px;
        padding-top: 32px;
        font-size: $fs16;
        color: #000025;
    }
    .body {
        padding: 38px 30px;
    }
    .commonblock {
        width: 100%;
        margin-bottom: 32px;
        /*min-height: 500px;*/
        /*background: rgba(142, 146, 161, 0.1);*/
    }
    .serviceHead {
        width: 854px;
        height: 78px;
        margin-bottom: 32px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        .serviceImg {
            width: 78px;
            height: 78px;
            margin-right: 10px;
            background: url('~@/assets/index/interfaceIcon.png') no-repeat;
            background-size: cover;
        }
        .infos {
            width: 700px;
            height: 78px;
            /*background: #42b983;*/
            /*margin-left: 20px;*/
            .subhead {
                width: 300px;
                height: 17px;
                font-family: Source Han Sans CN;
                font-size: 17px;
                font-weight: normal;
                font-stretch: normal;
                line-height: 17px;
                letter-spacing: 0px;
                color: #000025;
            }
            .subBody {
                margin-top: 14px;
                .spanlist {
                    padding-right: 12px;
                    margin-right: 12px;
                    display: inline;
                    border-right: 1px solid grey;
                    font-size: $fs12;
                    line-height: 25px;

                    .key {
                        color: #333333;
                    }
                    .value {
                        color: #999999;
                    }
                    &:last-child {
                        border: none;
                    }
                }
            }
        }
    }
    .commonblock {
        width: 100%;
        margin-bottom: 32px;
        .commonTitle {
            width: 100%;
            height: 20px;
            line-height: 20px;
            padding-left: 12px;
            color: #000025;
            position: relative;
            display: block;
            &::after {
                content: '';
                width: 4px;
                height: 20px;
                background-color: #0284ff;
                position: absolute;
                left: 0;
                top: 0;
            }
        }
    }
    .records {
        .recordsWrapper {
            width: 854px;
            height: 105px;
            display: grid;
            margin-top: 20px;
            grid-template-columns: repeat(3, 255px);
            justify-content: space-between;
            align-items: start;
            /*background: red;*/
        }
        .item {
            width: 255px;
            height: 30px;
            /*background: #8e92a1;*/
            margin-bottom: 25px;
            user-select: none;
            .key {
                width: 108px;
                line-height: 30px;
                text-align: right;
                padding-right: 10px;
                box-sizing: border-box;
                font-family: Source Han Sans CN;
                font-size: $fs12;
                font-weight: normal;
                font-stretch: normal;
                letter-spacing: 0px;
                color: #000025;
                float: left;
            }
            .value {
                width: 147px;
                height: 30px;
                line-height: 30px;
                background-color: #ffffff;
                border: solid 1px #cccccc;
                float: right;
                color: rgba(0, 0, 0, 0.45);
                box-sizing: border-box;
                padding: 0 10px;
                .unit {
                    float: right;
                }
            }
        }
    }
    .whitelist {
        width: 100%;
        margin-bottom: 32px;
        .whitelistTitle {
            width: 100%;
            height: 20px;
            line-height: 20px;
            padding-left: 12px;
            color: #000025;
            position: relative;
            display: block;
            &::after {
                content: '';
                width: 4px;
                height: 20px;
                background-color: #0284ff;
                position: absolute;
                left: 0;
                top: 0;
            }
        }
        .whitelistWrapper {
            margin-top: 20px;
            .title,
            .content {
                display: flex;
                width: 853px;
                height: 30px;
                background-color: #eef1f8;
                border: solid 1px #d5d6e3;
                border-bottom: none;
                .key {
                    flex: 1;
                    margin: 5px 30px;
                }
                .value {
                    flex: 1;
                    margin: 5px 30px;
                }
                &:last-child {
                    border-bottom: solid 1px #d5d6e3;
                }
            }
        }
    }
    .goback {
        display: block;
        margin: 0 auto;
    }
}
</style>
