import { baseRequest, uploadRequest } from '@/API'
// const BaseUrl1 = 'http://10.33.9.171:19966'

//app申请列表
export function MyAppList(JSON) {
    return baseRequest({
        // url: '/interface/resourceservice/myusedservice',
        // url: '/application/resource/page',
        url: '/application/open/page',
        method: 'POST',
        data: JSON
    })
} //APP详情列表
export function MyAppDetail(JSON) {
    return baseRequest({
        // url: '/interface/resourceservice/myusedservice',
        // url: '/application/resource/myappdetail',
        url: '/catalog/open/myappdetail',
        method: 'POST',
        data: JSON
    })
}
//APP详情列表
export function MyAppEdit(JSON) {
    return baseRequest({
        // url: '/interface/resourceservice/myusedservice',
        // url: '/application/resource/appsubmit',
        url: '/catalog/open/appsubmit',
        method: 'POST',
        data: JSON
    })
}
//APP删除
export function MyAppDelete(JSON) {
    return baseRequest({
        // url: '/application/resource/remove',
        url: '/application/open/remove',
        method: 'POST',
        data: JSON
    })
}
//上传
export function UploadImg(JSON) {
    return uploadRequest({
        url: window.globalData.UploadAction,
        method: 'POST',
        data: JSON
    })
}
//上传
export function DataDomainSelect(JSON) {
    return baseRequest({
        url: '/catalog/dataDomain/selectName',
        method: 'POST',
        data: JSON
    })
}
