<template>
    <div class="Search">
        <login-modal v-if="openModal" @cancel="openModal = false"></login-modal>
        <div class="serachBody">
            <div class="center-content">
                <div class="left-content">
                    <FloatBox title="资源类型" style="height: 400px;">
                        <div class="slide-list">
                            <ul>
                                <li class=" all" :class="{ active: typeNow == item.type }" @click="handleLeft(item.type)" v-for="(item, index) in leftData" :key="index">
                                    <div class="flex-box">
                                        <span class="title">{{ item.name }}</span>
                                        <span>{{ item.num }}</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </FloatBox>
                </div>
                <div class="right-content">
                    <div class="listWrap">
                        <a-icon type="unordered-list" class="icon" />
                        <span class="title">资源目录</span>
                    </div>
                    <div v-if="showData">
                        <a-spin :spinning="spinning" tip="数据加载中...">
                            <div class="data-list">
                                <div class="list-item" v-for="(item, index) in dataSource" :key="item + index">
                                    <div class="content">
                                        <div class="title" @click="handleDetailClick(item)">
                                            <span class="sourceType">【{{ getTitle(typeNow) }}】</span>
                                            <em>{{ item.name }}</em>
                                            <span class="forbidden-btn" v-if="item.isopen && item.isopen != '1'">受限</span>
                                        </div>
                                        <p>{{ item.summary }}</p>
                                        <span class="time">{{ resolveTimeStream(item.updateDate) }}</span>
                                        <a-button type="primary" class="btn-add" :class="item.hasApplied ? 'btn-added' : ''" v-if="item.isopen != '1' && typeNow == '0'" @click="handleAppyBtnClick(item)">
                                            {{ item.hasApplied ? '已申请' : '受限目录申请' }}
                                        </a-button>
                                    </div>
                                </div>
                            </div>
                            <div class="pagination">
                                <a-pagination show-size-changer show-quick-jumper :current="current" :default-current="current" :total="total" :page-size-options="pageSizeOptions" :page-size.sync="pageSize" @change="onChange" @showSizeChange="onShowSizeChange" />
                            </div>
                        </a-spin>
                    </div>
                    <no-data v-else></no-data>
                </div>
            </div>
        </div>
        <div>
            <DetailModal :openModal="openDetailModal" @changeModal="changeModal" :openType="openType" :detailId="detailId"></DetailModal>
        </div>
    </div>
</template>

<script>
import { getCookie } from '@/utils/cookie'
import { GetDictionary } from '@/API/http'
import LoginModal from '@/components/LoginModal'
import { GetLable, resolveTimeStream } from '@/utils/tools'
import { searchMenu, searchList } from './api/index'
import { getAppliedCatalogs } from '@/views/OpenData/api/index'
import NoData from './component/NoData'
import DetailModal from './component/DetailModal'
export default {
    name: 'Search',
    data() {
        return {
            // 搜索
            showData: false,
            spinning: true, //页面loading 状态
            pageSize: 5,
            pageSizeOptions: ['5', '10', '20', '50'],
            // page
            dataSource: [],
            leftData: '',
            keywords: '',
            typeNow: 0,
            search_type_option: '',
            current: 1,
            total: 100,
            openModal: false,
            // 对话框
            openDetailModal: false,
            detailId: '',
            openType: '',
            hasAppliedList: []
        }
    },
    components: {
        NoData,
        DetailModal,
        LoginModal
    },
    watch: {
        $route() {
            this.initSearch()
        }
    },
    mounted() {
        this.keywords = this.$route.query.keywords
        this.getDictionary()
    },
    methods: {
        resolveTimeStream,
        getTitle(type) {
            // debugger
            if (type == '1') {
                return '数据集'
            }
            if (type == '2') {
                return '数据接口'
            }
            return '数据目录'
        },
        handleLeft(type) {
            this.current = 1
            this.filter(type)
        },
        handleAppyBtnClick(item) {
            //受限目录申请
            // this.showApplyCatalogModel = true
            if (!getCookie('kf_token')) {
                this.openModal = true
                return
            }
            if (item.hasApplied) return
            this.$router.push({ name: 'DataCatalogApplication', query: { id: item.businessId } })
            console.log(item)
        },
        handleDetailClick(item) {
            this.openDetailModal = true
            this.openType = item.type
            this.detailId = item.businessId
            // debugger
            // const type = item.type
            // if (type == 0) {
            // this.detailId = 'e9501fb78f9848c6829072972939959b'
            // this.detailId = '0e164faedf8340b6b136037e1c6f7679'
            // } else if (type == 1) {
            // this.detailId = '058016f143b24731897b9d3c8930eea6'
            // } else {
            //     this.detailId = item.id // 'f5cf9f0720134ba0b18fc43154bd59f0'
            // }
        },
        onShowSizeChange() {
            // console.log(e)
            this.current = 1
            this.filter(this.typeNow)
        },
        onChange(e) {
            // console.log(e)
            console.log(this.current)
            this.current = e
            this.filter(this.typeNow)
        },
        async getDictionary() {
            await GetDictionary('search_type').then(res => {
                this.search_type_option = res
            })
            await this.initSearch()
        },
        initSearch() {
            let _this = this
            if (!this.$route.query.keywords) return

            this.dataSource = []
            this.leftData = []

            searchMenu({
                //左侧目录
                name: this.$route.query.keywords
            })
                .then(res => {
                    this.showData = true
                    this.$store.dispatch('searchHistory')
                    if (res.length > 0) {
                        res.forEach(item => {
                            item.name = GetLable(item.type, this.search_type_option)
                        })
                        _this.leftData = res
                        // debugger
                        this.filter(this.leftData[0].type)
                        setTimeout(() => {
                            this.spinning = false
                        }, 300)
                    } else {
                        this.showData = false
                    }
                })
                .catch(() => {})
        },
        filter(type) {
            this.showData = true
            this.spinning = true
            this.typeNow = type
            Promise.allSettled([
                getAppliedCatalogs(),
                searchList({
                    pageNo: this.current,
                    pageSize: this.pageSize,
                    param: {
                        name: this.$route.query.keywords,
                        type: type,
                        sidx: 'create_date',
                        sord: 'desc'
                    }
                })
            ]).then(res => {
                this.handleHasAppliedRes(res[0])
                this.handleGetDataCatalogListRes(res[1])
                this.spinning = false
            })
        },
        handleHasAppliedRes(res) {
            if (res.status == 'rejected') {
                this.hasAppliedList = []
                return
            }
            this.hasAppliedList = res.value || []
        },
        handleGetDataCatalogListRes(res) {
            if (res.status == 'rejected') {
                this.dataSource = []
                return
            }

            this.total = res.value.total
            this.current = res.value.current
            this.dataSource = res.value.records
            this.dataSource = this.dataSource.map(item => {
                item.hasApplied = this.hasAppliedList.includes(item.id)
                return this._.omit(item, ['downloadNum'])
            })
            setTimeout(() => {
                if (res.value.records.length) {
                    this.showData = true
                } else {
                    this.showData = false
                }
                this.spinning = false
            }, 300)
        },

        changeModal(val) {
            this.openDetailModal = val
            console.log('changeModal=>',val);
            if(!val){
                this.openType = ''
                this.detailId = ''
            }
        }
    },
    activated() {}
}
</script>

<style scoped lang="scss">
.Search {
    background: #f4f5f6;
    .serachBody {
        .center-content {
            width: 1200px;
            display: block;
            margin: 0 auto;
            padding: 24px 0 48px 0;
            .left-content {
                float: left;
                .slide-list {
                    margin-top: 10px;
                    padding-bottom: 24px;
                    .title {
                        margin-left: 10px;
                        color: #333;
                    }
                    li {
                        display: flex;
                        padding: 0 27px 0 17px;
                        margin: 0 7px 0 13px;
                        align-items: center;
                        font-size: $fs14;
                        span {
                            color: #999;
                        }
                    }
                    li.all {
                        font-size: $fs16;
                    }
                    li.active {
                        background-color: #f7f8f8;
                        span {
                            color: #0184ff;
                        }
                    }
                    .flex-box {
                        height: 36px;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        cursor: pointer;
                    }
                }
            }
            .right-content {
                width: 896px;
                background: #fff;
                margin-left: 304px;
                padding: 0 32px 0 52px;
                overflow: auto;
                min-height: 800px;
                .listWrap {
                    .icon {
                        font-size: 20px;
                    }
                    .title {
                        width: 100px;
                        height: 24px;
                        font-size: 24px;
                        line-height: 24px;
                        margin-left: 12px;
                    }
                    margin-top: 20px;
                }
                .total-box {
                    display: flex;
                    padding: 16px 0;
                    justify-content: space-between;
                    .total {
                        color: #333;
                        font-size: $fs20;
                    }
                    .clear {
                        display: flex;
                        align-items: center;
                        font-size: $fs12;
                        em {
                            display: inline-block;
                            margin-left: 8px;
                        }
                    }
                }
                .data-list {
                    min-height: 500px;
                    .list-item {
                        height: 82px;
                        border-bottom: 1px #d2d3d3 dashed;
                        box-sizing: border-box;
                        display: flex;
                        align-items: center;
                        .content {
                            flex: 1;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            /* height: 40px; */
                            position: relative;
                            .title {
                                display: flex;
                                align-items: center;
                                height: 40px;
                                font-size: $fs14;
                                margin-bottom: 2px;
                                padding-right: 170px;
                                cursor: pointer;
                                .sourceType {
                                    color: $blue;
                                }
                                em {
                                    cursor: pointer;
                                }
                            }
                            .btn-apply {
                                position: absolute;
                                right: 0;
                                top: 0;
                            }
                            .btn-add {
                                position: absolute;
                                right: 0;
                                top: 0;

                                padding: 0px 18px;
                                text-align: center;
                                border-radius: 4px;
                                font-size: $fs12;
                                color: #fff;
                                background-color: #357df1;
                                transition: all 0.3s ease;
                                cursor: pointer;
                                &:hover {
                                    opacity: 0.8;
                                }
                            }
                            .btn-added {
                                background-color: #e2f1ff !important;
                                color: #357df1 !important;
                            }
                            p {
                                font-size: $fs12;
                                color: #999;
                            }
                            .time {
                                position: absolute;
                                text-align: right;
                                right: 0;
                                bottom: 0px;
                                width: 150px;
                                height: $fs14;
                                font-family: Source Han Sans CN;
                                font-size: 14px;
                                font-weight: normal;
                                font-stretch: normal;
                                line-height: $fs14;
                                letter-spacing: 0px;
                                color: #999999;
                            }
                        }
                        .date {
                            width: 150px;
                            height: 40px;
                            display: flex;
                            flex-direction: column;
                            text-align: right;
                            justify-content: space-between;
                            font-family: Source Han Sans CN;
                            font-size: $fs12;
                            color: #999999;
                        }
                    }
                }
                .pagination {
                    margin-top: 40px;
                    margin-bottom: 48px;
                    ul {
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }
    }
    .forbidden-btn {
        display: inline-block;
        text-align: center;
        color: #f58646;
        font-size: 10px;
        /* width: 20px; */
        /* height: 10px; */
        background-color: #fff1e9;
        border-radius: 10px;
        padding: 3px 10px;
        margin-left: 12px;
    }
}
</style>
