<template>
    <div class="DetailApprovalBody">
        <div class="body">
            <div class="commonblock" v-if="hastable">
                <span class="commonTitle" v-if="type == '0'">数据交换申请信息</span>
                <span class="commonTitle" v-else-if="type == '1'">接口服务申请信息</span>
                <span class="commonTitle" v-else>视频资源申请信息</span>
                <table class="infotable" border="1">
                    <tbody v-if="type == 0">
                        <tr>
                            <td>
                                <div>
                                    <span class="key">申请标题</span>
                                    <span class="value" :title="baseInfo.applTitle">{{ baseInfo.applTitle }}</span>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <span class="key">申请人</span>
                                    <span class="value">{{ baseInfo.applName }}</span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div>
                                    <span class="key">联系电话</span>
                                    <span class="value">{{ baseInfo.applTel }}</span>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <span class="key">电子邮箱</span>
                                    <span class="value">{{ baseInfo.email || '无' }}</span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div>
                                    <span class="key">业务类型</span>
                                    <span class="value">{{ false || '无' }}</span>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <span class="key">申请部门</span>
                                    <span class="value">{{ baseInfo.applCompany }}</span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div>
                                    <span class="key">申请状态</span>
                                    <span class="value">{{ GetLable(baseInfo.applyState, apply_type_options) }}</span>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <span class="key">申请时间</span>
                                    <span class="value">{{ resolveTimeStream(baseInfo.updateDate) }}</span>
                                </div>
                            </td>
                        </tr>
                        <tr v-if="baseInfo.updateDate">
                            <td colspan="2">
                                <div>
                                    <span class="key">审批时间</span>
                                    <span class="value">{{ resolveTimeStream(baseInfo.updateDate) }}</span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-if="type == 1">
                        <tr>
                            <td>
                                <div>
                                    <span class="key">申请标题</span>
                                    <span class="value" :title="serviceInfo.applyformName">{{ serviceInfo.applyformName }}</span>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <span class="key">申请人</span>
                                    <span class="value">{{ serviceInfo.applicantName }}</span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div>
                                    <span class="key">联系电话</span>
                                    <span class="value">{{ serviceInfo.applicantPhone }}</span>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <span class="key">电子邮箱</span>
                                    <span class="value">{{ serviceInfo.applicantEmail || '无' }}</span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div>
                                    <span class="key">业务类型</span>
                                    <span class="value">{{ GetLable(serviceInfo.businessType, business_type_options) || '无' }}</span>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <span class="key">申请状态</span>
                                    <span class="value">{{ GetLable(serviceInfo.serviceState, service_state_options) }}</span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td :colspan="!serviceInfo.approveTime ? 2 : 1">
                                <div>
                                    <span class="key">申请时间</span>
                                    <span class="value" v-if="serviceInfo.updateDate">{{ resolveTimeStream(serviceInfo.updateDate) }}</span>
                                </div>
                            </td>
                            <td v-if="serviceInfo.approveTime">
                                <div>
                                    <span class="key">审批时间</span>
                                    <span class="value" v-if="serviceInfo.approveTime">{{ resolveTimeStream(serviceInfo.approveTime) || '未知' }}</span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-if="type == 2">
                        <tr>
                            <td>
                                <div>
                                    <span class="key">申请单标题</span>
                                    <span class="value" :title="apply.applyTitle">{{ apply.applyTitle }}</span>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <span class="key">申请人</span>
                                    <span class="value">{{ apply.applyPhone }}</span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div>
                                    <span class="key">联系电话</span>
                                    <span class="value">{{ apply.applyPhone }}</span>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <span class="key">申请部门</span>
                                    <span class="value">{{ apply.applyOrgName }}</span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div>
                                    <span class="key">申请人邮箱</span>
                                    <span class="value">{{ apply.applyMail || '无' }}</span>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <span class="key">使用目的</span>
                                    <span class="value">{{ apply.applyPurpose }}</span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div>
                                    <span class="key">申请时间</span>
                                    <span class="value">{{ resolveTimeStream(apply.createDate) }}</span>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <span class="key">审批时间</span>
                                    <span class="value">{{ resolveTimeStream(apply.updateDate) }}</span>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td colspan="2">
                                <div>
                                    <span class="key">申请状态</span>
                                    <span class="value">{{ GetLable(apply.applyState, video_state_options) }}</span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div v-if="type == 1 || showAll === 'true'" class="commonblock">
                <span class="commonTitle">关联接口信息</span>
                <div class="interfaceInfo">
                    <div class="interIcon">
                        <img :src="dataInterface.interfaceImg" v-if="dataInterface.interfaceImg" class="interIconImg" alt="" />
                    </div>

                    <span class="interfaceTitle" @click="goInterfaceDetail">{{ dataInterface.name }}</span>
                    <span class="interFaceDesc">
                        接口摘要
                        <a-popover>
                            <span slot="content">{{ dataInterface.remarks }}</span>
                            <span class="descDetail">{{ dataInterface.remarks }}</span>
                        </a-popover>
                    </span>
                    <ul class="delist">
                        <li class="deli">
                            <span class="solid solid1"></span>
                            <span class="iname">接口协议</span>
                            <span class="ivalue">{{ GetLable(dataInterface.interfaceProtocol, interface_protocol_options) }}</span>
                        </li>
                        <li class="deli">
                            <span class="solid solid2"></span>
                            <span class="iname">调用方式</span>
                            <span class="ivalue">{{ GetLable(dataInterface.callMode, call_mode_options) }}</span>
                        </li>
                        <li class="deli">
                            <span class="solid solid3"></span>
                            <span class="iname">请求数据格式</span>
                            <span class="ivalue">{{ GetLable(dataInterface.inparamFormat, params_options) || '无' }}</span>
                        </li>
                        <li class="deli">
                            <span class="solid solid4"></span>
                            <span class="iname">返回数据格式</span>
                            <span class="ivalue">{{ GetLable(dataInterface.outparamFormat, params_options) || '无' }}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div v-if="(type == 1 || showAll === 'true') && applicationInfoEntity" class="commonblock">
                <span class="commonTitle">关联应用信息</span>
                <div class="interfaceInfo">
                    <div class="interIcon">
                        <img :src="applicationInfoEntity.interfaceImg" v-if="applicationInfoEntity.iconUrl" class="interIconImg" alt="" />
                    </div>
                    <span class="appTitle">{{ applicationInfoEntity.applicationName }}</span>
                    <span class="interFaceDesc">
                        接口摘要
                        <a-popover>
                            <span slot="content">{{ applicationInfoEntity.applicationDesc }}</span>
                            <span class="descDetail">{{ applicationInfoEntity.applicationDesc }}</span>
                        </a-popover>
                    </span>
                    <ul class="delist">
                        <li class="deli">
                            <span class="solid solid5"></span>
                            <span class="iname">应用负责人</span>
                            <span class="ivalue">{{ applicationInfoEntity.chargePersionName }}</span>
                        </li>
                        <li class="deli">
                            <span class="solid solid6"></span>
                            <span class="iname">负责人电话</span>
                            <span class="ivalue">{{ applicationInfoEntity.chargePersionPhone }}</span>
                        </li>
                        <li class="deli">
                            <span class="solid solid7"></span>
                            <span class="iname">IP地址</span>
                            <span class="ivalue visiable">{{ getIPList(applicationInfoEntity.ipAddress) }}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div v-if="type === 2" class="commonblock">
                <span class="commonTitle">视频通道列表</span>
                <span class="videoTotal">共{{ videoTotal }}个视频资源通道</span>
                <div class="videoApplyList">
                    <div class="data-list">
                        <div class="list-item" v-for="(item, index) in channelApplyList" :key="index">
                            <div class="content">
                                <div class="title" @click="handleDetailClick(item.id)">
                                    <span class="sourceType">{{ item.channelName }}</span>
                                    <em v-if="false" style="position: absolute;left: 82px">
                                        <span class="it1">channelName</span>
                                        <span class="it2">channelName</span>
                                    </em>
                                </div>
                                <p>
                                    {{ item.channelOrgName }}
                                </p>
                                <span class="time">2020-05-06 15:13:14</span>
                            </div>
                        </div>
                    </div>
                    <div class="pagination">
                        <a-pagination v-model="current" :total="videoTotal" :page-size-options="pageSizeOptions" :page-size.sync="pageSize" show-size-changer show-quick-jumper :default-current="1" @showSizeChange="onShowSizeChange" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { GetDataExchangeDetail, InterfaceServiceDetail, OpenServiceDetail, VideoChannelList, VideoDetail } from '@/views/Home/MyApproval/api'
import { GetLable, resolveTimeStream } from '@/utils/tools.js'
import { GetDictionary } from '@/API/http'

export default {
    name: 'DetailApprovalBody',
    props: {
        hastable: {
            type: Boolean,
            default: true
        }
        /*  type: {
type: [String]
},
id: {
type: [String]
}*/
    },
    data() {
        return {
            id: null,
            type: null,
            showAll: null,
            baseInfo: {},
            serviceInfo: {}, //接口服务详情信息
            dataInterface: {}, //关联接口信息
            applicationInfoEntity: {}, //关联应用信息
            apply: {}, //视频申请详情
            apply_type_options: null,
            business_type_options: null,
            interface_protocol_options: null,
            call_mode_options: null,
            service_state_options: null,
            video_state_options: null,
            dataSource: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            params_options: [
                {
                    value: '0',
                    label: 'JSON'
                },
                {
                    value: '1',
                    label: 'XML'
                }
            ],
            pageSize: 5,
            pageSizeOptions: ['5', '10', '20', '50'],
            current: 1,
            videoTotal: 454, //视频申请总数
            channelApplyList: [
                {
                    applyDataId: '',
                    channelId: 'iiiiidddd',
                    channelName: '四-102',
                    channelOrg: '',
                    channelOrgName: '杭州市公安局',
                    channelType: '',
                    id: '',
                    createBy: '',
                    stat: 0,
                    updateBy: ''
                }
            ]
        }
    },
    methods: {
        resolveTimeStream,
        GetLable,
        handleDetailClick(id) {
            this.$router.push({
                name: 'VideoCatalogDetail',
                query: { id }
            })
        },
        onShowSizeChange(current, size) {
            this.current = current
            this.pageSize = size
            this.listInit()
        },
        goInterfaceDetail() {
            let stat = this.dataInterface.stat
            if (stat != '1') return this.$message.warn('该接口已下线')
            if (this.dataInterface.catalogId) {
                this.$router.push({
                    path: '/InterfaceService/DataSetDetail',
                    query: { id: this.dataInterface.id }
                })
            } else {
                this.$router.push({
                    path: '/InterfaceService/Detail',
                    query: { id: this.dataInterface.id, serviceState: 0 }
                })
            }
        },
        getIPList(str) {
            if (str) {
                let arr = JSON.parse(str)
                console.log(arr)
                let res = []
                arr.map(item => {
                    res.push(item.ipaddress)
                })
                return res.join('、')
            } else {
                return ''
            }
        },
        async dictionaryInit() {
            if (this.type === '0') {
                console.log('数据初数化1')
                await GetDictionary('exchangeAuditState')
                    .then(res => {
                        this.apply_type_options = res
                    })
                    .catch(e => {
                        console.log(e)
                    })
            } else if (this.type === '1' || this.showAll) {
                console.log('数据初数化2')
                await GetDictionary('business_type')
                    .then(res => {
                        this.business_type_options = res
                    })
                    .catch(e => {
                        console.log(e)
                    })
                await GetDictionary('interface_protocol')
                    .then(res => {
                        this.interface_protocol_options = res
                    })
                    .catch(e => {
                        console.log(e)
                    })
                await GetDictionary('call_mode')
                    .then(res => {
                        this.call_mode_options = res
                    })
                    .catch(e => {
                        console.log(e)
                    })
                await GetDictionary('service_state')
                    .then(res => {
                        this.service_state_options = res
                    })
                    .catch(e => {
                        console.log(e)
                    })
            } else if (this.type === '2') {
                await GetDictionary('video_state')
                    .then(res => {
                        this.video_state_options = res
                    })
                    .catch(e => {
                        console.log(e)
                    })
            } else if (this.showAll) {
                //
            }
            this.listInit()
        },
        listInit() {
            if (this.type == 0) {
                GetDataExchangeDetail({ id: this.id }).then(res => {
                    this.baseInfo = res.sysExchange
                    this.$emit('process', res.flowHistory)
                })
            } else if (this.type == 1) {
                InterfaceServiceDetail({ id: this.id }).then(res => {
                    this.serviceInfo = res.serviceInfo
                    this.dataInterface = res.dataInterface
                    this.applicationInfoEntity = res.applicationInfoEntity
                    this.$emit('process', res.list)
                })
            } else if (this.type == 2) {
                VideoDetail({ id: this.id }).then(res => {
                    // VideoDetail({ id: '11111' }).then(res => {
                    console.log(res)
                    this.apply = res
                    this.$emit('process', res.taskFlowHistoryList)
                })
                VideoChannelList({
                    pageNo: this.current,
                    pageSize: this.pageSize,
                    param: {
                        id: this.$route.query.id
                    }
                }).then(res => {
                    console.log(res)
                    this.current = res.current
                    this.videoTotal = res.total
                    this.channelApplyList = res.records
                })
            } else if (this.showAll) {
                //f服务详情
                /* ServiceDetail({ id: this.id }).then(res => {
    // this.baseInfo = res.sysExchange
    console.log(res)
    this.$emit('process', res.flowHistory)
    this.$emit('serviceData', res)
})*/
                console.log('我的服务详情')
                OpenServiceDetail({ id: this.id }).then(res => {
                    this.serviceInfo = res.serviceInfo
                    this.dataInterface = res.dataInterface
                    this.applicationInfoEntity = res.applicationInfoEntity
                    if (!this.applicationInfoEntity) {
                        this.$message.warn('相关应用已被删除，无法展示应用信息')
                    }
                    let records = {}
                    records.toady = res.busServiceStaticOut.toady
                    records.total = res.busServiceStaticOut.total
                    records.callnumberPerday = res.serviceInfo.callnumberPerday
                    records.callnumberTotal = res.serviceInfo.callnumberTotal
                    records.callnumberPertime = res.serviceInfo.callnumberPertime
                    records.totalServiceTime = res.serviceInfo.totalServiceTime
                    this.$emit('applicationInfoEntity', this.applicationInfoEntity)
                    this.$emit('process', res.approveList)
                    this.$emit('serviceData', res.serviceInfo)
                    this.$emit('record', records)
                })
            }
        }
    },
    mounted() {
        let query = this.$route.query
        this.type = query.type // 0 数据交换 1 接口服务申请 2视频目录申请
        this.id = query.id
        this.showAll = String(query.showAll)
        this.dictionaryInit()
    }
}
</script>

<style scoped lang="scss">
.DetailApprovalBody {
    .commonTitle {
        width: 100%;
        height: 20px;
        line-height: 20px;
        padding-left: 12px;
        color: #000025;
        position: relative;
        display: block;
        &::after {
            content: '';
            width: 4px;
            height: 20px;
            background-color: #0284ff;
            position: absolute;
            left: 0;
            top: 0;
        }
    }
    .body {
        width: 100%;
        .commonblock {
            width: 100%;
            margin-bottom: 32px;
            /*min-height: 500px;*/
            /*background: rgba(142, 146, 161, 0.1);*/
        }
        .infotable {
            width: 854px;
            height: 200px;
            margin-top: 24px;
            td {
                width: 50%;
                height: 40px;
                padding: 0;
                position: relative;
                div {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: flex-start;
                    align-items: center;
                    .key {
                        display: inline-block;
                        width: 100px;
                        height: 40px;
                        line-height: 40px;
                        text-align: right;
                        box-sizing: border-box;
                        padding-right: 8px;
                        color: #8e92a1;
                        background-color: #f9f9f9;
                        border-right: solid 1px #dbdbdb;
                    }
                    .value {
                        display: inline-block;
                        width: 326px;
                        height: 40px;
                        line-height: 40px;
                        box-sizing: border-box;
                        padding-left: 12px;
                        color: #000025;
                        -webkit-box-sizing: border-box;
                        box-sizing: border-box;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                        text-align: justify;
                        overflow: hidden;
                    }
                }
            }
        }
        table,
        table td,
        table th {
            border: 1px solid #dbdbdb;
            border-collapse: collapse;
        }
        .interfaceInfo {
            width: 854px;
            height: 140px;
            border: 1px solid #cccccc;
            box-sizing: border-box;
            margin-top: 15px;
            position: relative;
            .interIcon {
                width: 40px;
                height: 40px;
                border: 1px solid #cccccc;
                position: absolute;
                top: 20px;
                left: 16px;
                background: url('~@/assets/index/interfaceIcon.png') no-repeat;
                background-size: cover;
                .interIconImg {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                }
            }
            .interfaceTitle,.appTitle {
                width: 360px;
                height: 16px;
                font-family: Source Han Sans CN;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                line-height: 16px;
                letter-spacing: 1px;
                color: #000025;
                position: absolute;
                top: 20px;
                left: 72px;
                cursor: pointer;
            }
            .interFaceDesc {
                width: 764px;
                height: $fs13;
                font-family: Source Han Sans CN;
                font-size: $fs12;
                font-weight: normal;
                font-stretch: normal;
                line-height: $fs12;
                letter-spacing: 0px;
                color: #333333;
                position: absolute;
                top: 45px;
                left: 72px;
                .descDetail {
                    color: #999999;
                    margin-left: 8px;
                }
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                text-align: justify;
                overflow: hidden;
            }
            &:hover {
                border: 1px solid #0184ff;
                .interfaceTitle {
                    color: #0184ff;
                }
            }
            .delist {
                width: 744px;
                height: 30px;
                position: absolute;
                top: 76px;
                left: 16px;
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-start;
                .deli {
                    width: 186px;
                    height: 30px;
                    position: relative;
                    .solid {
                        width: 26px;
                        height: 26px;
                        position: absolute;
                        left: 0;
                        top: 0;
                    }
                    .iname {
                        width: 140px;
                        height: $fs12;
                        font-family: Source Han Sans CN;
                        font-size: $fs12;
                        font-weight: normal;
                        font-stretch: normal;
                        line-height: $fs12;
                        letter-spacing: 0px;
                        color: rgba(0, 0, 0, 0.45);
                        position: absolute;
                        left: 36px;
                        top: 0;
                    }
                    .ivalue {
                        width: 140px;
                        height: 14px;
                        font-family: Source Han Sans CN;
                        font-size: $fs12;
                        font-weight: normal;
                        font-stretch: normal;
                        line-height: $fs12;
                        letter-spacing: 0px;
                        color: rgba(0, 0, 0, 0.85);
                        position: absolute;
                        left: 36px;
                        top: 16px;
                    }
                    .visiable {
                        overflow: visible;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
        .videoTotal {
            display: block;
            margin-top: 24px;
            .blue {
            }
        }
        .data-list {
            .list-item {
                height: 72px;
                border-bottom: 1px #d2d3d3 dashed;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                position: relative;
                .content {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    height: 40px;
                    position: relative;
                    .title {
                        font-size: $fs14;
                        cursor: pointer;
                        .sourceType {
                            color: $blue;
                        }
                        em {
                            cursor: pointer;
                            .it1 {
                                padding: 0 14px;
                                color: #999999;
                                font-size: $fs12;
                                line-break: $fs12;
                                border-right: 1px solid #999999;
                            }
                            .it2 {
                                padding: 0 14px;
                                font-size: $fs12;
                                line-break: $fs12;
                                color: #999999;
                            }
                        }
                    }
                    p {
                        font-size: $fs12;
                        color: #999;
                    }
                    .time {
                        position: absolute;
                        text-align: right;
                        right: 0;
                        bottom: 0px;
                        width: 150px;
                        height: $fs14;
                        font-family: Source Han Sans CN;
                        font-size: 14px;
                        font-weight: normal;
                        font-stretch: normal;
                        line-height: $fs14;
                        letter-spacing: 0px;
                        color: #999999;
                    }
                }
                .date {
                    width: 150px;
                    height: 40px;
                    display: flex;
                    flex-direction: column;
                    text-align: right;
                    justify-content: space-between;
                    font-family: Source Han Sans CN;
                    font-size: $fs12;
                    color: #999999;
                }
            }
        }
        .pagination {
            margin-top: 40px;
            margin-bottom: 48px;
            ul {
                display: flex;
                justify-content: center;
            }
        }
    }
}
</style>
