import { baseRequest } from '@/API'

export function CollectPage(params) {
    // 获取收藏列表接口
    return baseRequest({
        url: '/usercenter/opencollect/collectPage',
        method: 'POST',
        data: params
    })
}
export function GetCollectResStat(params) {
    // 获取收藏数据是否上下线
    return baseRequest({
        url: '/usercenter/opencollect/getCollectResStat',
        method: 'POST',
        data: params
    })
}
// 资源目录列表
export function CatalogList(JSON) {
    return baseRequest({
        // url: '/usercenter/resourcecollect/catlogpage',
        url: '/usercenter/opencollect/catlogpage',
        method: 'POST',
        data: JSON
    })
}
//数据接口 列表
export function DataList(JSON) {
    return baseRequest({
        // url: '/usercenter/resourcecollect/datapage',
        url: '/usercenter/opencollect/datapage',
        method: 'POST',
        data: JSON
    })
}
//数据接口 列表
export function AppList(JSON) {
    return baseRequest({
        // url: '/usercenter/resourcecollect/datapage',
        url: '/usercenter/opencollect/appresultpage',
        method: 'POST',
        data: JSON
    })
}
//删除列表
export function DeleteApp(query) {
    return baseRequest({
        // url: '/usercenter/resourcecollect/del',
        url: '/usercenter/opencollect/del',
        method: 'POST',
        params: query
    })
}
//查询详情
export function GetDetail(JSON) {
    return baseRequest({
        url: '/catalog/dataOpen/selectOne',
        method: 'POST',
        params: JSON
    })
}
