<template>
    <div class="MyApprovalDetail">
        <div class="head">我的申请详细信息</div>
        <div class="body">
            <detail-approval-body  @process="handleProcess"></detail-approval-body>
            <approval-process :processData="processData"></approval-process>
            <a-button class="goback" @click="goback">返回</a-button>
        </div>
    </div>
</template>

<script>
import { handleDateToString } from '@/utils/tools'
import DetailApprovalBody from '@/views/Home/component/DetailApprovalBody'
import ApprovalProcess from '@/views/Home/component/ApprovalProcess'
export default {
    name: 'MyApprovalDetail',
    data() {
        return {
            processData: []
        }
    },
    components: {
        DetailApprovalBody,
        ApprovalProcess
    },
    methods: {
        goback() {
            console.log('-1')
            this.$router.go(-1)
        },
        resolveTime(time) {
            let str = handleDateToString(time, true)
            return str.split(' ')[0] + ' ' + str.split(' ')[1]
        },
        handleProcess(e) {
            this.processData = e
        }
    },
    mounted() {}
}
</script>

<style scoped lang="scss">
.MyApprovalDetail {
    .head {
        width: 100%;
        height: 62px;
        box-sizing: border-box;
        border-bottom: 1px solid #ebeced;
        padding-left: 34px;
        padding-top: 32px;
        font-size: $fs16;
        color: #000025;
    }
    .body {
        padding: 38px 30px;
    }
    .commonblock {
        width: 100%;
        margin-bottom: 32px;
        /*min-height: 500px;*/
        /*background: rgba(142, 146, 161, 0.1);*/
    }
    .commonTitle {
        width: 100%;
        height: 20px;
        line-height: 20px;
        padding-left: 12px;
        color: #000025;
        position: relative;
        display: block;
        &::after {
            content: '';
            width: 4px;
            height: 20px;
            background-color: #0284ff;
            position: absolute;
            left: 0;
            top: 0;
        }
    }
    .process {
        width: 500px;
        min-height: 101px;
        position: relative;
        .processLi {
            width: 100%;
            height: 45px;
            line-height: 45px;
            padding-left: 50px;
            position: relative;
            .seeMore {
                display: block;
                width: 34px;
                height: 34px;
                position: absolute;
                background: #fdfdfd;
                left: -15px;
                top: 6px;
                text-align: center;
                line-height: 34px;
                z-index: 30;
                color: #0284ff;
                .see--icons {
                    width: 24px;
                    height: 24px;
                    line-height: 24px;
                    border-radius: 24px;
                    font-size: 15px;
                    overflow: hidden;
                    border: 1px solid #0284ff;
                    position: absolute;
                    left: 4px;
                    top: 5px;
                    cursor: pointer;
                }
            }
            .pname {
                width: 80px;
                display: block;
                float: left;
            }
            .paction {
                width: 36px;
                display: block;
                float: left;
            }
            .pactionDesc {
                width: 234px;
                display: block;
                float: left;
            }
            .pactionTime {
                display: block;
                float: left;
                text-align: right;
            }
        }
        $left: 0px;
        .processLi:after {
            content: '';
            width: 2px;
            height: 100%;
            position: absolute;
            left: $left;
            top: 0;
            border-left: 2px solid #d5d6e3;
            z-index: 1;
        }
        .processLi::before {
            content: '';
            width: 9px;
            height: 9px;
            position: absolute;
            left: -4px;
            top: 40%;
            border-radius: 9px;
            background: #d5d6e3;
            z-index: 10;
        }
        .processLi:first-child::after {
            content: '';
            width: 2px;
            height: 50%;
            position: absolute;
            left: $left;
            top: 50%;
            border-left: 2px solid #d5d6e3;
        }
        .processLi:last-child::after {
            content: '';
            width: 2px;
            height: 50%;
            position: absolute;
            left: $left;
            top: 0%;
            border-left: 2px solid #d5d6e3;
        }
    }
    .goback {
        display: block;
        margin: 0 auto;
    }
}
</style>
