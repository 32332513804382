<template>
    <div class="page">
        <div class="login-container" id="login-container"></div>
    </div>
</template>

<script>
import { _script } from '@/config/script.js'
export default {
    name: 'zzdingLogin',
    data() {
        return {}
    },
    components: {},
    created() {
        // this.createScript('https://sso.ding.zj.gov.cn/static/js/idaas-login-plugin.js')
        // this.$forceUpdate()
    },
    async mounted() {
        this.createScript('', _script[window.globalData.city.name]['text'])
    },
    methods: {
        createScript(src = '', text) {
            let _script = document.createElement('script')
            src && _script.setAttribute('src', src)
            if (text) {
                _script.text = text
            }
            document.getElementsByTagName('body')[0].appendChild(_script)
        }
    }
}
</script>
<style scoped lang="scss">
.page {
    height: 100vh;
    background-color: #f2f2f2;
    max-height: 100vh;
    width: 100%;
    overflow: hidden;
    position: relative;
    .login-container {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}
</style>
