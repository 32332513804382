<template>
    <div class="ApprovalProcess">
        <div class="commonblock" style="width:100%;overflow:hidden;position: relative">
            <span class="commonTitle">审批流程</span>
            <ul class="process" style="margin-left: 25px;margin-top: 25px;position: relative">
                <li class="processLi" v-for="(item, index) in processData" :key="index">
                    <a-popover placement="top" v-if="item.handleRemark">
                        <template slot="content">
                            <p>{{ item.handleRemark }}</p>
                        </template>
                        <div>
                            <span class="seeMore">
                                <span class="see--icons"><a-icon type="unordered-list" /></span>
                            </span>
                            <span class="pname" :title="item.handleMan">{{ item.handleMan }}</span>
                            <span class="paction"></span>
                            <span class="pactionDesc">{{ item.handleInfo }}</span>
                            <span class="pactionTime">{{ resolveTime(item.updateDate) }}</span>
                        </div>
                    </a-popover>
                    <div v-else>
                        <span class="pname" :title="item.handleMan || item.operator">{{ item.handleMan || item.operator }}</span>
                        <span class="paction"></span>
                        <span class="pactionDesc" v-if="item.opinions || item.handleInfo">{{ item.opinions || item.handleInfo }}</span>
                        <span class="pactionTime">{{ resolveTime(item.createDate) }}</span>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { handleDateToString } from '@/utils/tools'

export default {
    name: 'ApprovalProcess',
    data() {
        return {}
    },
    props: {
        processData: {
            type: Array,
            default: () => {
                return [
                    // {
                    //     businessId: '888635a676624e458faa04b674c5638b',
                    //     businessType: null,
                    //     createBy: 'system',
                    //     createDate: 1596696269000,
                    //     handleInfo: '接口需求【接收】',
                    //     handleMan: '张三',
                    //     handleOfficeName: null,
                    //     handlePhone: '13366556655',
                    //     handleRemark: 'ssssssssssss',
                    //     handleState: null,
                    //     id: 'be3e5458f2494916ade4c11b7d0c5552',
                    //     stat: '1',
                    //     updateBy: null,
                    //     updateDate: 1596696269000
                    // },
                    // {
                    //     businessId: '888635a676624e458faa04b674c5638b',
                    //     businessType: null,
                    //     createBy: 'system',
                    //     createDate: 1596696416000,
                    //     handleInfo: '数据实施工单【发起】',
                    //     handleMan: '李四赵五',
                    //     handleOfficeName: null,
                    //     handlePhone: '13366556655',
                    //     handleRemark: 'ssssssssssss',
                    //     handleState: null,
                    //     id: '842e342dfe1b4bb982441a3190a92c09',
                    //     stat: '1',
                    //     updateBy: null,
                    //     updateDate: 1596696416000
                    // }
                ]
            }
        }
    },
    methods: {
        resolveTime(time) {
            let str = handleDateToString(time, true)
            return str.split(' ')[0] + ' ' + str.split(' ')[1]
        }
    },
    mounted() {}
}
</script>

<style scoped lang="scss">
.ApprovalProcess {
    .commonblock {
        width: 100%;
        margin-bottom: 32px;
        /*min-height: 500px;*/
        /*background: rgba(142, 146, 161, 0.1);*/
        .commonTitle {
            width: 100%;
            height: 20px;
            line-height: 20px;
            padding-left: 12px;
            color: #000025;
            position: relative;
            display: block;
            &::after {
                content: '';
                width: 4px;
                height: 20px;
                background-color: #0284ff;
                position: absolute;
                left: 0;
                top: 0;
            }
        }
        .process {
            /* width: 500px; */
            min-height: 101px;
            position: relative;
            .processLi {
                display: flex;
                align-items: center;
                width: 100%;
                height: 45px;
                padding: 4px 0;
                padding-left: 50px;
                position: relative;
                > div {
                    display: flex;
                    align-items: center;
                    flex-flow: row nowrap;
                    .pactionTime {
                        flex-shrink: 0;
                    }
                }
                .seeMore {
                    display: block;
                    width: 34px;
                    height: 34px;
                    position: absolute;
                    background: #fdfdfd;
                    left: -15px;
                    top: 6px;
                    text-align: center;
                    line-height: 34px;
                    z-index: 30;
                    color: #0284ff;
                    .see--icons {
                        width: 24px;
                        height: 24px;
                        line-height: 24px;
                        border-radius: 24px;
                        font-size: 15px;
                        overflow: hidden;
                        border: 1px solid #0284ff;
                        position: absolute;
                        left: 4px;
                        top: 5px;
                        cursor: pointer;
                    }
                }
                .pname {
                    width: 180px;
                    display: block;
                    float: left;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .paction {
                    width: 36px;
                    display: block;
                    float: left;
                }
                .pactionDesc {
                    width: 234px;
                    display: block;
                    float: left;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .pactionTime {
                    display: block;
                    float: left;
                    text-align: right;
                }
            }
            $left: 0px;
            .processLi:after {
                content: '';
                width: 2px;
                height: 100%;
                position: absolute;
                left: $left;
                top: 0;
                border-left: 2px solid #d5d6e3;
                z-index: 1;
            }
            .processLi::before {
                content: '';
                width: 9px;
                height: 9px;
                position: absolute;
                left: -4px;
                top: 40%;
                border-radius: 9px;
                background: #d5d6e3;
                z-index: 10;
            }
            .processLi:first-child::after {
                content: '';
                width: 2px;
                height: 50%;
                position: absolute;
                left: $left;
                top: 50%;
                border-left: 2px solid #d5d6e3;
            }
            .processLi:last-child::after {
                content: '';
                width: 2px;
                height: 50%;
                position: absolute;
                left: $left;
                top: 0%;
                border-left: 2px solid #d5d6e3;
            }
        }
    }
}
</style>
