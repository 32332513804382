<template>
    <div class="MyCollectList">
        <div class="headTab">
            <span class="navitm" :class="{ nav1Active: currentTab == '0' }" @click="nav1Change(0)">数据目录</span>
            <span class="navitm" :class="{ nav1Active: currentTab == '1' }" @click="nav1Change(1)">数据集</span>
            <span class="navitm" :class="{ nav1Active: currentTab == '2' }" @click="nav1Change(2)">数据接口</span>
            <span class="navitm" :class="{ nav1Active: currentTab == '3' }" @click="nav1Change(3)">应用成果</span>
        </div>
        <div>
            <div v-if="showData">
                <a-spin :spinning="spinning" tip="数据加载中...">
                    <div class="applist">
                        <div class="blk" v-for="item in tabOptions[currentTab].list" :key="item.id" @click="goDetail(item)">
                            <span class="appName dots1">{{ item.resourceName }}</span>
                            <ul class="infoList">
                                <li class="infoLi">
                                    <span class="key">信息资源提供方：</span>
                                    <span class="value">{{ item.resourceProviderName }}</span>
                                </li>
                                <li class="infoLi">
                                    <span class="key">更新时间：</span>
                                    <span class="value">{{ resolveTimeStream(item.updateDate) }}</span>
                                </li>
                                <li class="infoLi">
                                    <span class="key">资源摘要：</span>
                                    <span class="value dots">{{ item.resourceAbstract }}</span>
                                </li>
                            </ul>
                            <div class="btns">
                                <div class="btnlist" @click.stop="deleteApp(item)"><a-icon type="delete" /></div>
                            </div>
                        </div>
                    </div>
                    <div class="pagination">
                        <a-pagination v-model="tabOptions[currentTab].pageNo" :defaultCurrent="tabOptions[currentTab].pageNo" :total="tabOptions[currentTab].total" :page-size-options="pageSizeOptions" :page-size.sync="tabOptions[currentTab].pageSize" show-size-changer show-quick-jumper @showSizeChange="onShowSizeChange" @change="pageChange" />
                    </div>
                </a-spin>
            </div>
            <no-data v-else></no-data>
        </div>

        <DataCatalogDetail :visible="visible" :data="detailData" @modalCancel="handleModalCancel"></DataCatalogDetail>
    </div>
</template>

<script>
import { CatalogList, DataList, DeleteApp, AppList, GetDetail, CollectPage, GetCollectResStat } from '@/views/Home/MyCollect/api'
import { resolveTimeStream } from '@/utils/tools'
import NoData from '@/views/Home/component/NoData'
import DataCatalogDetail from '@/components/DataCatalogDetail'
export default {
    name: 'MyCollectList',
    data() {
        return {
            currentTab: 0, // 当前选中
            tabOptions: {
                0: {
                    pageNo: 1,
                    pageSize: 6,
                    total: 0,
                    list: []
                },
                1: {
                    pageNo: 1,
                    pageSize: 6,
                    total: 0,
                    list: []
                },
                2: {
                    pageNo: 1,
                    pageSize: 6,
                    total: 0,
                    list: []
                },
                3: {
                    pageNo: 1,
                    pageSize: 6,
                    total: 0,
                    list: []
                }
            }, //
            visible: false, //详情弹框状态
            // 详情数据
            detailData: {},
            showData: true,
            spinning: true, //页面loading 状态
            pageSize1: 6,
            pageSize2: 6,
            pageSize3: 6,
            pageSizeOptions: ['6', '12', '18', '50'],
            current1: 1,
            total1: 1,
            current2: 1,
            current3: 1,
            total2: 1,
            total3: 1,
            nav1Active: 1, //我的 审批默认tab 1
            catalogList: null,
            interfaceList: null,
            appList: null,
            resourceType: ''
        }
    },
    components: { DataCatalogDetail, NoData },
    methods: {
        resolveTimeStream,
        // 弹窗关闭事件
        handleModalCancel() {
            this.visible = false
        },
        onShowSizeChange(e) {
            console.log(e)
            this.listinit()
        },
        pageChange(e) {
            console.log(e)
            this.tabOptions[this.nav1Active].pageNo = e
            this.listinit()
        },
        nav1Change(k) {
            this.currentTab = k
            this.nav1Active = k
            this.listinit()
        },
        goDetail(item) {
            // 查看详情
            GetCollectResStat({ id: item.id })
                .then(res => {
                    if (res == '1') {
                        // 在线的，走后面逻辑
                        let currentStrategy = this.chooseStrategy()
                        currentStrategy(item)
                        return
                    }
                    // 不为1，给个已下线提示
                    this.$message.warn('该资源已下线')
                })
                .catch(() => {})
        },
        chooseStrategy() {
            // 选择对应策略
            let _this = this
            let detailStrategy = {
                0: _this.goDataCatalog,
                1: _this.goDataSet,
                2: _this.goServiceDetail,
                3: _this.goApplicationDetail
            }
            return detailStrategy[this.currentTab]
        },
        goDataCatalog(item) {
            // console.log(item)
            GetDetail({ id: item.resourceId }).then(res => {
                this.detailData = res
                this.visible = true
            })
        },
        goDataSet(item) {
            //数据集
            let name = 'DataSetDetail'
            this.$router.push({
                name,
                query: { id: item.resourceId }
            })
        },
        goServiceDetail(item) {
            // debugger
            this.$router.push({
                path: '/InterfaceService/Detail',
                query: { id: item.resourceId, serviceState: '0' }
            })
        },
        goApplicationDetail(item) {
            this.$router.push({
                name: 'ApplicationResultsDetail',
                params: { id: item.resourceId }
            })
        },
        deleteApp(item) {
            console.log(item)
            var that = this
            this.$confirm({
                title: '提示',
                centered: true,
                content: '确认删除该条收藏吗？',
                okText: '确认',
                cancelText: '取消',
                onOk: function() {
                    DeleteApp({ id: item.resourceId, isstructuredData: 1, resourceType: that.currentTab }).then(() => {
                        that.$message.success('取消收藏成功')
                        that.listinit()
                    })
                }
            })
        },
        listinit() {
            let _this = this
            this.spinning = true
            CollectPage({
                pageNo: _this.tabOptions[_this.currentTab].pageNo,
                pageSize: _this.tabOptions[_this.currentTab].pageSize,
                param: {
                    resourceType: _this.currentTab
                }
            }).then(res => {
                _this.tabOptions[_this.currentTab].list = res.records
                _this.tabOptions[_this.currentTab].total = res.total
                if (res.records.length > 0) {
                    this.showData = true
                } else {
                    this.showData = false
                }
                setTimeout(() => {
                    this.spinning = false
                }, 300)
                console.log(res)
            })
            // if (this.nav1Active === 1) {
            //     //资源目录
            //     CatalogList({
            //         pageNo: this.current1,
            //         pageSize: this.pageSize1,
            //         param: {}
            //     }).then(res => {
            //         console.log(res)

            //         this.catalogList = res.records
            //         this.total1 = res.total
            //         this.current1 = res.current
            //     })
            // } else if (this.nav1Active === 2) {
            //     //数据接口
            //     DataList({
            //         pageNo: this.current2,
            //         pageSize: this.pageSize2,
            //         param: {}
            //     }).then(res => {
            //         this.interfaceList = res.records
            //         this.total2 = res.total
            //         this.current2 = res.current
            //     })
            // } else if (this.nav1Active === 3) {
            //     //数据接口
            //     AppList({
            //         pageNo: this.current3,
            //         pageSize: this.pageSize3,
            //         param: {}
            //     }).then(res => {
            //         this.appList = res.records
            //         this.total3 = res.total
            //         this.current3 = res.current
            //     })
            // }
        }
    },
    activated() {
        this.listinit()
    }
}
</script>

<style scoped lang="scss">
.MyCollectList {
    .headTab {
        width: 100%;
        height: 62px;
        /*background: lightblue;*/
        border-bottom: 1px solid #ebeced;
        overflow: hidden;
        .navitm {
            width: 74px;
            font-family: Source Han Sans CN;
            font-size: 16px;
            height: 16px;
            font-weight: normal;
            font-stretch: normal;
            line-height: 16px;
            letter-spacing: 0px;
            color: #999999;
            position: relative;
            margin-top: 32px;
            display: block;
            float: left;
            margin-right: 45px;
            cursor: pointer;
            text-align: center;
            &:first-child {
                margin-left: 60px;
            }
        }
        .navitm:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: -13px;
            width: 100%;
            height: 4px;
            background: #357df1;
            display: none;
        }
        .nav1Active {
            color: #000025;
        }
        .nav1Active:after {
            display: block;
        }
    }
    .applist {
        user-select: none;
        width: 882px;
        display: block;
        margin: 0 auto;
        margin-top: 32px;
        min-height: 600px;
        margin-bottom: 48px;
        display: grid;
        grid-auto-rows: 248px;
        grid-template-columns: repeat(3, 260px);
        justify-content: space-between;
        align-items: flex-start;
        .blk {
            width: 260px;
            height: 200px;
            box-sizing: border-box;
            border: solid 1px #dbdbdb;
            margin-bottom: 32px;
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: center;
            position: relative;
            .sanweifenxi {
                display: block;
                width: 120px;
                height: 136px;
                background: url('~@/assets/index/icon-sanweifenxi.png') no-repeat;
                background-size: cover;
            }
            .addOne {
                display: block;
                width: 120px;
                height: 40px;
                background-color: rgba(53, 125, 241, 0.1);
                border-radius: 4px;
                text-align: center;
                line-height: 40px;
                font-family: Source Han Sans CN;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                letter-spacing: 0px;
                color: #0184ff;
                margin-top: 14px;
                cursor: pointer;
            }
            .appName {
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                letter-spacing: 0px;
                color: #303343;
                width: 216px;
                position: absolute;
                left: 20px;
                top: 20px;
                color: #0184ff;
                box-sizing: border-box;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                text-align: left;
                overflow: hidden;
            }
            .appStatus {
                width: 52px;
                height: 24px;
                position: absolute;
                left: 20px;
                line-height: 24px;
                color: #0184ff;
                top: 44px;
                background-color: rgba(1, 132, 255, 0.1);
                border-radius: 4px;
                text-align: center;
                &.reject {
                    background-color: rgba(255, 1, 1, 0.1);
                    color: #ff0101;
                }
            }
            .infoList {
                width: 220px;
                height: 132px;
                display: block;
                position: absolute;
                left: 20px;
                top: 54px;
                .infoLi {
                    width: 100%;
                    height: 20px;
                    overflow: hidden;
                    margin-bottom: 8px;
                    overflow: hidden;
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    text-align: justify;
                    .key {
                        color: #999999;
                    }
                    .value {
                        color: #333333;
                    }
                    &:last-child {
                        width: 100%;
                        height: 40px;
                        overflow: visible;
                        margin-bottom: 8px;
                        overflow: hidden;
                        box-sizing: border-box;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        text-align: justify;
                    }
                }
            }
            .btns {
                width: 100%;
                height: 32px;
                position: absolute;
                left: 0;
                bottom: 0;
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-start;
                .btnlist {
                    width: 100%;
                    height: 32px;
                    text-align: center;
                    font-size: $fs14;
                    line-height: 32px;
                    background: #f4f5f6;
                    color: #999999;
                    cursor: pointer;
                    &:hover {
                        background: #0184ff;
                        // color: white;
                        // background-color: #fff
                    }
                }
            }
        }
        .blk:first-child {
            cursor: pointer;
        }
        .blk:hover {
            border: solid 1px #0184ff;
            cursor: pointer;
        }
    }
    .pagination {
        width: 100%;
        display: block;
        margin-top: 40px;
        margin-bottom: 48px;
        ul {
            display: flex;
            justify-content: center;
        }
    }
}
</style>
